import React, { useState } from 'react';
import { Avatar, Card, Col, Empty, Flex, Pagination, Row } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../services/react-query/queryKeys';
import { decodeResponse, decryptItem } from '../../helpers/apiUtils';
import { FetchData } from '../../network/fetchData';
import DeleteModal from '../common/DeleteModal';
import { deleteData } from '../../network/deleteData';
import FilterTable from '../common/filtersTables/FilterTable';
import AddCertificate from './AddCertificate';
import { EditOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import UpdateCertificate from './UpdateCertificate';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import UploadFileModal from '../common/UploadFile';

function ListCertificates() {
  const [editCertificateData, setEditCertificateData] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState<React.Key | null>(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalAction, setModalAction] = useState<'add' | 'update' | "delete" | "upload" | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage: number = 12;
  const currentLang = useSelector((state: any) => state?.user?.currentLang);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const indexOfLastItem: number = currentPage * itemsPerPage;
  const indexOfFirstItem: number = indexOfLastItem - itemsPerPage;

  const showModal = (action: 'add' | 'update' | 'delete' | 'upload', id?: React.Key, data?: any) => {
    setSelectedRowId(id !== undefined ? id : null);
    setEditCertificateData(data);
    setModalAction(action);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const deleteValue = {
    mode: "certificate",
    type: "delete",
    id: selectedRowId
  };

  const [searchTerm, setSearchTerm] = useState('');

  const values = {
    mode: "certificate",
    type: "get",
    language_id: currentLang === "ar" ? 1 : currentLang === "en" ? 2 : null,
  };

  const { data: listCertificates } = useQuery(
    [queryKeys.listCertificates, values.language_id, searchTerm],
    () => FetchData({
      ...values,
      name: searchTerm,
    }),
    {
      keepPreviousData: true,
    }
  );

  const decodedResponse = decodeResponse(listCertificates);
  const decryptedData = decodedResponse?.data.map((item: any) => {
    const decryptedItem = decryptItem(item);
    const excludedFields = ['media'];

    const itemWithoutDecryption = Object.fromEntries(
      Object.entries(decryptedItem).map(([key, value]) => {
        return excludedFields.includes(key) ? [key, item[key]] : [key, value];
      })
    );

    return itemWithoutDecryption;
  });

  const currentItems: any[] = decryptedData?.slice(indexOfFirstItem, indexOfLastItem);

  const items = [
    {
      title: <FormattedMessage id='certificate' />,
    }
  ];

  return (
    <>
      <FilterTable
        buttonText={<FormattedMessage id='add_certificate' />}
        tabName={<FormattedMessage id='certificate' />}
        onClick={() => showModal('add')}
        items={items}
        onSearch={(value) => setSearchTerm(value)}
      />

      <UploadFileModal visible={isModalVisible && modalAction === 'upload'} onClose={closeModal} mode={"assessment_advice"} confirmationHeader={''} successMessage={''} queryKey={queryKeys.listAdvice} />

      <AddCertificate
        visible={isModalVisible && modalAction === 'add'}
        onClose={closeModal}
      />
      <UpdateCertificate
        visible={isModalVisible && modalAction === 'update'}
        onClose={closeModal}
        certificateData={editCertificateData}
      />

      <DeleteModal
        visible={isModalVisible && modalAction === 'delete'}
        onDelete={deleteData}
        data={deleteValue}
        confirmationHeader={<FormattedMessage id='certificate' />}
        queryKey={queryKeys.listCertificates}
        onClose={closeModal}
      />

      {decryptedData && decryptedData?.length > 0 ? (
        <>
          <Row gutter={{ xs: 8, sm: 16, md: 8, lg: 14, xl: 10 }} justify={decryptedData?.length % 2 !== 0 ? "space-between" : "start"}>
            {currentItems.map((item: any) => (
              <Col className='mb-4'>
                <Card title={item?.name} className='xl:w-[300px]'
                  actions={[
                    <Link to="/certificate/data" className='text-[#ED1D23]' state={{ certificate_data: item }}><EyeOutlined /></Link>,
                    <EditOutlined key="edit" onClick={() => showModal('update', item.id, item)} />,
                    <DeleteOutlined key="delete" style={{ color: "red" }} onClick={() => showModal('delete', item.id)} />,
                  ]}
                >
                  <Flex align='center'>
                    <img
                      alt="example"
                      src={item.media}
                      className='w-full h-[100px]'
                    />
                  </Flex>
                </Card>
              </Col>
            ))}
          </Row>
          <Pagination
            current={currentPage}
            total={decryptedData.length}
            pageSize={itemsPerPage}
            onChange={handlePageChange}
            className='mt-1'
          />
        </>
      ) : (
        <Empty description={<FormattedMessage id='no_data' />} className='mt-24' />
      )}
    </>
  );
}

export default ListCertificates;
