import { FC } from "react";

interface ContactsProps {
    width?: number;
    height?: number;
}

const Contacts: FC<ContactsProps> = ({ width = "18px", height = "18px" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="23" viewBox="0 0 21 23" fill="none">
        <path d="M9.33438 1.94375C5.41164 2.03656 4.96703 5.03922 5.80375 8.5025C5.6725 8.59531 5.40508 8.92531 5.44938 9.47875C5.58063 10.4945 5.97766 10.7713 6.1975 10.7713C6.28609 11.4639 6.59781 12.1927 6.90625 12.655C7.12609 12.9781 7.20813 13.3941 7.20813 13.81C7.20813 14.1795 7.21305 13.9441 7.16875 14.36C7.12445 14.4941 7.06211 14.6161 6.985 14.7313C7.07524 15.087 7.59531 16.78 9.37375 16.78C11.1948 16.78 11.6838 14.953 11.7363 14.7313C11.6542 14.6178 11.5886 14.4941 11.5394 14.36C11.4951 13.853 11.4869 14.0884 11.4869 13.6725C11.4869 13.303 11.6247 12.9867 11.8019 12.71C12.1103 12.2477 12.4089 11.4639 12.4975 10.7713C12.7633 10.7713 13.157 10.4945 13.2456 9.47875C13.2899 8.92531 13.0816 8.59531 12.9044 8.5025C13.3457 7.21 14.1759 3.19844 11.3556 2.7825C11.0472 2.22906 10.304 1.94375 9.33438 1.94375ZM12.4319 15.35C12.0791 16.3331 11.1456 17.66 9.37375 17.66C7.60024 17.66 6.67 16.3572 6.3025 15.3638C4.55688 16.4895 1 16.835 1 20.74H17.7475C17.7475 16.7353 14.207 16.4603 12.4319 15.35Z" fill="#0E103A"/>
      </svg>
    );
};

export default Contacts;
