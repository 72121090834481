import React, { useState } from 'react';
import { Space } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { DeleteOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { ColumnsType } from 'antd/es/table';
import AssignUserELearning from './AssignUserAssessment';
import { decodeResponse, decryptItem } from '../../../../helpers/apiUtils';
import { FetchData } from '../../../../network/fetchData';
import { queryKeys } from '../../../../services/react-query/queryKeys';
import DeleteModal from '../../../common/DeleteModal';
import { deleteData } from '../../../../network/deleteData';
import CustomTable from '../../../common/CustomTable';
import UploadFileModal from '../../../common/UploadFile';
import FilterTable from '../../../common/filtersTables/FilterTable';

interface AssessmentUserProps {
  assessmentData: any;
}


interface DataType {
  id: React.Key;
  name: string;
  email: string;
  start_date: string;
  end_date: string;
  success_rate: string;
  progress: string;
}


const AssessmentUser: React.FC<AssessmentUserProps> = ({ assessmentData }) => {
  const [editUserELearningData, setEditUserELearningData] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState<React.Key | null>(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalAction, setModalAction] = useState<'add' | 'update' | "delete" | "upload" | null>(null);


  const showModal = (action: 'add' | 'update' | 'delete' | 'upload', id?: React.Key, data?: any) => {
    setSelectedRowId(id !== undefined ? id : null);
    setEditUserELearningData(data);
    setModalAction(action);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const deleteValue = {
    mode: "e-learning",
    type: "delete",
    id: selectedRowId
  };

  const values = {
    mode: "e-learning",
    type: "users_elearning",
    id: assessmentData.id,
  };

  const [searchTerm, setSearchTerm] = useState('');

  const { data: listELearnings } = useQuery(
    [queryKeys.listELearnings, searchTerm],
    () => FetchData({
      ...values,
      name: searchTerm,
    }),
    {
      keepPreviousData: true,
    }
  );

  const decodedResponse = decodeResponse(listELearnings);
  const decryptedData = decodedResponse?.data.map((item: any) => {
    const decryptedItem = decryptItem(item);
    const excludedFields = ['pivot', 'elearnings'];

    const itemWithoutDecryption = Object.fromEntries(
      Object.entries(decryptedItem).map(([key, value]) => {
        return excludedFields.includes(key) ? [key, item[key]] : [key, value];
      })
    );

    return itemWithoutDecryption;
  });

  const columns: ColumnsType<DataType> = [
    {
      title: <FormattedMessage id={'name'} />,
      dataIndex: 'name',
    },
    {
      title: <FormattedMessage id={'email'} />,
      dataIndex: 'email',
    },
    {
      title: <FormattedMessage id={'phone'} />,
      dataIndex: 'phone',
    },
    {
      key: 'operation',
      width: 100,
      render: (record: DataType) => (
        <Space>
          <DeleteOutlined className='text-gray-400' onClick={() => showModal('delete', record.id)} />
        </Space>
      ),
    },
  ];

  const items = [
    {
      title: <FormattedMessage id='assessment' />,
      href: '/assessment',
    },
    {
      title: <FormattedMessage id='users' />,
      href: '',
    }
  ];
  
  return (
    <>
      <FilterTable items={items} onSearch={(value) => setSearchTerm(value)} buttonText={<FormattedMessage id='add_assessment_exam_users' />} tabName={<FormattedMessage id='assessment_exam_users' />} onClick={() => showModal('add')} onClickUpload={() => showModal('upload')} />

      <UploadFileModal visible={isModalVisible && modalAction === 'upload'} onClose={closeModal} mode={"e-learning"} confirmationHeader={''} successMessage={''} queryKey={queryKeys.listELearnings} />

      <AssignUserELearning
        visible={isModalVisible && modalAction === 'add'}
        onClose={closeModal}
        assessmentData={assessmentData}
      />

      <DeleteModal
        visible={isModalVisible && modalAction === 'delete'}
        onDelete={deleteData}
        data={deleteValue}
        confirmationHeader={<FormattedMessage id='assessment_exam_users' />}
        queryKey={queryKeys.listELearnings}
        onClose={closeModal}
      />

      <CustomTable columns={columns} data={decryptedData} />
    </>
  );
}

export default AssessmentUser;
