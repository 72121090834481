import React, { useState } from "react";
import { Button, Card, Flex, Space, Tabs, TabsProps } from "antd";
import CustomHeader from "../../components/common/CustomHeader";
import { FormattedMessage } from "react-intl";
import SubCategory from "../../components/assessment/assessmentData/SubCategory";
import { useLocation } from "react-router-dom";

export default function AssessmentSubCategoryData() {
  const location = useLocation();
  const data = location?.state.categoryData;

  return (
    <>

      <CustomHeader title={<FormattedMessage id={'assessment'} />} />

      <SubCategory categoryData={data}/>
    </>
  );
}

