import { FC } from "react";

interface MessageProps {
    width?: number;
    height?: number;
}

const Message: FC<MessageProps> = ({ width = "18px", height = "18px" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
        <g clip-path="url(#clip0_369_7037)">
          <path d="M0 2.94V4.10813C1.26492 5.20898 8.52141 11.5352 9.09563 12.0356C9.68625 12.5508 10.2752 12.6 10.5 12.6C10.7248 12.6 11.3138 12.5508 11.9044 12.0356C12.4999 11.5172 20.1797 4.8218 21 4.10813V2.94H0ZM0 5.22375V15.7631L6.3525 10.7494C4.29352 8.95781 1.3568 6.405 0 5.22375ZM21 5.22375C19.7187 6.33938 16.736 8.93156 14.6475 10.7494L21 15.75V5.22375ZM6.99563 11.3138L0 16.8263V18.06H21V16.8131L14.0044 11.3138C13.1906 12.0225 12.6049 12.536 12.4556 12.6656C11.6501 13.3662 10.8183 13.44 10.5 13.44C10.1817 13.44 9.34992 13.3678 8.54438 12.6656C8.39672 12.5377 7.81102 12.0241 6.99563 11.3138Z" fill="#0E103A"/>
        </g>
        <defs>
          <clipPath id="clip0_369_7037">
            <rect width="21" height="21" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    );
};

export default Message;
