import React from "react";
import { Button, Col, Flex, Form, message, Modal, Row } from "antd";
import { useMutation } from "@tanstack/react-query";
import queryClient from "../../services/react-query/queryClient";
import { FormattedMessage, useIntl } from "react-intl";

interface AddModalProps<T> {
    visible: boolean;
    onClose: () => void;
    onSubmit: (data: T) => Promise<void>;
    initialValues: T | null;
    confirmationHeader: any;
    mode: string;
    assessmentData?: string;
    type?: string;
    queryKey: string; // Message to display on success
    formFields: {
        colSize: any, input: any; name: string; label: any; placeholder?: string; rules?: any[]
    }[];
}

const AddModal: React.FC<AddModalProps<any>> = ({
    visible,
    onClose,
    onSubmit,
    initialValues,
    confirmationHeader,
    formFields,
    queryKey,
    mode,
    type,
    assessmentData
}) => {
    const intl = useIntl();
    const successMessage = intl.formatMessage({ id: 'success_add_msg' });

    const { mutate: addMutation, isLoading } = useMutation(onSubmit, {
        onSuccess: () => {
            onClose();
            queryClient.invalidateQueries([queryKey]);
            message.success(successMessage);
            form.resetFields();
        },
        onError: (error) => {
            message.error("Error Adding");
        },
    });

    const onFinish = async (values: any) => {
        const { start_date, end_date, ...otherValues } = values;
        const formattedStartDate = start_date ? start_date.format('YYYY-MM-DD HH:mm:ss') : null;

        const positions_subcategory = formFields
            .filter(field => field.name.startsWith('positions_subcategory'))
            .map(field => ({
                sub_assessment_category_id: parseInt(field.name.split('[')[1].split(']')[0]),
                standard: values[field.name]
            }));

        // Check if end_date exists and format it
        const formattedEndDate = end_date ? end_date.format('YYYY-MM-DD HH:mm:ss') : null;

        const formData = {
            mode: mode,
            type: type || "store",
            assessment_type_id: assessmentData,
            assessment_category_id: assessmentData,
            ...(formattedStartDate && { start_date: formattedStartDate }),
            ...(formattedEndDate && { end_date: formattedEndDate }),
            ...(positions_subcategory.length > 0 && { positions_subcategory }),
            ...otherValues
        };
        addMutation(formData);
    };

    const onCancel = () => {
        form.resetFields();
        onClose();
    };

    const [form] = Form.useForm();

    return (
        <Modal
            centered
            open={visible}
            className="customModal"
            title={confirmationHeader}
            onOk={onFinish}
            onCancel={onCancel}
            footer={[]}
            width={850}
        >
            <div className="px-5 pb-6">
                <Form form={form} initialValues={initialValues} layout="vertical" onFinish={onFinish}>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 22 }} justify={"space-between"}>

                        {formFields.map((field) => (
                            <Col span={field.colSize}>
                                <Form.Item
                                    required={false}
                                    key={field.name}
                                    name={field.name}
                                    label={field.label}
                                    rules={field.rules}
                                >
                                    {field.input}
                                </Form.Item>
                            </Col>
                        ))}
                    </Row>

                    <Flex gap="middle" align="center" justify="flex-end">
                        <Button
                            type="text"
                            onClick={onCancel}
                            className="bg-[#1d1d39] h-full text-white text-lg rounded-md px-2 w-[112px]"
                            htmlType="button"
                            loading={isLoading}
                        >
                            <FormattedMessage id='cancel' />
                        </Button>
                        <Button
                            type="text"
                            className="bg-[#cf2110] h-full text-white text-lg rounded-md px-2 w-[112px]"
                            htmlType="submit"
                            loading={isLoading}
                        >
                            <FormattedMessage id='save' />
                        </Button>
                    </Flex>
                </Form>
            </div>
        </Modal>
    );
};

export default AddModal;
