// Define an enum with numeric values
enum roleEnum {
  Admin = "admin",
  AssessmentAdmin = "assessment_admin",
  ElearningAdmin = "elearning_admin",
  AccreditationAdmin = "accreditation_admin",
  ExamAdmin = "exam_admin",
  User = "user",
}

// Create a constant array to define the roleType
const role: { value: string; label: string }[] = [
  { value: roleEnum.Admin, label: 'Admin' },
  { value: roleEnum.AssessmentAdmin, label: 'Assessment Admin' },
  { value: roleEnum.ElearningAdmin, label: 'Elearning Admin' },
  { value: roleEnum.AccreditationAdmin, label: 'Accreditation Admin' },
  { value: roleEnum.ExamAdmin, label: 'Exam Admin' },
  { value: roleEnum.User, label: 'User' },
];

export { roleEnum, role };
