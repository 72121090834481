import { FC } from "react";
import MostBookedServices from "./componentsWrappers/MostBookedServices";
import Component from "./componentsWrappers/Component";
import { FormattedMessage } from "react-intl";

interface ServicesWrapperProps { }
const ServicesSCGIcon = (width = "32.019px", height = "32") => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5899 5.75699C11.5285 5.75699 11.4671 5.7439 11.4095 5.71708L9.99465 5.06098L8.57975 5.71708C8.44019 5.78215 8.27655 5.76763 8.15093 5.67746C8.02531 5.58815 7.95716 5.43817 7.97283 5.28494L8.14201 3.61373L7.11238 2.59919C7.00294 2.49141 6.95937 2.33343 6.99848 2.1846C7.03759 2.03606 7.15314 1.91993 7.30169 1.87974L8.70486 1.5006L9.64461 0.170843C9.80539 -0.0569478 10.184 -0.0569478 10.3448 0.170843L11.2845 1.5006L12.6877 1.87974C12.8363 1.91993 12.9518 2.03606 12.9909 2.1846C13.03 2.33343 12.9865 2.49141 12.877 2.59919L11.8474 3.61373L12.0166 5.28494C12.0322 5.43823 11.9641 5.58815 11.8385 5.67746C11.7648 5.73023 11.6776 5.75699 11.5899 5.75699ZM9.99465 4.15954C10.0561 4.15954 10.118 4.17291 10.175 4.19944L11.0873 4.62238L10.9735 3.49589C10.9601 3.36661 11.0064 3.23848 11.0991 3.14717L11.7429 2.51285L10.9115 2.28809C10.8149 2.26185 10.7306 2.20295 10.673 2.12142L9.99465 1.16131L9.31625 2.12142C9.25873 2.20295 9.17445 2.26185 9.07782 2.28809L8.24642 2.51285L8.89022 3.14717C8.98291 3.23848 9.02928 3.36661 9.01584 3.49589L8.90194 4.62238L9.81431 4.19944C9.87126 4.17291 9.93324 4.15954 9.99465 4.15954Z"
      fill="#00086D"
    />
    <path
      d="M12.2813 18H7.70714C7.47038 18 7.27832 17.808 7.27832 17.5712V8.42295C7.27832 7.87131 7.72727 7.42236 8.27891 7.42236H11.7095C12.2611 7.42236 12.7101 7.87131 12.7101 8.42295V17.5712C12.7101 17.808 12.518 18 12.2813 18ZM8.13597 17.1424H11.8524V8.42295C11.8524 8.34422 11.7882 8.28001 11.7095 8.28001H8.27891C8.20018 8.28001 8.13597 8.34422 8.13597 8.42295V17.1424Z"
      fill="#00086D"
    />
    <path
      d="M16.8555 18.0001H12.2814C12.0446 18.0001 11.8525 17.808 11.8525 17.5712V10.1383C11.8525 9.90153 12.0446 9.70947 12.2814 9.70947H16.2837C16.8354 9.70947 17.2843 10.1584 17.2843 10.7101V17.5712C17.2843 17.808 17.0923 18.0001 16.8555 18.0001ZM12.7102 17.1424H16.4267V10.7101C16.4267 10.6313 16.3625 10.5671 16.2837 10.5671H12.7102V17.1424Z"
      fill="#00086D"
    />
    <path
      d="M7.70705 18.0001H3.13293C2.89616 18.0001 2.7041 17.8081 2.7041 17.5713V12.9972C2.7041 12.4455 3.15305 11.9966 3.70469 11.9966H7.70705C7.94382 11.9966 8.13587 12.1886 8.13587 12.4254V17.5713C8.13587 17.8081 7.94382 18.0001 7.70705 18.0001ZM3.56175 17.1425H7.27822V12.8542H3.70469C3.62596 12.8542 3.56175 12.9184 3.56175 12.9972V17.1425Z"
      fill="#00086D"
    />
    <path
      d="M18.571 18H1.41808C1.18131 18 0.989258 17.8079 0.989258 17.5712C0.989258 17.3344 1.18131 17.1423 1.41808 17.1423H18.571C18.8078 17.1423 18.9999 17.3344 18.9999 17.5712C18.9999 17.8079 18.8078 18 18.571 18Z"
      fill="#00086D"
    />
  </svg>
);
const ServicesWrapper: FC<ServicesWrapperProps> = ({ }) => {
  return (
    <div className="m-0">
      <div className="flex gap-2 items-center">
        <span> {ServicesSCGIcon("17.4px", "18px")} </span>
        <span className="font-semibold"><FormattedMessage id={'assessment_services'} /></span>
      </div>

      <Component>
        <MostBookedServices />
      </Component>

    </div>
  );
};

export default ServicesWrapper;
