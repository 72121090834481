import React, { useState } from 'react';
import { Card, Col, Modal, Row } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { FetchData } from '../../../network/fetchData';
import { decodeResponse, decryptItem } from '../../../helpers/apiUtils';
import FilterTable from '../../common/filtersTables/FilterTable';
import { EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { queryKeys } from '../../../services/react-query/queryKeys';
import { deleteData } from '../../../network/deleteData';
import DeleteModal from '../../common/DeleteModal';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import UploadFileModal from '../../common/UploadFile';
import PaginationComponent from '../../common/PaginationComponent';
import AddPosition from './AddPosition';


interface InfoProps {
  assessmentData: any;
}

const Position: React.FC<InfoProps> = ({ assessmentData }) => {
  const [selectedRowId, setSelectedRowId] = useState<React.Key | null>(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalAction, setModalAction] = useState<'add' | 'update' | "delete" | "upload" | "show" | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedData, setSelectedData] = useState<any>(null);

  const showModal = (id: React.Key, action: 'add' | 'update' | "delete" | "upload" | "show") => {
    setSelectedRowId(id);
    setModalAction(action);
    setModalVisible(true);
  };


  const closeModal = () => {
    setModalVisible(false);
  };

  const deleteValue = {
    mode: "assessment_position",
    type: "delete",
    id: selectedRowId
  };
  const currentLang = useSelector((state: any) => state?.user?.currentLang);

  const values = {
    mode: "assessment_position",
    type: "get",
    assessment_type_id: assessmentData.id,
    language_id: currentLang === "ar" ? 1 : currentLang === "en" ? 2 : null

  };

  const { data: listPostions } = useQuery(
    [queryKeys.listPostions, values.language_id, searchTerm],
    () => FetchData({
      ...values,
      name: searchTerm,
    }),
    {
      keepPreviousData: true,
    }
  );
  const decodedResponse = decodeResponse(listPostions);
  const decryptedData = (decodedResponse?.data || []).map((item: any) => {
    const decryptedItem = decryptItem(item);
    const excludedFields = ['sub_assessment_categories'];

    const itemWithoutDecryption = Object.fromEntries(
      Object.entries(decryptedItem).map(([key, value]) => {
        return excludedFields.includes(key) ? [key, item[key]] : [key, value];
      })
    );

    return itemWithoutDecryption;
  });
  const decryptedSubAssessmentData = (selectedData?.sub_assessment_categories || []).map((item: any) => {
    const decryptedItem = decryptItem(item);
    const excludedFields = ['pivot'];

    const itemWithoutDecryption = Object.fromEntries(
      Object.entries(decryptedItem).map(([key, value]) => {
        return excludedFields.includes(key) ? [key, item[key]] : [key, value];
      })
    );

    return itemWithoutDecryption;
  });

  const items = [
    {
      title: <FormattedMessage id='assessment' />,
      href: '/assessment',
    },
    {
      title: <FormattedMessage id='position' />,
    }
  ];

  return (
    <>
      <FilterTable
        buttonText={<FormattedMessage id='add_position' />}
        items={items}
        onClick={() => showModal(1, 'add')}
        onClickUpload={() => showModal(1, 'upload')}
        onSearch={(value) => setSearchTerm(value)}
      />

      <UploadFileModal visible={isModalVisible && modalAction === 'upload'} onClose={closeModal} mode={"assessment_advice"} confirmationHeader={''} successMessage={''} queryKey={queryKeys.listAdvice} />
      <AddPosition
        visible={isModalVisible && modalAction === 'add'}
        onClose={closeModal} assessmentData={assessmentData} />
      <DeleteModal
        visible={isModalVisible && modalAction === 'delete'}
        onDelete={deleteData}
        data={deleteValue}
        confirmationHeader={<FormattedMessage id={'position'} />}
        queryKey={queryKeys.listPostions}
        onClose={closeModal}
      />

      <PaginationComponent
        gutterNumber={16}
        spanNumber={6}
        decryptedData={decryptedData}
        actions={(item: { id: React.Key; }) => (
          <>
            <Col> <EyeOutlined key="view" onClick={() => {
              showModal(item.id, "show");
              setSelectedData(item);
            }} /></Col>
            <Col><EditOutlined key="edit" onClick={() => showModal(item.id, 'update')} /></Col>
            <Col><DeleteOutlined key="delete" className='text-red-400' onClick={() => showModal(item.id, 'delete')} /></Col>
          </>
        )}
      />

      <Modal
        visible={isModalVisible && modalAction === 'show'}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: true }}
        footer={[]}
        onCancel={closeModal}
        width={850}
      >
        <Card className='m-4'>
          {(decryptedSubAssessmentData || []).map((item: any) => (
            <div key={item.id}>
              <Row gutter={12}>
                <Col span={12} className='bg-[#F5F5F5] p-2 mb-4'>
                  <FormattedMessage id='name' />:   {item.name}
                </Col>
                <Col span={12} className='bg-[#F5F5F5] p-2 mb-4'>
                  <FormattedMessage id='standard' />:  {item.pivot.standard}
                </Col>
              </Row>

            </div>
          ))}
        </Card>
      </Modal>

    </>
  );
};

export default Position;