import User from "../pages/user/User";

const routesUser = [
  {
    path: "users",
    element: <User />,
  }
];

export default routesUser;
