export const queryKeys = {
  dataList: "dataList",
  listCategories: "listCategories",
  listStatement: "listStatement",
  listAdvice: "listAdvice",
  listCompanies: "listCompanies",
  listPostions: "listPostions",
  listExams: "listExams",
  listCorporate: "listCorporate",
  SpacficCorporates: "SpacficCorporates",
  listBatchs: "listBatchs",
  listAccreditationCenter: "listAccreditationCenter",
  eLearningChapters: "eLearningChapters",
  listAccreditationTrainer: "listAccreditationTrainer",
  listUser: "listUser",
  listSubCategories: "listSubCategories",
  listCertificates: "listCertificates",
  listLevels: "listLevels",
  listRunningQuestions: "listRunningQuestions",
  SpacficAccreditationCenter: "SpacficAccreditationCenter",
  listELearnings: "listELearnings",
  listExamChapters: "listExamChapters",
  listChapters: "listChapters",
  listUserBatchs: "listUserBatchs",
  listAssessments: "listAssessments",
};
