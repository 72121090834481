import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Layout, Menu } from 'antd';
import {
  SafetyCertificateOutlined,
  TeamOutlined,
  FileDoneOutlined,
  FormOutlined,
  BookOutlined,
  RightOutlined,
  UserOutlined,
  LeftOutlined
} from '@ant-design/icons';
import '../../styles/SidebarMenu.css';
import icon from '../../assets/icon-white.png';
import ELearningSVG from '../../assets/common/ELearningSVG';
import { FormattedMessage } from 'react-intl';

const { Sider } = Layout;
const { SubMenu } = Menu;  // Import SubMenu

const SidebarMenu = () => {
  const currentLang = useSelector((state) => state?.user?.currentLang);
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(true);
  const userRoles = useSelector((s) => s?.user?.data?.user?.roles.map(role => role.name));

  console.log(userRoles);

  useEffect(() => {
    const handleResize = () => {
      const isSmallScreen = window.innerWidth <= 768;
      if (isSmallScreen) {
        setCollapsed(true);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const collapseButtonStyle = {
    float: 'right',
    top: '180px',
    position: 'absolute',
    marginLeft: currentLang === 'en' ? (collapsed ? '80px' : '220px') : '0',
    marginRight: currentLang !== 'en' ? (collapsed ? '70px' : '210px') : '0',
  };

  const CollapseButton = ({ collapsed, toggleCollapse }) => {
    return (
      <div className={`buttonCollaps ${collapsed ? 'collapsed' : 'expanded'}`} style={collapseButtonStyle}>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="97" viewBox="0 0 32 97" fill="none">
          <path d={currentLang === 'en' ? "M0 0.67334L20.5022 12.3889V85.6111L0 97.3267V0.67334Z" : "M21.3076 96.6484L0.0339044 84.9525L-0.0338896 11.7303L21.2181 -0.00485011L21.3076 96.6484Z"} fill="#0E103A" />
          <foreignObject x={currentLang === 'en' ? "-3" : "-7"} y="0" width="100%" height="100%">
            <button onClick={toggleCollapse} className="collapseButton">
              {collapsed ? <RightOutlined className="icon" /> : <LeftOutlined className="icon" />}
            </button>
          </foreignObject>
        </svg>
      </div>
    );
  };

  const menuItems = [
    { role: 'admin', key: '', icon: <img src={icon} alt="" />, label: <FormattedMessage id="IBDL_admin_control" /> },
    { role: 'admin', key: 'certificate', icon: <SafetyCertificateOutlined />, label: <FormattedMessage id="certificate" /> },
    { role: 'elearning_admin', key: 'e-learning', icon: <ELearningSVG />, label: <FormattedMessage id="e_learnings" /> },
    {
      role: 'exam_admin', icon: <BookOutlined />, label: <FormattedMessage id="exams" />,
      children: [
        { key: 'questions/bank', label: <FormattedMessage id="questions-bank" /> },
        { key: 'exams/configuration', label: <FormattedMessage id="exam_configuration" /> },
        { key: 'exams/schedule', label: <FormattedMessage id="exam_schedule" /> },
        { key: 'exams/monitoring', label: <FormattedMessage id="exams_monitoring" /> },
      ]
    },

    { role: 'assessment_admin', key: 'assessment', icon: <FormOutlined />, label: <FormattedMessage id="assessment" /> },
    { role: 'corporate_admin', key: 'corporate', icon: <TeamOutlined />, label: <FormattedMessage id="corporate" /> },
    { role: 'accreditation_admin', key: 'accreditation', icon: <FileDoneOutlined />, label: <FormattedMessage id="accreditation" /> },
    { role: 'admin', key: 'users', icon: <UserOutlined />, label: <FormattedMessage id="users" /> },
  ];

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={setCollapsed}
      width={220}
      className="sidebar p-2 mx-9 my-6 rounded-md"
    >
      <Menu
        defaultSelectedKeys={['1']}
        onClick={({ key }) => navigate(`/${key}`)}
      >
        {menuItems.filter(item => userRoles.includes(item.role) || userRoles.includes('admin')).map(item => (
          item.children ? (
            <SubMenu key={item.key} icon={item.icon} title={item.label}>
              {item.children.map(subItem => (
                <Menu.Item key={subItem.key} onClick={() => navigate(`/${subItem.key}`)} style={{ ...item.style, ...{ color: 'black' } }}>
                  {subItem.label}
                </Menu.Item>
              ))}
            </SubMenu>
          ) : (
            <Menu.Item key={item.key} icon={item.icon} style={{ ...item.style, ...{ height: '45px', display: 'flex', alignItems: 'center', color: 'black' } }}>
              {item.label}
            </Menu.Item>
          )
        ))}
      </Menu>

      <CollapseButton collapsed={collapsed} toggleCollapse={() => setCollapsed(!collapsed)} />
    </Sider>
  );
};

export default SidebarMenu;
