import { InboxOutlined } from "@ant-design/icons";
import { FC, useState } from "react";
import React from "react";
import { Button, Form, message, Flex, Modal } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UploadData } from "../../network/uploadDate";
import Dragger from "antd/es/upload/Dragger";
import { FormattedMessage } from "react-intl";

interface UploadFileModalProps<T> {
  visible: boolean;
  onClose: () => void;
  mode: T | null; // Define the data prop
  confirmationHeader: string; // Text for the confirmation message
  successMessage: string; // Message to display on success
  queryKey: string; // Message to display on success
}

const UploadFileModal: FC<UploadFileModalProps<any>> = ({
  visible,
  onClose,
  mode,
  confirmationHeader,
  queryKey,
  successMessage,
}) => {

  // const [fileList, setFileList] = useState(null);
  const [fileList, setFileList] = useState<any>([]);
  const [fileValue, setFileValue] = useState<any>([]);
  const queryClient = useQueryClient();

  const { mutate: onUploadFile, isLoading } = useMutation(
    UploadData,
    {
      onSuccess: (res: any) => {
        queryClient.invalidateQueries([queryKey]);
        onClose();
        message.success(successMessage);
      },
      onError: (error: any) => {
        console.error("Error uploading file:", error);
      },
    }
  );


  const handleUploadFile = ({ file }: any) => {
    let form_data = new FormData();
    form_data.append('mode', mode);
    form_data.append('type', "upload");
    form_data.append('file', fileValue[0]);

    onUploadFile(form_data);
  };
  // console.log("fileList",fileList);

  const handleFileChange = (info: { fileList: any; }) => {
    let fileList = [...info.fileList];

    // 1. Limit the number of uploaded files
    fileList = fileList.slice(-1);

    // 2. Read from response and show file link
    fileList = fileList.map(file => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url;
      }
      return file;
    });

    // Update the component state
    setFileList(fileList);
    setFileValue(fileList.map(file => file.originFileObj));

    // You can also log or perform other actions with the file here
    console.log(fileList);
  };

  const handleRemove = () => {
    setFileList(null);
  };

  return (
    <Modal
      centered
      open={visible}
      onOk={handleUploadFile}
      onCancel={onClose}
      okButtonProps={{ disabled: true }}
      cancelButtonProps={{ disabled: true }}
      footer={[]}
      width={850}
    >
      <Form>

        <div className="p-6">
          <Dragger
            onChange={handleFileChange}
            onRemove={handleRemove}
            accept=".xlsx"
            multiple={false}
            showUploadList={{ showRemoveIcon: true }}
          >
            <div className="p-5">
              <div className="flex items-center justify-center gap-[10px] py-2 px-6 border-[1px] border-solid w-fit mx-auto rounded-lg border-[#75757E]">
                <InboxOutlined style={{ fontSize: "19px", color: "#9999A7" }} />
                <span className="text-[#75757E]"><FormattedMessage id={'upload_text1'} /></span>
              </div>
              <span className="text-xs font-normal text-[#75757E]">
                <FormattedMessage id={'upload_text2'} />
              </span>
            </div>
          </Dragger>

        </div>

        <Flex gap="middle" align="center" justify="flex-end">
          <Button
            type="text"
            onClick={onClose}
            className="bg-[#1d1d39] h-full text-white text-lg rounded-md px-2 w-[112px]"
            htmlType="button"
            loading={isLoading}
          >
            <FormattedMessage id='cancel' />
          </Button>
          <Button
            type="text"
            className="bg-[#cf2110] h-full text-white text-lg rounded-md px-2 w-[112px]"
            htmlType="submit"
            loading={isLoading}
            onClick={handleUploadFile}
          >
            <FormattedMessage id='save' />
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
};

export default UploadFileModal;
