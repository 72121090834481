import { useEffect, useState } from 'react';
import { Button, Col, Flex, Form, Input, Modal, Row, Select, Upload, message } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../../services/react-query/queryKeys';
import { addELearning } from '../../../network/storeDate';
import queryClient from '../../../services/react-query/queryClient';
import { InboxOutlined } from "@ant-design/icons";
import Dragger from 'antd/es/upload/Dragger';
import { FormattedMessage } from 'react-intl';
import { decodeResponse, decryptItem } from '../../../helpers/apiUtils';
import { FetchData } from '../../../network/fetchData';

interface AddModalProps<T> {
  visible: boolean;
  onClose: () => void;
  chapterData: any;
  eLearningData: any;
}

const UpdateChapter: React.FC<AddModalProps<any>> = ({
  visible,
  onClose,
  chapterData,
  eLearningData,
}) => {
  const [fileList, setFileList] = useState<any>([]);
  const [fileValue, setFileValue] = useState<any>([]);
  const [form] = Form.useForm();

  const { mutate: onUpdateChapter, isLoading } = useMutation(addELearning, {
    onSuccess: (res) => {
      message.success("Submit success!");
      queryClient.invalidateQueries([queryKeys.listChapters]);
      onCancel();
    },
    onError: (error) => {
      message.error("حاول مرة اخرى");
    },
  });

  const values = {
    mode: "chapter",
    type: "get",
  };

  const { data: listChapters } = useQuery(
    [queryKeys.listChapters],
    () => FetchData({
      ...values,
    }),
    {
      keepPreviousData: true,
    }
  );
  const decodedResponse = decodeResponse(listChapters);
  const decryptedData = decodedResponse?.data.map((item: any) => {
    const decryptedItem = decryptItem(item);
    return decryptedItem;
  });

  const handleFileChange = (info: { fileList: any; }) => {
    let fileList = [...info.fileList];

    // 1. Limit the number of uploaded files
    fileList = fileList.slice(-1);

    // 2. Read from response and show file link
    fileList = fileList.map(file => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url;
      }
      return file;
    });

    // Update the component state
    setFileList(fileList);
    setFileValue(fileList.map(file => file.originFileObj));

    // You can also log or perform other actions with the file here
    console.log(fileList);
  };

  const handleRemove = () => {
    setFileList(null);
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  const onFinish = async (values: any) => {
    let form_data = new FormData();
    form_data.append('mode', "chapter");
    form_data.append('type', "assign_chapter");
    form_data.append('name', values.name);
    form_data.append('id', chapterData.id);
    form_data.append('e_learning_id', eLearningData?.id);
    form_data.append('content', fileValue[0]);

    onUpdateChapter(form_data);
  };

  const initialValues = {
    chapter_id: chapterData?.chapter_id,
  }

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(initialValues);
    }

  }, [visible, initialValues, form]);

  return (
    <Modal
      centered
      open={visible}
      className="customModal"
      onOk={onFinish}
      onCancel={onCancel}
      footer={[]}
      title={<FormattedMessage id='edit_chapter' />}
      width={1050}
    >

      <Form
        onFinish={onFinish}
        name="complex-form"
        layout="vertical"
        form={form}
        encType="multipart/form-data"
        className="form-style mt-8 px-9 pb-5"
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 14 }}>
          <Col xl={24}>
            <Form.Item
              label={<FormattedMessage id={'chapter'} />}
              required={false}
              name="chapter_id"
              rules={[{ required: true, message: 'Missing Chapter' }]}
            >
              <Select
                placeholder="Choces Chapter"
                options={decryptedData?.map((item: { id: any; name: any; }) => ({
                  value: item?.id,
                  label: item?.name,
                }))}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Dragger
            onChange={handleFileChange}
            onRemove={handleRemove}
            multiple={false}
            showUploadList={{ showRemoveIcon: true }}
          >
            <div className="p-5">
              <div className="flex items-center justify-center gap-[10px] py-2 px-6 border-[1px] border-solid w-fit mx-auto rounded-lg border-[#75757E]">
                <InboxOutlined style={{ fontSize: "19px", color: "#9999A7" }} />
                <span className="text-[#75757E]"><FormattedMessage id={'upload_text1'} /></span>
              </div>
              <span className="text-xs font-normal text-[#75757E]">
                <FormattedMessage id={'upload_text2'} />
              </span>
            </div>
          </Dragger>
        </Form.Item>

        <Flex gap="middle" align="center" justify="flex-end">
          <Button
            type="text"
            onClick={() => onCancel()}
            loading={isLoading}
            className="bg-[#1d1d39] h-full text-white text-lg rounded-md px-2 w-[112px]"
            htmlType="button"
          >
            <FormattedMessage id={'cancel'} />
          </Button>
          <Button
            type="text"
            loading={isLoading}
            className="bg-[#cf2110] h-full text-white text-lg rounded-md px-2 w-[112px]"
            htmlType="submit"
          >
            <FormattedMessage id={'save'} />
          </Button>
        </Flex>
      </Form>
    </Modal>

  );
}

export default UpdateChapter;

