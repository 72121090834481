import React from "react";
import { FormattedMessage } from "react-intl";
import CustomHeader from "../../components/common/CustomHeader";
import UserTable from "../../components/user/UserTable";

export default function User() {

  return (
    <>
      <CustomHeader title={<FormattedMessage id={'users'} />} />
      <UserTable />
    </>
  );
}


