import { useEffect, useState } from 'react';
import { Button, Col, Flex, Form, Input, Modal, Row, Select, Upload, message } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../services/react-query/queryKeys';
import { addELearning } from '../../network/storeDate';
import queryClient from '../../services/react-query/queryClient';
import { RcFile } from 'antd/es/upload';
import UploudImgSVG from '../../assets/teamMember/UploudImgSVG';
import TextArea from 'antd/es/input/TextArea';
import { lang } from '../../enum/lang';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { FetchData } from '../../network/fetchData';
import { decodeResponse, decryptItem } from '../../helpers/apiUtils';

interface AddModalProps<T> {
  visible: boolean;
  onClose: () => void;
  eLearningData: any;
}

const UpdateELearning: React.FC<AddModalProps<any>> = ({
  visible,
  onClose,
  eLearningData,
}) => {
  const currentLang = useSelector((state: any) => state?.user?.currentLang);
  const [form] = Form.useForm();

  const { mutate: onUpdateELearning, isLoading } = useMutation(addELearning, {
    onSuccess: (res) => {
      message.success("Submit success!");
      queryClient.invalidateQueries([queryKeys.listELearnings]);
      onClose();
    },
    onError: (error) => {
      message.error("حاول مرة اخرى");
    },
  });

  const values = {
    mode: "certificate",
    type: "get",
    language_id: currentLang === "ar" ? 1 : currentLang === "en" ? 2 : null,
  };

  const { data: listCertificates } = useQuery(
    [queryKeys.listCertificates, values.language_id],
    () => FetchData({
      ...values,
    }),
    {
      keepPreviousData: true,
    }
  );

  const decodedResponse = decodeResponse(listCertificates);
  const decryptedData = decodedResponse?.data.map((item: any) => {
    const decryptedItem = decryptItem(item);
    return decryptedItem;
  });

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  const onFinish = async (values: any) => {
    let form_data = new FormData();
    form_data.append('mode', "e-learning");
    form_data.append('type', "update");
    form_data.append('certificate_id', values?.certificate_id);
    form_data.append('id', eLearningData?.id);
    form_data.append('instruction', encodeURIComponent(values?.instruction));

    onUpdateELearning(form_data);
  };

  const initialValues = {
    certificate_id: eLearningData?.certificate_id,
    instruction: decodeURIComponent(eLearningData?.instruction),
  }

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(initialValues);
    }

  }, [visible, initialValues, form]);

  return (
    <Modal
      centered
      open={visible}
      className="customModal"
      onOk={onFinish}
      onCancel={onCancel}
      footer={[]}
      title={<FormattedMessage id='edit_e_learning' />}
      width={1050}
    >

      <Form
        onFinish={onFinish}
        name="complex-form"
        form={form}
        layout="vertical"
        className="form-style mt-8 px-9 pb-5"
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 14 }}>
          <Col xl={24}>
            <Form.Item
              label={<FormattedMessage id={'certificate'} />}
              required={false}
              name="certificate_id"
              rules={[{ required: true, message: 'Missing Certificate' }]}
            >
              <Select
                placeholder="Choces Chapter"
                options={decryptedData?.map((item: { id: any; name: any; }) => ({
                  value: item?.id,
                  label: item?.name,
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 14 }}>
          <Col xl={24}>
            <Form.Item
              name="instruction"
              label={<FormattedMessage id='instruction' />}
              required={false}
              className="form-style w-full"
              rules={[
                { required: true, message: 'Instruction is required' },
              ]}
            >
              <TextArea placeholder="Enter Instruction" rows={5}
                showCount />
            </Form.Item>
          </Col>
        </Row>


        <Flex gap="middle" align="center" justify="flex-end">
          <Button
            type="text"
            onClick={() => onCancel()}
            loading={isLoading}
            className="bg-[#1d1d39] h-full text-white text-lg rounded-md px-2 w-[112px]"
            htmlType="button"
          >
            <FormattedMessage id={'cancel'} />
          </Button>
          <Button
            type="text"
            loading={isLoading}
            className="bg-[#cf2110] h-full text-white text-lg rounded-md px-2 w-[112px]"
            htmlType="submit"
          >
            <FormattedMessage id={'save'} />
          </Button>
        </Flex>
      </Form>
    </Modal>

  );
}

export default UpdateELearning;

