import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export default function TopPurchasedProducts() {

  const currentLang = useSelector((state) => state?.user?.currentLang);

  const customOptions = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
        position: "bottom",
        ticks: {
          color: "black",
          font: { size: 12, family: "Arial" },
        },
        grid: {
          color: "rgba(0, 0, 0, 0)",
        },
      },
      y: {
        stacked: true,
        position: currentLang === "ar" ? "right" : "left",
        ticks: {
          color: "black",
          font: { size: 12, family: "Arial" },
        },
        grid: {
          color: "rgba(0, 0, 0, 0)",
        },
      },
    },
    indexAxis: "x",
    maintainAspectRatio: false,
    aspectRatio: 1,
    plugins: {
      tooltip: {
        mode: "index",
        intersect: false,
      },
    },
    parsing: {
      xAxisKey: "month",
      yAxisKey: "value",
    },
  };
  const generateLabels = () => {
    const labels = {
      en: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      ar: [
        "يناير",
        "فبراير",
        "مارس",
        "أبريل",
        "مايو",
        "يونيو",
        "يوليو",
        "أغسطس",
        "سبتمبر",
        "أكتوبر",
        "نوفمبر",
        "ديسمبر",
      ],
    };

    return currentLang === "ar" ? labels.ar : labels.en;
  };
  const reversedLabels = generateLabels().slice().reverse();
  const dataset1 = [50, 70, 50, 60, 50, 80, 90, 50, 80, 79, 40, 10].reverse();
  const dataset2 = [50, 70, 50, 60, 50, 80, 90, 50, 80, 79, 40, 10].reverse();
  const dataset3 = [50, 70, 50, 60, 50, 80, 90, 50, 80, 79, 40, 10].reverse();
  const dataset4 = [50, 70, 50, 60, 50, 80, 90, 50, 80, 79, 40, 10].reverse();
  const customData = {
    labels: reversedLabels,
    datasets: [
      {
        label: "IBDL Essential",
        data: dataset1,
        backgroundColor: "#0E103A",
      },
      {
        label: "IBDL Professional",
        data: dataset2,
        backgroundColor: "rgba(123, 172, 210, 0.8)",
      },
      {
        label: "IBDL Master",
        data: dataset3,
        backgroundColor: "rgba(197, 224, 244, 0.8)",
      },
      {
        label: "Certified Educator",
        data: dataset4,
        backgroundColor: "rgba(199, 222, 222, 0.8)",
      },
    ],
  };


  return (
    <div dir="rtl" style={{ height: "18rem" }}>
      <Bar options={customOptions} data={customData} />
    </div>
  );
}
