// Define an enum with numeric values
enum questionTypeEnum {
  MCQ = "mcq",
  TrueFalse = "true_false",
}

// Create a constant array to define the questionTypeType
const questionType: { value: string; label: string }[] = [
  { value: questionTypeEnum.MCQ, label: 'MCQ' },
  { value: questionTypeEnum.TrueFalse, label: 'True & False' },
];

export { questionTypeEnum, questionType };
