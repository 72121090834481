import { FC } from "react";

interface EditSVGProps {
    width?: number;
    height?: number;
}

const EditSVG: FC<EditSVGProps> = ({ width = "18px", height = "18px" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="black" >
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3527 2.60225C11.2472 2.49676 11.1041 2.4375 10.9549 2.4375C10.8058 2.4375 10.6627 2.49676 10.5572 2.60225L3.66291 9.49654C3.59224 9.56721 3.54168 9.65544 3.51642 9.75213L2.76642 12.6235C2.71595 12.8167 2.7717 13.0222 2.91291 13.1634C3.05412 13.3046 3.2596 13.3603 3.45282 13.3099L6.32413 12.5599C6.42083 12.5346 6.50906 12.484 6.57973 12.4134L13.474 5.51907C13.6937 5.2994 13.6937 4.94324 13.474 4.72357L11.3527 2.60225ZM4.56633 10.1841L10.9549 3.7955L12.2808 5.12132L5.89215 11.5099L4.09758 11.9787L4.56633 10.1841Z" fill="black" />
            <path d="M3 14.4375C2.68934 14.4375 2.4375 14.6893 2.4375 15C2.4375 15.3107 2.68934 15.5625 3 15.5625H14.25C14.5607 15.5625 14.8125 15.3107 14.8125 15C14.8125 14.6893 14.5607 14.4375 14.25 14.4375H3Z" fill="black" />
        </svg>
    );
};

export default EditSVG;
