import AddCorporate from "../components/corporate/AddCorporate";
import Corporate from "../pages/corporate/Corporate";
import CorporateData from "../pages/corporate/CorporateData";
import UserBatchTable from "../components/corporate/batch/UserBatchTable";

const routesCorporate = [
  {
    path: "corporate",
    element: <Corporate />,
  },
  {
    path: "corporate/data",
    element: <CorporateData />,
  },
  {
    path: "add/corporate",
    element: <AddCorporate />,
  },
  {
    path: "batch/data",
    element: <UserBatchTable />,
  }
];

export default routesCorporate;
