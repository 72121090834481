import React, { useState } from 'react';
import { Input, Select, Space } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useQuery } from '@tanstack/react-query';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import PhoneInputWithCountrySelect from 'react-phone-number-input';
import { FormattedMessage } from 'react-intl';
import { queryKeys } from '../../services/react-query/queryKeys';
import { FetchData } from '../../network/fetchData';
import { decodeResponse, decryptItem } from '../../helpers/apiUtils';
import DeleteModal from '../common/DeleteModal';
import FilterTable from '../common/filtersTables/FilterTable';
import UploadFileModal from '../common/UploadFile';
import CustomTable from '../common/CustomTable';
import { deleteData } from '../../network/deleteData';
import { StoreData } from '../../network/storeDate';
import AddModal from '../common/AddModal';
import UpdateModal from '../common/UpdateModal';
import { role } from '../../enum/role';

interface DataType {
  role: any;
  user: any;
  id: React.Key;
  name: string;
  email: string;
  roles: any;
  start_date: string;
  end_date: string;
  success_rate: string;
  progress: string;
}


export default function UserTable() {
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState<React.Key | null>(null);
  const [modalAction, setModalAction] = useState<'add' | 'update' | "delete" | "upload" | null>(null);
  const location = useLocation();

  const [phoneNumber, setPhoneNumber] = useState("");

  const handlePhoneNumberChange = (value: string) => {
    setPhoneNumber(value);
  };

  const [searchTerm, setSearchTerm] = useState('');

  const showModal = (action: 'add' | 'update' | 'delete' | 'upload', id?: React.Key) => {
    setSelectedRowId(id !== undefined ? id : null);
    setModalAction(action);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const values = {
    mode: "auth",
    type: "all_user",
  };

  const deleteValue = {
    mode: "batch",
    type: "delete_user_batch",
    id: selectedRowId
  };

  const { data: listUser } = useQuery(
    [queryKeys.listUser],
    () => FetchData(values)
  );

  const decodedResponse = decodeResponse(listUser);
  const decryptedData = decodedResponse?.data.map((item: any) => {
    const decryptedItem = decryptItem(item);
    const excludedFields = ['role'];

    const itemWithoutDecryption = Object.fromEntries(
      Object.entries(decryptedItem).map(([key, value]) => {
        return excludedFields.includes(key) ? [key, item[key]] : [key, value];
      })
    );

    return itemWithoutDecryption;
  });

  const columns: ColumnsType<DataType> = [
    {
      title: <FormattedMessage id={'name'} />,
      dataIndex: 'name',
    },
    {
      title: <FormattedMessage id={'email'} />,
      dataIndex: 'email',
    },
    {
      title: <FormattedMessage id={'phone'} />,
      dataIndex: 'phone',
    },
    {
      title: <FormattedMessage id={'role'} />,
      dataIndex: 'role',
    },
    {
      key: 'operation',
      width: 100,
      render: (record: DataType) => (
        <Space>
          <EditOutlined className='text-gray-400' onClick={() => showModal('update', record.id)} />
          <DeleteOutlined className='text-gray-400' onClick={() => showModal('delete', record.id)} />
        </Space>
      ),
    },
  ];


  const formFields = [
    { name: "name", label: <FormattedMessage id={'name'} />, rules: [{ required: true, message: "Name is required" }], input: <Input placeholder="Enter Name" />, colSize: 8 },
    { name: "email", label: <FormattedMessage id={'email'} />, rules: [{ required: true, message: "Email is required" }], input: <Input placeholder="Enter Email" />, colSize: 8 },
    {
      name: "phone", label: <FormattedMessage id={'phone'} />, input: <PhoneInputWithCountrySelect
        defaultCountry="SA"
        name='phone_country_code'
        international
        onChange={() => { }}
      />, colSize: 8
    },
    {
      name: "role", label: <FormattedMessage id={'role'} />, input: <Select
        placeholder="choces role"
        options={role?.map((item) => ({
          value: item.value,
          label: item.label,
        }))}
      />, colSize: 8
    }
  ];

  const updateFormFields = [
    { name: "name", label: <FormattedMessage id={'name'} />, rules: [{ required: true, message: "Name is required" }], input: <Input placeholder="Enter Name" />, colSize: 8 },
    { name: "email", label: <FormattedMessage id={'email'} />, rules: [{ required: true, message: "Email is required" }], input: <Input placeholder="Enter Email" />, colSize: 8 },
    {
      name: "phone", label: <FormattedMessage id={'phone'} />, input: <PhoneInputWithCountrySelect
        defaultCountry="SA"
        name='phone_country_code'
        international
        onChange={() => { }}
      />, colSize: 8
    }, {
      name: "batch_id", label: "", input: <Input className='hidden' />
      , colSize: 0
    },];

  console.log("decryptedData", decryptedData);
  return (
    <>
      <AddModal
        visible={isModalVisible && modalAction === 'add'}
        confirmationHeader={<FormattedMessage id={'add_user'} />}
        queryKey={queryKeys.listUser}
        onClose={closeModal}
        onSubmit={StoreData}
        formFields={formFields}
        mode='auth'
        type='add_user' initialValues={undefined} />

      <UpdateModal
        visible={isModalVisible && modalAction === 'update'}
        confirmationHeader={<FormattedMessage id={'edit_user'} />}
        queryKey={queryKeys.listUser}
        onClose={closeModal}
        selectedRowId={selectedRowId}
        onSubmit={StoreData}
        initialValues={{
          name: decryptedData?.find((item: { id: React.Key | null }) => item.id === selectedRowId)?.name,
          email: decryptedData?.find((item: { id: React.Key | null }) => item.id === selectedRowId)?.email,
          // phone: decryptedData?.find((item: { id: React.Key | null }) => item.id === selectedRowId)?.phone||'',
        }}
        formFields={updateFormFields}
        mode='batch'
        type='update_user'
      />

      <FilterTable onSearch={(value) => setSearchTerm(value)} buttonText={<FormattedMessage id={'add_user'} />} onClick={() => showModal('add')} onClickUpload={() => showModal('upload')} />

      <UploadFileModal visible={isModalVisible && modalAction === 'upload'} onClose={closeModal} mode={"assessment_advice"} confirmationHeader={''} successMessage={''} queryKey={queryKeys.listAdvice} />

      <DeleteModal
        visible={isModalVisible && modalAction === 'delete'}
        onDelete={deleteData}
        data={deleteValue}
        confirmationHeader={<FormattedMessage id={'user'} />}
        queryKey={queryKeys.listUser}
        onClose={closeModal}
      />

      <CustomTable columns={columns} data={decryptedData} />

    </>
  );
};

