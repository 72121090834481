import { FC } from "react";

interface PhoneSVGProps {
    width?: number;
    height?: number;
}

const PhoneSVG: FC<PhoneSVGProps> = ({ width = "18px", height = "18px" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.89267 6.57094C5.08421 9.16554 7.20454 11.2283 9.84038 12.346L9.8487 12.3496L10.3579 12.5765C11.0063 12.8654 11.7685 12.6603 12.1842 12.0849L13.0338 10.909C13.0857 10.8372 13.0723 10.7373 13.0034 10.6817L11.5202 9.48518C11.4465 9.4257 11.338 9.43955 11.2816 9.51566L10.7044 10.2946C10.5642 10.4838 10.311 10.5495 10.0965 10.4524C8.1256 9.56045 6.54179 7.97665 5.64984 6.00571C5.55276 5.7912 5.6185 5.53804 5.80766 5.39785L6.58658 4.82061C6.66269 4.7642 6.67655 4.65579 6.61706 4.58206L5.42037 3.09869C5.36475 3.02975 5.26492 3.01636 5.1931 3.06821L4.0109 3.92165C3.43171 4.33977 3.22774 5.10834 3.52338 5.75864L3.89227 6.57008C3.89241 6.57037 3.89254 6.57065 3.89267 6.57094ZM9.44578 13.2649C6.58176 12.0492 4.27818 9.80717 2.98349 6.98734L2.98271 6.98564L2.61304 6.1725C2.12031 5.08866 2.46026 3.80772 3.42558 3.11085L4.60777 2.25741C5.11052 1.89447 5.80934 1.98821 6.19867 2.4708L7.39537 3.95416C7.81174 4.47028 7.71476 5.22921 7.18198 5.62404L6.73565 5.9548C7.48959 7.41773 8.68451 8.61266 10.1474 9.3666L10.4782 8.92026C10.873 8.38748 11.632 8.29051 12.1481 8.70688L13.6312 9.90341C14.114 10.2929 14.2076 10.9919 13.8444 11.4947L12.9948 12.6705C12.3019 13.6295 11.0316 13.9715 9.95094 13.49L9.44578 13.2649Z" fill="#9999A7"/>
      </svg>
    );
};

export default PhoneSVG;
