import { Card, Col, Flex, Row, Typography, message } from "antd";
import React, { useEffect, useState } from "react";
import { Content } from "antd/es/layout/layout";
import LoginForm from "../../../components/auth/LoginForm";
import useGetUserData from "../../../hooks/useGetUserData";
import { useNavigate } from "react-router-dom";
import Rectangle from "../../../assets/Rectangle.png";
import logo from "../../../assets/footer-logo.png";
import icon from "../../../assets/icon-white1.png";
import { FormattedMessage } from "react-intl";

export default function Login() {
  const { Link } = Typography;
  const navigate = useNavigate();
  const { isLoggedIn } = useGetUserData();
  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn, navigate]);
  if (isLoggedIn) return null;


  return (
    <Content>
      <div style={{ position: "absolute", background: "#0E103A", height: "95px", width: "100%", marginTop: "50px" }}>

        <Row className="text-center  pt-5" justify={"space-around"}>

          <Col className="text-5xl text-white text-center">
            <span>
              <img src={icon} alt="" className="w-[40px] h-[40px] mt-1 mr-20" />
            </span>
            Welcome To IBDL
          </Col>
          <Col >
          </Col>
        </Row>

      </div>
      <Flex
        justify="center"
        align="center"
        style={{
          height: "100vh",
          backgroundImage: `url(${Rectangle})`,
          backgroundSize: "432px 100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right",
        }}
        vertical
      >
        <div style={{ position: "absolute", right: "100px" }}>
          <img src={logo} alt="Logo" style={{ width: "240px", height: "80px" }} />
        </div>

        <Card className="px-8 pt-7 mr-80 mt-28"
          bordered={false}
        >
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <LoginForm />
          </div>
        </Card>
      </Flex>
    </Content>

  );
}
