import AddExamConfigration from "../components/exam/examConfigration/AddExamConfigration";
import EditExamConfiguration from "../components/exam/examConfigration/EditExamConfiguration";
import ExamConfiguration from "../components/exam/examConfigration/ListExamConfigurations";
import ListExams from "../components/exam/examMonitoring/ListExams";
import ExamScheduling from "../components/exam/examScheduling/ExamScheduling";
import AddQuestionsBank from "../components/exam/questionsBank/AddQuestionsBank";
import EditQuestionsBank from "../components/exam/questionsBank/EditQuestionsBank";
import QuestionsBank from "../components/exam/questionsBank/QuestionsBank";
import ExamConfigurationData from "../pages/exam/ExamConfigurationData";
import ExamineeMonitoringData from "../pages/exam/ExamineeMonitoringData";
import ExamMonitoringData from "../pages/exam/ExamMonitoringData";
import ExamSchedulingData from "../pages/exam/ExamSchedulingData";

const routesExam = [
  {
    path: "questions/bank",
    element: <QuestionsBank />,
  },
  {
    path: "add/questions",
    element: <AddQuestionsBank />,
  },
  {
    path: "update/questions",
    element: <EditQuestionsBank />,
  },

// Exam Configuration

  {
    path: "exams/configuration",
    element: <ExamConfiguration />,
  },
  {
    path: "add/exam/configuration",
    element: <AddExamConfigration />,
  },
  {
    path: "edit/exam/configuration",
    element: <EditExamConfiguration />,
  },
  {
    path: "exam/configuration/data",
    element: <ExamConfigurationData />,
  },

// Exam Schedule

  {
    path: "exams/schedule",
    element: <ExamScheduling />,
  },
  {
    path: "exam/schedule/data",
    element: <ExamSchedulingData />,
  },

// Exam Monitoring

  {
    path: "exams/monitoring",
    element: <ListExams />,
  },
  {
    path: "exam/data",
    element: <ExamMonitoringData />,
  },
// Examinee Info

  {
    path: "examinee/data",
    element: <ExamineeMonitoringData />,
  }
];

export default routesExam;
