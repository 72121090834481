import Assessment from "../pages/assessment/Assessment";
import AssessmentData from "../pages/assessment/AssessmentData";
import AssessmentSubCategoryData from "../pages/assessment/AssessmentSubCategoryData";

const routesAssessment = [
  {
    path: "assessment",
    element: <Assessment />,
  },
  {
    path: "assessment/data",
    element: <AssessmentData />,
  },
  {
    path: "subcategories",
    element: <AssessmentSubCategoryData />,
  }
];

export default routesAssessment;
