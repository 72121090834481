import { css } from "@emotion/css";
import { Button, Layout, Menu, Space, Input, Dropdown, Avatar, Row, Col } from "antd";
import { Outlet, useNavigate } from "react-router-dom";
import useGetUserData from "../../hooks/useGetUserData";
import React from "react";
import SidebarMenu from "./SidebarMenu";
import {
  DownOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import langIcon from "../../assets/images/lang-change-icon.svg"
import { Link } from "react-router-dom";
import { decryptData } from '../../helpers/apiUtils';
import { setCurrentLang, setUserData } from "../../services/store/slices/user";
import { useDispatch, useSelector } from "react-redux";
import logox from "../../assets/logo.png";
import icon from "../../assets/icon-white1.png";
import { FormattedMessage, useIntl } from "react-intl";

const { Content, Header } = Layout;

const OwnLayoutStyles = css`
    display: flex;
    height: 75vh;
  `;
const contentStyles = css`
    flex: 1;
    overflow-y: auto;
    height: calc(75vh);
  `;

function OwnLayout() {
  const navigate = useNavigate();
  const { isLoggedIn, user } = useGetUserData();
  const dispatch = useDispatch();
  const decryptedItem = decryptData(user.name);
  const currentLang = useSelector((state) => state?.user?.currentLang);
  const handelChangeLng = () => {
    dispatch(setCurrentLang(currentLang === "ar" ? "en" : "ar"));
  }

  const userRoles = useSelector((s) => s?.user?.data?.user?.roles.map(role => role.name)) || [];
  return (
    <Layout className={OwnLayoutStyles}>
      <Header style={{ background: "white", display: "flex", padding: "5px", marginRight: "30px", marginLeft: "30px", justifyContent: "flex-end" }} >
        <Dropdown overlay={
          <Menu>
            <Menu.Item
              key="3"
              icon={<UserOutlined />}
              className="brown-color"
            >
              <Link to={`/`}><FormattedMessage id={'personal_account'} /></Link>
            </Menu.Item>
            <Menu.Item
              className="logout-menu-item"
              key="4"
              icon={<LogoutOutlined />}
              onClick={() => {
                dispatch(setUserData(null));
                localStorage.clear();
                navigate("/login");
                return;
              }}
            >
              <FormattedMessage id={'logout'} />
            </Menu.Item>
          </Menu>
        }>
          <a onClick={(e) => e.preventDefault()} className="text-black">
            <Space>
              <Avatar size={32} src="" icon={<UserOutlined />} />
              <p className="user-name">{decryptedItem}</p>
              <DownOutlined />
            </Space>
          </a>
        </Dropdown>

        <Button className="border-none" onClick={handelChangeLng} icon={<img src={langIcon} alt="" />}>
          {currentLang === "ar" ? "English" : "العربية"}
        </Button>
      </Header>

      <Layout>
        <Header>
          <div className="px-9 bg-white mt-5">
            <img src={logox} alt="" className="w-[200px] h-[60px] items-center" />
          </div>
          <Row className="text-white header-section">
            <Col>
              <img src={icon} alt="" className="w-[30px] h-[30px] grid justify-items-center content-center mt-1 mx-2" />
            </Col>
            <Col className="text-4xl">
              <FormattedMessage id={'IBDL_admin_control'} />
            </Col>
          </Row>
          <div></div>

        </Header>
        <Layout>
          {userRoles.includes("admin") && <SidebarMenu />}
          <Layout className={userRoles.includes("admin") && contentStyles}>
            <Content className="m-[2.8vw]" >
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </Layout>

  );
}

export default OwnLayout;
