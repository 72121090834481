import React from "react";
import { Card, Flex, Tabs, TabsProps } from "antd";
import { css } from "@emotion/css";
import AccreditationCenterTable from "../../components/accreditation/center/AccreditationCenter";
import CustomHeader from "../../components/common/CustomHeader";
import { FormattedMessage } from "react-intl";

export default function Accreditation() {
  return (
    <>
      <CustomHeader title={<FormattedMessage id={'accreditation'} />} />
      <AccreditationCenterTable />
    </>

  );
}


