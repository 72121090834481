import React, { useState } from 'react';
import { Col } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../../services/react-query/queryKeys';
import { decodeResponse, decryptItem } from '../../../helpers/apiUtils';
import { FetchData } from '../../../network/fetchData';
import DeleteModal from '../../common/DeleteModal';
import { deleteData } from '../../../network/deleteData';
import FilterTable from '../../common/filtersTables/FilterTable';
import { EditOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import AddChapter from './AddChapter';
import UpdateChapter from './UpdateChapter';
import { FormattedMessage } from 'react-intl';
import UploadFileModal from '../../common/UploadFile';
import PaginationComponent from '../../common/PaginationComponent';
import { Link } from 'react-router-dom';

interface ChaptersProps {
  eLearningData: any;
}

const Chapters: React.FC<ChaptersProps> = ({ eLearningData }) => {
  const [editChapterData, setEditChapterData] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState<React.Key | null>(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalAction, setModalAction] = useState<'add' | 'update' | "delete" | "upload" | null>(null);

  const showModal = (action: 'add' | 'update' | 'delete' | 'upload', id?: React.Key, data?: any) => {
    setSelectedRowId(id !== undefined ? id : null);
    setEditChapterData(data);
    setModalAction(action);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const deleteValue = {
    mode: "chapter",
    type: "delete",
    id: selectedRowId
  };


  const values = {
    mode: "chapter",
    type: "elearning_chapters",
    e_learning_id: eLearningData.id
  };

  const [searchTerm, setSearchTerm] = useState('');

  const { data: eLearningChapters } = useQuery(
    [queryKeys.eLearningChapters, searchTerm],
    () => FetchData({
      ...values,
      name: searchTerm,
    }),
    {
      keepPreviousData: true,
    }
  );

  const decodedResponse = decodeResponse(eLearningChapters);
  const decryptedData = decodedResponse?.data.map((item: any) => {
    const decryptedItem = decryptItem(item);
    const excludedFields = ['media', 'index_path', "e_learning", "chapter"];

    const itemWithoutDecryption = Object.fromEntries(
      Object.entries(decryptedItem).map(([key, value]) => {
        return excludedFields.includes(key) ? [key, item[key]] : [key, value];
      })
    );

    return itemWithoutDecryption;
  });

  const items = [
    {
      title: <FormattedMessage id='e_learnings' />,
      href: '/e-learning',
    },
    {
      title: <FormattedMessage id='chapters' />,
      href: '',
    }
  ];

  return (
    <>
      <FilterTable items={items} onSearch={(value) => setSearchTerm(value)} buttonText={<FormattedMessage id='add_chapter' />} tabName={<FormattedMessage id='chapters' />} onClick={() => showModal('add')} isUpload={false} />

      <UploadFileModal visible={isModalVisible && modalAction === 'upload'} onClose={closeModal} mode={"assessment_advice"} confirmationHeader={''} successMessage={''} queryKey={queryKeys.listAdvice} />

      <AddChapter
        visible={isModalVisible && modalAction === 'add'}
        onClose={closeModal}
        eLearningData={eLearningData}
      />

      <UpdateChapter
        visible={isModalVisible && modalAction === 'update'}
        onClose={closeModal}
        chapterData={editChapterData}
        eLearningData={eLearningData}
      />

      <DeleteModal
        visible={isModalVisible && modalAction === 'delete'}
        onDelete={deleteData}
        data={deleteValue}
        confirmationHeader={<FormattedMessage id='chapter' />}
        queryKey={queryKeys.listChapters}
        onClose={closeModal}
      />

      <PaginationComponent
        spanNumber={8}
        gutterNumber={10}
        decryptedData={decryptedData}
        actions={(item: any) => (
          <>
            {/* <Col><EditOutlined key="edit" onClick={() => showModal('update', item.id, item)} /></Col> */}
            <Col><Link to={item.index_path} target='_blank' className='text-black'><EyeOutlined /></Link> </Col>
            <Col><DeleteOutlined key="delete" style={{ color: "red" }} onClick={() => showModal('delete', item.id)} /></Col>
          </>
        )}
      />
    </>
  );
}

export default Chapters;
