import React, { useState } from 'react';
import { Row, Col, Card, Empty, Pagination } from 'antd';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';

interface Props {
  decryptedData: any;
  actions: any;
  gutterNumber: any;
  title?: any;
  extra?: any;
  data: (item: any) => React.ReactNode;
  spanNumber: any;
}

const PaginationComponentDynmicData: React.FC<Props> = ({ decryptedData, actions, gutterNumber, spanNumber, data, extra, title }) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage: number = 12;

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const indexOfLastItem: number = currentPage * itemsPerPage;
  const indexOfFirstItem: number = indexOfLastItem - itemsPerPage;
  const currentItems: any[] = decryptedData?.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      {decryptedData && decryptedData.length > 0 ? (
        <>
          <Row gutter={gutterNumber} >
            {currentItems.map((item: any) => (
              <Col key={item.id} className='mb-3' xl={spanNumber} sm={24}>
                <Card
                  style={{ width: 280 }}
                  className='rounded-sm'
                  actions={actions(item)}
                  title={title ? title(item) : ""}
                  extra={extra ? extra(item) : ""}
                >
                  <div >
                    {data(item)}
                  </div>
                </Card>
              </Col>
            ))}
          </Row>

          <Pagination
            current={currentPage}
            total={decryptedData.length}
            pageSize={itemsPerPage}
            onChange={handlePageChange}
            className='mt-1'
          />
        </>
      ) : (
        <Empty description={<FormattedMessage id='no_data' />} className='mt-24' />
      )}
    </>
  );
};

export default PaginationComponentDynmicData;
