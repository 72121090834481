import { useState } from 'react';
import { Button, Col, Flex, Form, Input, Modal, Row, Select, message } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import PhoneInputWithCountrySelect from 'react-phone-number-input';
import { StoreData } from '../../../../network/storeDate';
import queryClient from '../../../../services/react-query/queryClient';
import { queryKeys } from '../../../../services/react-query/queryKeys';
import { decodeResponse, decryptItem } from '../../../../helpers/apiUtils';
import { FetchData } from '../../../../network/fetchData';
import { lang } from '../../../../enum/lang';

interface AddModalProps<T> {
  visible: boolean;
  onClose: () => void;
  assessmentData: any;
}

const AssignUserAssessment: React.FC<AddModalProps<any>> = ({
  assessmentData,
  visible,
  onClose,
}) => {
  const [form] = Form.useForm();

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  const { mutate: addMutation, isLoading } = useMutation(StoreData, {
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries([queryKeys.listELearnings]);
      message.success("Submit success!");
      form.resetFields();
    },
    onError: (error) => {
      message.error("Error Adding");
    },
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const handlePhoneNumberChange = (value: string) => {
    setPhoneNumber(value);
  };

  const onFinish = async (values: any) => {

    const formData = {
      mode: "e-learning",
      type: "assign_user",
      id: assessmentData.id,
      ...values
    };
    addMutation(formData);
  };

  const handleChange = (value: string[]) => {
    console.log(`selected ${value}`);
  };

  const values = {
    mode: "auth",
    type: "all_user",
  };

  const { data: listUser } = useQuery(
    [queryKeys.listUser],
    () => FetchData(values)
  );

  const decodedResponse = decodeResponse(listUser);
  const decryptedData = decodedResponse?.data.map((item: any) => {
    const decryptedItem = decryptItem(item);
    const excludedFields = ['role'];

    const itemWithoutDecryption = Object.fromEntries(
      Object.entries(decryptedItem).map(([key, value]) => {
        return excludedFields.includes(key) ? [key, item[key]] : [key, value];
      })
    );

    return itemWithoutDecryption;
  });

  const usersWithRoleUser = decryptedData?.filter((item: any) => item.role === 'user');

  return (
    <Modal
      centered
      open={visible}
      className="customModal"
      onOk={onFinish}
      onCancel={onCancel}
      footer={[]}
      title={<FormattedMessage id='add_e_learning_users' />}
      width={1050}
    >

      <Form
        onFinish={onFinish}
        name="complex-form"
        layout="vertical"
        form={form}
        encType="multipart/form-data"
        className="form-style mt-8 px-9 pb-5"
      >

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 14 }}>
          <Col xl={4}>
            <Form.Item
              name="language_id"
              label={<FormattedMessage id='language' />}
              required={false}
              className="form-style w-full"
              rules={[
                { required: true, message: 'language is required' },
              ]}
            >
              <Select
                placeholder="choces language"
                options={lang?.map((item) => ({
                  value: item.value,
                  label: item.label,
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={20}>
            <Form.Item
              name="user_ids"
              label={<FormattedMessage id='users' />}
              required={false}
              className="form-style w-full"
              rules={[
                { required: false, message: 'users is required' },
              ]}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Please Select Users"
                onChange={handleChange}
                options={usersWithRoleUser?.map((item: { id: any; email: any; }) => ({
                  value: item.id,
                  label: item?.email,
                }))}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.List name="users">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 14 }}>
                  <Col span={8}>
                    <Form.Item
                      {...restField}
                      name={[name, 'name']}
                      rules={[{ required: true, message: 'Missing name' }]}
                    >
                      <Input placeholder="Name" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      {...restField}
                      name={[name, 'email']}
                      rules={[
                        { required: true, message: 'Email is required' },
                      ]}                    >
                      <Input placeholder="Enter Email " />
                    </Form.Item>
                  </Col>
                  <Col xl={7}>
                    <Form.Item
                      name={[name, 'phone']}
                      className="form-style w-full"
                      required={false}
                    >
                      <PhoneInputWithCountrySelect
                        defaultCountry="SA"
                        name='phone_country_code'
                        international
                        onChange={() => {}}
                      />
                    </Form.Item>
                  </Col>
                  <Col className='mt-1'>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Col>
                </Row>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Add User
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Flex gap="middle" align="center" justify="flex-end">
          <Button
            type="text"
            onClick={() => onCancel()}
            loading={isLoading}
            className="bg-[#1d1d39] h-full text-white text-lg rounded-md px-2 w-[112px]"
            htmlType="button"
          >
            <FormattedMessage id={'cancel'} />
          </Button>
          <Button
            type="text"
            loading={isLoading}
            className="bg-[#cf2110] h-full text-white text-lg rounded-md px-2 w-[112px]"
            htmlType="submit"
          >
            <FormattedMessage id={'save'} />
          </Button>
        </Flex>
      </Form>
    </Modal>

  );
}

export default AssignUserAssessment;

