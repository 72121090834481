import React, { useState } from 'react';
import { DatePicker, Input, Space } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useQuery } from '@tanstack/react-query';
import { FetchData } from '../../../network/fetchData';
import { decodeResponse, decryptItem } from '../../../helpers/apiUtils';
import FilterTable from '../../common/filtersTables/FilterTable';
import useCustomRowSelection from '../../common/filtersTables/TableSelectionRow';
import { EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { queryKeys } from '../../../services/react-query/queryKeys';
import DeleteModal from '../../common/DeleteModal';
import { deleteData } from '../../../network/deleteData';
import { StoreData } from '../../../network/storeDate';
import AddModal from '../../common/AddModal';
import UpdateModal from '../../common/UpdateModal';
import dayjs from 'dayjs';
import CustomTable from '../../common/CustomTable';
import { Link, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import UploadFileModal from '../../common/UploadFile';


interface DataType {
  id: React.Key;
  name: string;
  email: string;
  start_date: string;
  end_date: string;
  success_rate: string;
  progress: string;
}

interface BasicInfoProps {
  corporateData: any;
}

const BatchTable: React.FC<BasicInfoProps> = ({ corporateData }) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState<React.Key | null>(null);
  const [modalAction, setModalAction] = useState<'add' | 'update' | "delete" | "upload" | null>(null);
  const [searchTerm, setSearchTerm] = useState('');

  const showModal = (action: 'add' | 'update' | 'delete' | 'upload', id?: React.Key) => {
    setSelectedRowId(id !== undefined ? id : null);
    setModalAction(action);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const values = {
    mode: "batch",
    type: "get"
  };

  const deleteValue = {
    mode: "batch",
    type: "delete",
    id: selectedRowId
  };


  const { data: listBatchs } = useQuery(
    [queryKeys.listBatchs],
    () => FetchData(values)
  );

  const decodedResponse = decodeResponse(listBatchs);
  const decryptedData = (decodedResponse?.data || []).map((item: any) => {
    const decryptedItem = decryptItem(item);
    const excludedFields = ['start_date', 'end_date', 'progress'];

    const itemWithoutDecryption = Object.fromEntries(
      Object.entries(decryptedItem).map(([key, value]) => {
        return excludedFields.includes(key) ? [key, item[key]] : [key, value];
      })
    );

    return itemWithoutDecryption;
  });

  const columns: ColumnsType<DataType> = [
    {
      title: <FormattedMessage id={'name'} />,
      dataIndex: 'name',
    },
    {
      title: <FormattedMessage id={'start_date'} />,
      dataIndex: 'start_date',
    },
    {
      title: <FormattedMessage id={'end_date'} />,
      dataIndex: 'end_date',
    },
    {
      title: <FormattedMessage id={'success_rate'} />,
      dataIndex: 'success_rate',
    },

    {
      title: <FormattedMessage id={'progress'} />,
      dataIndex: 'progress',
    },
    {
      key: 'operation',
      width: 100,
      render: (record: DataType) => (
        <Space>
          <Link to="/batch/data" className='text-gray-400' state={{ batch_data: record }}><EyeOutlined /></Link>
          <EditOutlined className='text-gray-400' onClick={() => showModal('update', record.id)} />
          <DeleteOutlined className='text-gray-400' onClick={() => showModal('delete', record.id)} />
        </Space>
      ),
    },
  ];

  const filteredData = decryptedData?.filter((item: any) => corporateData?.id === item.corporate_id);

  const start_date = dayjs(decryptedData?.find((item: { id: React.Key | null }) => item.id === selectedRowId)?.start_date);
  const end_date = dayjs(decryptedData?.find((item: { id: React.Key | null }) => item.id === selectedRowId)?.end_date);

  const dateFormat = 'YYYY-MM-DD HH:mm:ss'; // Adjust the format as needed

  const formFields = [
    { name: "name", label: <FormattedMessage id={'name'} />, rules: [{ required: true, message: "Name is required" }], input: <Input placeholder="Enter Name" />, colSize: 8 },
    {
      name: "start_date", label: <FormattedMessage id={'start_date'} />, rules: [{ required: true, message: "Start Date is required" }], input: <DatePicker
        placeholder='Start End Date'

        format={dateFormat}
      />
      , colSize: 8
    },
    {
      name: "end_date", label: <FormattedMessage id={'end_date'} />, input: <DatePicker
        format={dateFormat} placeholder='Choose End Date'
      />
      , colSize: 8
    },
    {
      name: "corporate_id", label: "", input: <Input className='hidden' />
      , colSize: 0
    },
  ];

  const updateFormFields = [
    { name: "name", label: <FormattedMessage id={'name'} />, rules: [{ required: true, message: "Name is required" }], input: <Input placeholder="Enter Name" />, colSize: 8 },
    {
      name: "start_date", label: <FormattedMessage id={'start_date'} />, rules: [{ required: true, message: "Start Date is required" }], input: <DatePicker
        placeholder='Start End Date'

        format={dateFormat}
      />
      , colSize: 8
    },
    {
      name: "end_date", label: <FormattedMessage id={'end_date'} />, input: <DatePicker
        format={dateFormat} placeholder='Choose End Date'
      />
      , colSize: 8
    },
    {
      name: "corporate_id", label: "", input: <Input className='hidden' />
      , colSize: 0
    },
  ];

  const items = [
    {
      title: <FormattedMessage id='corporates' />,
      href: '/corporate',
    },
    {
      title: <FormattedMessage id='batchs' />,
    }
  ];

  return (
    <>
        <FilterTable
        buttonText={<FormattedMessage id='add_batch' />}
        items={items}
        onClick={() => showModal('add', 1)}
        onClickUpload={() => showModal('upload')}
                onSearch={(value) => setSearchTerm(value)}
      />



      <AddModal
        visible={isModalVisible && modalAction === 'add'}
        confirmationHeader={
          <span>
            <FormattedMessage id={'add_msg'} /> {<FormattedMessage id={'batch'} />}
          </span>
        }
        queryKey={queryKeys.listBatchs}
        onClose={closeModal}
        onSubmit={StoreData}
        initialValues={{
          corporate_id: corporateData?.id,
        }}
        formFields={formFields}
        mode='batch'
      />

      <UpdateModal
        visible={isModalVisible && modalAction === 'update'}
        confirmationHeader={
          <span>
            <FormattedMessage id={'update_msg'} /> {<FormattedMessage id={'batch'} />}
          </span>
        }
        queryKey={queryKeys.listBatchs}
        onClose={closeModal}
        selectedRowId={selectedRowId}
        onSubmit={StoreData}
        initialValues={{
          name: decryptedData?.find((item: { id: React.Key | null }) => item.id === selectedRowId)?.name,
          email: decryptedData?.find((item: { id: React.Key | null }) => item.id === selectedRowId)?.email,
          progress: decryptedData?.find((item: { id: React.Key | null }) => item.id === selectedRowId)?.progress,
          success_rate: decryptedData?.find((item: { id: React.Key | null }) => item.id === selectedRowId)?.success_rate,
          start_date: start_date,
          end_date: end_date,
          corporate_id: corporateData?.id,
        }}
        formFields={updateFormFields}
        mode='batch'
      />

      
      <UploadFileModal visible={isModalVisible && modalAction === 'upload'} onClose={closeModal} mode={"assessment_advice"} confirmationHeader={''} successMessage={''} queryKey={queryKeys.listAdvice} />
      
      <DeleteModal
        visible={isModalVisible && modalAction === 'delete'}
        onDelete={deleteData}
        data={deleteValue}
        confirmationHeader= {<FormattedMessage id={'batch'} />}
         queryKey={queryKeys.listBatchs}
        onClose={closeModal}
      />

      <CustomTable columns={columns} data={filteredData} />

    </>
  );
};

export default BatchTable;