import { axiosInstance } from "../services/axiosInstance";
import catchAsync from "../utils/catchAsync";

export const UploadData = catchAsync(async (body) => {
  const { data } = await axiosInstance.post("/IBDL", body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return data;
});