import { AxiosResponse } from "axios";
import { axiosInstance } from "../services/axiosInstance";
import catchAsync from "../utils/catchAsync";

export const authLogin = async (body: {
  mode: string;
  type: string;
  email: string;
  password: string;
}): Promise<AxiosResponse> => {
  return await axiosInstance.post("/IBDL", body);
};
