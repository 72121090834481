import React, { useState } from 'react';
import { Col, Flex, Row, Space } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useQuery } from '@tanstack/react-query';
import { FetchData } from '../../../network/fetchData';
import { decodeResponse, decryptItem } from '../../../helpers/apiUtils';
import FilterTable from '../../common/filtersTables/FilterTable';
import { DownOutlined, UpOutlined, LinkOutlined, CalendarOutlined, DashboardOutlined } from '@ant-design/icons';
import { queryKeys } from '../../../services/react-query/queryKeys';
import DeleteModal from '../../common/DeleteModal';
import { deleteData } from '../../../network/deleteData';
import AddAccreditationTrainer from './AddAccreditationTrainer';
import UpdateAccreditationTrainer from './UpdateAccreditationTrainer';
import { FormattedMessage } from 'react-intl';
import Address from '../../../assets/common/Address ';
import CustomExpandedTable from '../../common/CustomExpandedTable';
import UploadFileModal from '../../common/UploadFile';
import { Link } from 'react-router-dom';

interface DataType {
  id: React.Key;
  name: string;
  email: string;
  start_date: string;
  end_date: string;
  success_rate: string;
  progress: string;
}

interface BasicInfoProps {
  AccreditationCenter: any;
}

const AccreditationTrainerTable: React.FC<BasicInfoProps> = ({ AccreditationCenter }) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState<React.Key | null>(null);
  const [modalAction, setModalAction] = useState<'add' | 'update' | "delete" | "upload" | null>(null);
  const [editAccreditationTrainerData, setEditAccreditationTrainerData] = useState(null);
  const showModal = (action: 'add' | 'update' | 'delete' | 'upload', id?: React.Key, data?: any) => {
    setSelectedRowId(id !== undefined ? id : null);
    setEditAccreditationTrainerData(data);
    setModalAction(action);
    setModalVisible(true);
  };


  const closeModal = () => {
    setModalVisible(false);
  };

  const values = {
    mode: "accreditation_trainer",
    type: "get"
  };

  const deleteValue = {
    mode: "accreditation_trainer",
    type: "delete",
    id: selectedRowId
  };

  const [searchTerm, setSearchTerm] = useState('');

  const { data: listAccreditationTrainer } = useQuery(
    [queryKeys.listAccreditationTrainer, searchTerm],
    () => FetchData({
      ...values,
      name: searchTerm,
    }),
    {
      keepPreviousData: true,
    }
  );

  const decodedResponse = decodeResponse(listAccreditationTrainer);
  const decryptedData = decodedResponse?.data.map((item: any) => {
    const decryptedItem = decryptItem(item);
    const excludedFields = ['cv', 'country', 'city', 'birth_date', 'gender', 'title', 'brief'];

    const itemWithoutDecryption = Object.fromEntries(
      Object.entries(decryptedItem).map(([key, value]) => {
        return excludedFields.includes(key) ? [key, item[key]] : [key, value];
      })
    );

    return itemWithoutDecryption;
  });

  const columns: ColumnsType<DataType> = [
    {
      title: <FormattedMessage id='name' />,
      dataIndex: 'name',
    },
    {
      title: <FormattedMessage id='email' />,
      dataIndex: 'email',
    },
    {
      title: <FormattedMessage id='phone' />,
      dataIndex: 'phone',
    },
    {
      title: <FormattedMessage id='title' />,
      dataIndex: 'title',
    },
    {
      key: 'operation',
      width: 100,
      render: (record: DataType) => (
        <Space>
          <a key="edit" className='text-red-400' onClick={() => showModal('update', record.id, record)}>
            <FormattedMessage id='edit' />
          </a>
          <a key="delete" className='text-red-400' onClick={() => showModal('delete', record.id)}>
            <FormattedMessage id='delete' />
          </a>
        </Space>
      ),
    },
  ];

  const formattedData = decryptedData?.map((record: any) => ({
    name: (record.name),
    email: (record.email),
    phone: (record.phone),
    title: (record.title),
    key: record.id,
    country: record.country,
    city: record.city,
    // birth_date: record?.birth_date,
    gender: record.gender,
    training_field: record.training_field,
    brief: record.brief,
    website: record.website,
    cv: record.cv,
    company: record.company,
    training_hours: record.training_hours,
    id: record.id
  }));

  const items = [
    {
      title: <FormattedMessage id='accreditation' />,
      href: '/accreditation',
    },
    {
      title: <FormattedMessage id='accreditation_trainer' />,
      href: '',
    }
  ];

  return (
    <>
      <FilterTable items={items} onSearch={(value) => setSearchTerm(value)}
        buttonText={<FormattedMessage id={'add_accreditation_trainer'} />} onClick={() => showModal('add')} isUpload={false} />

      <AddAccreditationTrainer
        visible={isModalVisible && modalAction === 'add'}
        accreditationCenterData={AccreditationCenter}
        onClose={closeModal}
      />

      <UpdateAccreditationTrainer
        visible={isModalVisible && modalAction === 'update'}
        onClose={closeModal}
        editAccreditationTrainerData={editAccreditationTrainerData}
        accreditationCenterData={AccreditationCenter}
      />

      <DeleteModal
        visible={isModalVisible && modalAction === 'delete'}
        onDelete={deleteData}
        data={deleteValue}
        confirmationHeader={<FormattedMessage id={'accreditation_trainer'} />}
        queryKey={queryKeys.listAccreditationTrainer}
        onClose={closeModal}
      />

      <CustomExpandedTable
        columns={columns}
        data={formattedData}
        expandableData={{
          expandRowByClick: true,
          expandedRowRender: (record: {
            cv: any;
            country: any;
            city: any;
            birth_date: any;
            type_accreditation_trainer: any; gender: any
            training_field: any; brief: any;
            website: any
            company: any; training_hours: any
          }) =>
            <div className="p-0">

              <Row className='bg-[#FAFAFA] p-3 mb-2' justify={"space-between"}>
                <Col span={6}>
                  <Row >
                    <Col className='text-[#0E103A] text-[16px] text-center font-extrabold'>
                      <Row >
                        <Col className='pt-1' >
                          <DashboardOutlined className='text-[20px]' />
                        </Col>
                        <Col className='px-3 text-[#0E103A] text-[16px] text-center font-extrabold'>
                          <FormattedMessage id={'training_hours'} />:
                          <span className='text-[#8C8C8C]'>
                            <br />
                            {record?.training_hours}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col span={6}>
                  <Row>
                    <Col className='text-[#0E103A] text-[16px] font-extrabold'>
                      <Row >
                        <Col className='pt-1' >
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M17.6786 8.92857H16.4286C16.3304 8.92857 16.25 9.00893 16.25 9.10714V16.25H1.60714V1.60714H8.75C8.84822 1.60714 8.92857 1.52679 8.92857 1.42857V0.178571C8.92857 0.0803571 8.84822 0 8.75 0H0.714286C0.319196 0 0 0.319196 0 0.714286V17.1429C0 17.5379 0.319196 17.8571 0.714286 17.8571H17.1429C17.5379 17.8571 17.8571 17.5379 17.8571 17.1429V9.10714C17.8571 9.00893 17.7768 8.92857 17.6786 8.92857Z" fill="#0E103A" />
                            <path d="M5.44405 9.43973L5.40164 12.0937C5.39941 12.2924 5.56012 12.4554 5.75878 12.4554H5.76771L8.40164 12.3906C8.44628 12.3884 8.49092 12.3705 8.52217 12.3393L17.8057 3.07589C17.8749 3.0067 17.8749 2.89286 17.8057 2.82366L15.0311 0.0513393C14.9954 0.0156251 14.9507 0 14.9039 0C14.857 0 14.8124 0.0178572 14.7766 0.0513393L5.49539 9.31473C5.46315 9.34845 5.44481 9.39309 5.44405 9.43973ZM6.86146 9.96652L14.9039 1.94196L15.9128 2.94866L7.86592 10.9777L6.84583 11.0022L6.86146 9.96652Z" fill="#0E103A" />
                          </svg>
                        </Col>
                        <Col className='px-3 text-[#0E103A] text-[16px] font-extrabold'>
                          <FormattedMessage id={'filed'} />:
                          <span className='text-[#8C8C8C]'>
                            <br />
                            {record?.training_field}
                          </span>
                        </Col>

                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col span={6}>
                  <Row>
                    <Col className='text-[#0E103A] text-[16px] font-extrabold'>
                      <Row >
                        <Col className='pt-1' >
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M17.6786 8.92857H16.4286C16.3304 8.92857 16.25 9.00893 16.25 9.10714V16.25H1.60714V1.60714H8.75C8.84822 1.60714 8.92857 1.52679 8.92857 1.42857V0.178571C8.92857 0.0803571 8.84822 0 8.75 0H0.714286C0.319196 0 0 0.319196 0 0.714286V17.1429C0 17.5379 0.319196 17.8571 0.714286 17.8571H17.1429C17.5379 17.8571 17.8571 17.5379 17.8571 17.1429V9.10714C17.8571 9.00893 17.7768 8.92857 17.6786 8.92857Z" fill="#0E103A" />
                            <path d="M5.44405 9.43973L5.40164 12.0937C5.39941 12.2924 5.56012 12.4554 5.75878 12.4554H5.76771L8.40164 12.3906C8.44628 12.3884 8.49092 12.3705 8.52217 12.3393L17.8057 3.07589C17.8749 3.0067 17.8749 2.89286 17.8057 2.82366L15.0311 0.0513393C14.9954 0.0156251 14.9507 0 14.9039 0C14.857 0 14.8124 0.0178572 14.7766 0.0513393L5.49539 9.31473C5.46315 9.34845 5.44481 9.39309 5.44405 9.43973ZM6.86146 9.96652L14.9039 1.94196L15.9128 2.94866L7.86592 10.9777L6.84583 11.0022L6.86146 9.96652Z" fill="#0E103A" />
                          </svg>
                        </Col>
                        <Col className='px-3 text-[#0E103A] text-[16px] font-extrabold'>
                          <FormattedMessage id={'location'} />
                          <span className='text-[#8C8C8C]'>
                            <br />
                            {record?.country} ,{record?.city}
                          </span>
                        </Col>

                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col span={6}>
                  <Row>
                    <Col className='text-[#0E103A] text-[16px] font-extrabold'>
                      <Row >
                        <Col className='pt-1' >
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M17.6786 8.92857H16.4286C16.3304 8.92857 16.25 9.00893 16.25 9.10714V16.25H1.60714V1.60714H8.75C8.84822 1.60714 8.92857 1.52679 8.92857 1.42857V0.178571C8.92857 0.0803571 8.84822 0 8.75 0H0.714286C0.319196 0 0 0.319196 0 0.714286V17.1429C0 17.5379 0.319196 17.8571 0.714286 17.8571H17.1429C17.5379 17.8571 17.8571 17.5379 17.8571 17.1429V9.10714C17.8571 9.00893 17.7768 8.92857 17.6786 8.92857Z" fill="#0E103A" />
                            <path d="M5.44405 9.43973L5.40164 12.0937C5.39941 12.2924 5.56012 12.4554 5.75878 12.4554H5.76771L8.40164 12.3906C8.44628 12.3884 8.49092 12.3705 8.52217 12.3393L17.8057 3.07589C17.8749 3.0067 17.8749 2.89286 17.8057 2.82366L15.0311 0.0513393C14.9954 0.0156251 14.9507 0 14.9039 0C14.857 0 14.8124 0.0178572 14.7766 0.0513393L5.49539 9.31473C5.46315 9.34845 5.44481 9.39309 5.44405 9.43973ZM6.86146 9.96652L14.9039 1.94196L15.9128 2.94866L7.86592 10.9777L6.84583 11.0022L6.86146 9.96652Z" fill="#0E103A" />
                          </svg>
                        </Col>
                        <Col className='px-3 text-[#0E103A] text-[16px] font-extrabold'>
                          <FormattedMessage id={'company'} />
                          <span className='text-[#8C8C8C]'>
                            <br />
                            {record?.company}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>


              <Row className='bg-[#FAFAFA] p-3 mb-2' justify={"space-between"}>
                <Col span={6}>
                  <Row >
                    <Col className='text-[#0E103A] text-[16px] text-center font-extrabold'>
                      <Row >
                        <Col className='pt-1' >
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M17.6786 8.92857H16.4286C16.3304 8.92857 16.25 9.00893 16.25 9.10714V16.25H1.60714V1.60714H8.75C8.84822 1.60714 8.92857 1.52679 8.92857 1.42857V0.178571C8.92857 0.0803571 8.84822 0 8.75 0H0.714286C0.319196 0 0 0.319196 0 0.714286V17.1429C0 17.5379 0.319196 17.8571 0.714286 17.8571H17.1429C17.5379 17.8571 17.8571 17.5379 17.8571 17.1429V9.10714C17.8571 9.00893 17.7768 8.92857 17.6786 8.92857Z" fill="#0E103A" />
                            <path d="M5.44405 9.43973L5.40164 12.0937C5.39941 12.2924 5.56012 12.4554 5.75878 12.4554H5.76771L8.40164 12.3906C8.44628 12.3884 8.49092 12.3705 8.52217 12.3393L17.8057 3.07589C17.8749 3.0067 17.8749 2.89286 17.8057 2.82366L15.0311 0.0513393C14.9954 0.0156251 14.9507 0 14.9039 0C14.857 0 14.8124 0.0178572 14.7766 0.0513393L5.49539 9.31473C5.46315 9.34845 5.44481 9.39309 5.44405 9.43973ZM6.86146 9.96652L14.9039 1.94196L15.9128 2.94866L7.86592 10.9777L6.84583 11.0022L6.86146 9.96652Z" fill="#0E103A" />
                          </svg>
                        </Col>
                        <Col className='px-3 text-[#0E103A] text-[16px] text-center font-extrabold'>
                          <FormattedMessage id={'gender'} />:
                          <span className='text-[#8C8C8C]'>
                            <br />
                            {record?.gender}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col span={6}>
                  <Row>
                    <Col className='text-[#0E103A] text-[16px] font-extrabold'>
                      <Row >
                        <Col className='pt-1' >
                          <CalendarOutlined className='text-[20px]' />
                        </Col>
                        <Col className='px-3 text-[#0E103A] text-[16px] font-extrabold'>
                          <FormattedMessage id={'birth_date'} />:
                          <span className='text-[#8C8C8C]'>
                            <br />
                            {record?.birth_date}
                          </span>
                        </Col>

                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col span={6}>
                  <Row>
                    <Col className='text-[#0E103A] text-[16px] font-extrabold'>
                      <Row >
                        <Col className='pt-1' >
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M17.6786 8.92857H16.4286C16.3304 8.92857 16.25 9.00893 16.25 9.10714V16.25H1.60714V1.60714H8.75C8.84822 1.60714 8.92857 1.52679 8.92857 1.42857V0.178571C8.92857 0.0803571 8.84822 0 8.75 0H0.714286C0.319196 0 0 0.319196 0 0.714286V17.1429C0 17.5379 0.319196 17.8571 0.714286 17.8571H17.1429C17.5379 17.8571 17.8571 17.5379 17.8571 17.1429V9.10714C17.8571 9.00893 17.7768 8.92857 17.6786 8.92857Z" fill="#0E103A" />
                            <path d="M5.44405 9.43973L5.40164 12.0937C5.39941 12.2924 5.56012 12.4554 5.75878 12.4554H5.76771L8.40164 12.3906C8.44628 12.3884 8.49092 12.3705 8.52217 12.3393L17.8057 3.07589C17.8749 3.0067 17.8749 2.89286 17.8057 2.82366L15.0311 0.0513393C14.9954 0.0156251 14.9507 0 14.9039 0C14.857 0 14.8124 0.0178572 14.7766 0.0513393L5.49539 9.31473C5.46315 9.34845 5.44481 9.39309 5.44405 9.43973ZM6.86146 9.96652L14.9039 1.94196L15.9128 2.94866L7.86592 10.9777L6.84583 11.0022L6.86146 9.96652Z" fill="#0E103A" />
                          </svg>
                        </Col>
                        <Col className='px-3 text-[#0E103A] text-[16px] font-extrabold'>
                          <FormattedMessage id={'brief'} />:
                          <span className='text-[#8C8C8C]'>
                            <br />
                            {record?.brief}
                          </span>
                        </Col>

                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col span={6}>
                  <Row>
                    <Col className='text-[#0E103A] text-[16px] font-extrabold'>
                      <Row >
                        <Col className='pt-1' >
                          <LinkOutlined className='text-[20px]' />
                        </Col>
                        <Col className='px-3 text-[#0E103A] text-[16px] font-extrabold'>
                          <FormattedMessage id={'cv'} />:
                          <span className='text-[#8C8C8C]'>
                            <br />
                            <Link className='text-[#8C8C8C] text-center font-extrabold' to={record?.cv} target='_blank'> <FormattedMessage id={'cv'} /></Link>
                          </span>
                        </Col>

                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>

            </div>
          ,
          expandIcon: ({ expanded, onExpand, record }: { expanded: boolean; onExpand: () => void; record: any }) =>
            expanded ? (
              <span onClick={() => onExpand()}>
                <UpOutlined />
              </span>
            ) : (
              <span onClick={() => onExpand()}>
                <DownOutlined />
              </span>
            ),
          rowExpandable: (record: { id: string; }) => record.id !== 'Not Expandable',
        }}
      />

    </>
  );
};

export default AccreditationTrainerTable;