import ELearning from "../pages/eLearning/ELearning";
import ELearningData from "../pages/eLearning/ELearningData";

const routesELearning = [
  {
    path: "e-learning",
    element: <ELearning />,
  },
  {
    path: "e-learning/data",
    element: <ELearningData />,
  }
];

export default routesELearning;
