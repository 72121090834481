import React, { useState } from 'react';
import { Avatar, Card, Col, Empty, Flex, Pagination, Row } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../services/react-query/queryKeys';
import { decodeResponse, decryptData, decryptItem } from '../../helpers/apiUtils';
import { FetchData } from '../../network/fetchData';
import DeleteModal from '../common/DeleteModal';
import { deleteData } from '../../network/deleteData';
import FilterTable from '../common/filtersTables/FilterTable';
import AddELearning from './AddELearning';
import Meta from 'antd/es/card/Meta';
import { EditOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import UpdateELearning from './UpdateELearning';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import UploadFileModal from '../common/UploadFile';

function ListELearnings() {
  const [editELearningData, setEditELearningData] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState<React.Key | null>(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalAction, setModalAction] = useState<'add' | 'update' | "delete" | "upload" | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage: number = 12;
  const currentLang = useSelector((state: any) => state?.user?.currentLang);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const indexOfLastItem: number = currentPage * itemsPerPage;
  const indexOfFirstItem: number = indexOfLastItem - itemsPerPage;

  const truncateText = (text: string, maxLength: number) => {
    const words = text.split(' ');
    if (words.length <= maxLength) {
      return text;
    }
    const truncatedText = words.slice(0, maxLength).join(' ') + '...';
    return truncatedText;
  };

  const showModal = (action: 'add' | 'update' | 'delete' | 'upload', id?: React.Key, data?: any) => {
    setSelectedRowId(id !== undefined ? id : null);
    setEditELearningData(data);
    setModalAction(action);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const deleteValue = {
    mode: "e-learning",
    type: "delete",
    id: selectedRowId
  };

  const [searchTerm, setSearchTerm] = useState('');

  const values = {
    mode: "e-learning",
    type: "get",
    language_id: currentLang === "ar" ? 1 : currentLang === "en" ? 2 : null,
  };

  const { data: listELearnings } = useQuery(
    [queryKeys.listELearnings, values.language_id, searchTerm],
    () => FetchData({
      ...values,
      name: searchTerm,
    }),
    {
      keepPreviousData: true,
    }
  );

  const decodedResponse = decodeResponse(listELearnings);
  const decryptedData = decodedResponse?.data.map((item: any) => {
    const decryptedItem = decryptItem(item);
    const excludedFields = ["certificate"];

    const itemWithoutDecryption = Object.fromEntries(
      Object.entries(decryptedItem).map(([key, value]) => {
        return excludedFields.includes(key) ? [key, item[key]] : [key, value];
      })
    );

    return itemWithoutDecryption;
  });

  const currentItems: any[] = decryptedData?.slice(indexOfFirstItem, indexOfLastItem);

  const items = [
    {
      title: <FormattedMessage id='e_learnings' />,
    }
  ];

  return (
    <>
      <FilterTable
        buttonText={<FormattedMessage id='add_e_learning' />}
        tabName={<FormattedMessage id='e_learnings' />}
        onClick={() => showModal('add')}
        items={items}
        isUpload={false}
        onSearch={(value) => setSearchTerm(value)}
      />

      <UploadFileModal visible={isModalVisible && modalAction === 'upload'} onClose={closeModal} mode={"assessment_advice"} confirmationHeader={''} successMessage={''} queryKey={queryKeys.listAdvice} />

      <AddELearning
        visible={isModalVisible && modalAction === 'add'}
        onClose={closeModal}
      />
      <UpdateELearning
        visible={isModalVisible && modalAction === 'update'}
        onClose={closeModal}
        eLearningData={editELearningData}
      />

      <DeleteModal
        visible={isModalVisible && modalAction === 'delete'}
        onDelete={deleteData}
        data={deleteValue}
        confirmationHeader={<FormattedMessage id='e_learning' />}
        queryKey={queryKeys.listELearnings}
        onClose={closeModal}
      />

      {decryptedData && decryptedData?.length > 0 ? (
        <>
          <Row gutter={{ xs: 8, sm: 16, md: 8, lg: 14, xl: 10 }} justify={decryptedData?.length % 2 !== 0 ? "space-between" : "start"}>
            {currentItems.map((item: any) => (
              <Col className='mb-4'>
                <Card title={decryptData(item?.certificate?.name)} className='xl:w-[300px]'
                  actions={[
                    <Link to="/e-learning/data" className='text-[#ED1D23]' state={{ e_learning_data: item }}><EyeOutlined /></Link>,
                    <EditOutlined key="edit" onClick={() => showModal('update', item.id, item)} />,
                    <DeleteOutlined key="delete" style={{ color: "red" }} onClick={() => showModal('delete', item.id)} />,
                  ]}
                >
                  <Meta
                    description={<div>{truncateText(decodeURIComponent(item?.instruction), 15)}</div>}
                  />
                </Card>
              </Col>
            ))}
          </Row>
          <Pagination
            current={currentPage}
            total={decryptedData.length}
            pageSize={itemsPerPage}
            onChange={handlePageChange}
            className='mt-1'
          />
        </>
      ) : (
        <Empty description={<FormattedMessage id='no_data' />} className='mt-24' />
      )}
    </>
  );
}

export default ListELearnings;
