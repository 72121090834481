import { FC } from "react";

interface ELearningSVGProps {
    width?: number;
    height?: number;
}

const ELearningSVG: FC<ELearningSVGProps> = () => {
    return (
        <div className="anticon anticon-file-done ant-menu-item-icon">
            <svg xmlns="http://www.w3.org/2000/svg" focusable="false" data-icon="book" width="1em" height="1em" viewBox="0 0 20 18" fill="none">
                <path d="M10 0.5L0 4.67544V14.8924H1.25V6.51869L3.75 7.43625V12.9774L4.05313 13.1714C4.05313 13.1714 6.7625 14.8924 10 14.8924C13.2375 14.8924 15.9469 13.1714 15.9469 13.1714L16.25 12.9774V7.43625L20 6.06562V4.67544L10 0.5ZM10 1.90485L18.2219 5.33223L10 8.33261L1.77656 5.33223L10 1.90485ZM5 7.88932L10 9.71791L10.2047 9.64131L15 7.89421V12.1935C14.5891 12.4331 12.5391 13.5886 10 13.5886C7.46094 13.5886 5.40938 12.4331 5 12.1935V7.88932ZM0.625 16.1962C0.278125 16.1962 0 16.4863 0 16.8481C0 17.2099 0.278125 17.5 0.625 17.5C0.971875 17.5 1.25 17.2099 1.25 16.8481C1.25 16.4863 0.971875 16.1962 0.625 16.1962Z" fill="#0E103A" />
            </svg>
        </div>
    );
};

export default ELearningSVG;
