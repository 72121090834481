import React from "react";
import { useLocation } from "react-router-dom";
import CustomHeader from "../../components/common/CustomHeader";
import { FormattedMessage } from "react-intl";
import ListLevels from "../../components/certificate/level/ListLevels";

export default function CertificateData() {
  const location = useLocation();
  const data = location?.state.certificate_data;

  return (
    <>
      <CustomHeader title={<div>{data.name.toUpperCase()} <FormattedMessage id={'certificate'} /></div>} />
      <div className="customTab2 mt-8">
        <ListLevels certificateData={data} />
      </div>
    </>

  );
}

