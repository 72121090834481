import Certificate from "../pages/certificate/Certificate";
import CertificateData from "../pages/certificate/CertificateData";
import Chapters from "../pages/certificate/Chapter";

const routesCertificate = [
  {
    path: "certificate",
    element: <Certificate />,
  },
  {
    path: "certificate/data",
    element: <CertificateData />,
  },
  {
    path: "certificate/chapter",
    element: <Chapters />,
  }
];

export default routesCertificate;
