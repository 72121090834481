import AddAccreditationCenter from "../components/accreditation/center/AddAccreditationCenter";
import Accreditation from "../pages/accreditation/Accreditation";
import AccreditationData from "../pages/accreditation/AccreditationData";

const routesAccreditation = [
  {
    path: "accreditation",
    element: <Accreditation />,
  },
  {
    path: "accreditation/center/data",
    element: <AccreditationData />,
  },
  {
    path: "add/accreditation/center",
    element: <AddAccreditationCenter />,
  },
];

export default routesAccreditation;
