import { useSelector } from "react-redux";
import { Navigate, useRoutes } from "react-router-dom";
import routesAuthentication from "./routesAuthentication";
import routesAssessment from "./routesAssessment";
import routesExam from "./routesExam";
import routesCorporate from "./routesCorporate";
import routesAccreditation from "./routesAccreditation";
import routesELearning from "./routesELearning";
import OwnNotFound from "../components/common/OwnNotFound";
import routesUser from "./routesUser";
import OwnLayout from "../components/layout/OwnLayout";
import Overview from "../pages/overview/Overview";
import { getStoredToken } from "../services/user-storage";
import routesCertificate from "./routesCertificate";

// Constants for role names
const ROLES = {
  ELEARNING_ADMIN: 'elearning_admin',
  ACCREDITATION_ADMIN: 'accreditation_admin',
  EXAM_ADMIN: 'exam_admin',
  ASSESSMENT_ADMIN: 'assessment_admin',
  CORPORATE_ADMIN: 'corporate_admin',
  ADMIN: 'admin',
};

function RoutesWrapper() {
  const userRoles = useSelector((s) => s?.user?.data?.user?.roles.map(role => role.name)) || [];
  const storedToken = getStoredToken();
  const hasRole = (...roles) => roles.some(role => userRoles.includes(role));

  const getRoleBasedRedirect = () => {
    if (hasRole(ROLES.ADMIN)) return '/overview';

    const redirects = {
      [ROLES.ELEARNING_ADMIN]: '/e-learning',
      [ROLES.ACCREDITATION_ADMIN]: '/accreditation',
      [ROLES.EXAM_ADMIN]: '/exams',
      [ROLES.ASSESSMENT_ADMIN]: '/assessment',
      [ROLES.CORPORATE_ADMIN]: '/corporate',
    };

    for (const role of userRoles) {
      if (redirects[role]) return redirects[role];
    }
    return '/';
  };

  const routeAccess = [
    { roles: [ROLES.ADMIN, ROLES.ASSESSMENT_ADMIN], routes: routesAssessment },
    { roles: [ROLES.ADMIN, ROLES.ACCREDITATION_ADMIN], routes: routesAccreditation },
    { roles: [ROLES.ADMIN, ROLES.CORPORATE_ADMIN], routes: routesCorporate },
    { roles: [ROLES.ADMIN, ROLES.ELEARNING_ADMIN], routes: routesELearning },
    { roles: [ROLES.ADMIN, ROLES.ELEARNING_ADMIN], routes: routesCertificate },
    { roles: [ROLES.ADMIN, ROLES.EXAM_ADMIN], routes: routesExam },
    { roles: [ROLES.ADMIN], routes: routesUser },
  ];

  const accessibleRoutes = hasRole(ROLES.ADMIN) ? [
    { index: true, element: <Overview /> },
    ...routesAssessment,
    ...routesAccreditation,
    ...routesCorporate,
    ...routesELearning,
    ...routesExam,
    ...routesCertificate,
    ...routesUser
  ] : routeAccess.reduce((acc, { roles, routes }) => {
    if (hasRole(...roles)) acc = acc.concat(routes);
    return acc;
  }, []);

  const roleBasedRedirectPath = storedToken ? getRoleBasedRedirect() : '/login';

  const routes = useRoutes([
    { path: "*", element: <OwnNotFound /> },
    {
      path: "/",
      element: storedToken ? <OwnLayout /> : <Navigate to="/login" replace />,
      children: [
        { index: true, element: hasRole(ROLES.ADMIN) ? <Overview /> : <Navigate to={roleBasedRedirectPath} replace /> },
        ...accessibleRoutes,
      ],
    },
    ...routesAuthentication,
  ]);

  return routes;
}

export default RoutesWrapper;
