import React from "react";
import { useLocation } from "react-router-dom";
import CustomHeader from "../../components/common/CustomHeader";
import { FormattedMessage } from "react-intl";
import BasicInfo from "../../components/exam/examConfigration/BasicInfo";

export default function ExamConfigurationData() {
  const location = useLocation();
  const data = location?.state.exam_configuration_data;

  return (
    <div>
      <CustomHeader title={<FormattedMessage id={'exam'} />} />

      <BasicInfo exam_configuration_data={data} />
    </div>
  );
}

