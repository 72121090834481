import React, { useState } from 'react';
import { Card, Col, Empty, Flex, Row } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../../services/react-query/queryKeys';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { decodeResponse, decryptItem } from '../../../helpers/apiUtils';
import { FetchData } from '../../../network/fetchData';
import EnvelopeSVG from '../../../assets/teamMember/EnvelopeSVG';
import PhoneSVG from '../../../assets/teamMember/PhoneSVG';
import DeleteModal from '../../common/DeleteModal';
import { deleteData } from '../../../network/deleteData';
import FilterTable from '../../common/filtersTables/FilterTable';
import UpdateAccreditationCenter from './UpdateAccreditationCenter';
import AddAccreditationCenter from './AddAccreditationCenter';
import { FormattedMessage } from 'react-intl';
import UploadFileModal from '../../common/UploadFile';


function AccreditationCenter() {
  const [selectedRowId, setSelectedRowId] = useState<React.Key | null>(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalAction, setModalAction] = useState<'add' | 'update' | "delete" | "upload" | null>(null);
  const [editAccreditationCenterData, setEditAccreditationCenterData] = useState(null);

  const showModal = (action: 'add' | 'update' | 'delete' | 'upload', id?: React.Key, data?: any) => {
    setSelectedRowId(id !== undefined ? id : null);
    setEditAccreditationCenterData(data);
    setModalAction(action);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };
  const [searchTerm, setSearchTerm] = useState('');

  const deleteValue = {
    mode: "accreditation_center",
    type: "delete",
    id: selectedRowId
  };

  const values = {
    mode: "accreditation_center",
    type: "get"
  };

  const { data: listAccreditationCenter } = useQuery(
    [queryKeys.listAccreditationCenter, searchTerm],
    () => FetchData({
      ...values,
      name: searchTerm,
    }),
    {
      keepPreviousData: true,
    }
  );

  const decodedResponse = decodeResponse(listAccreditationCenter);
  const decryptedData = decodedResponse?.data.map((item: any) => {
    const decryptedItem = decryptItem(item);
    const excludedFields = ['country', 'city', 'quality_manual','tax_registration','commercial_registration', 'website', 'accreditation', 'license', 'contact_person', 'contact_title'];

    const itemWithoutDecryption = Object.fromEntries(
      Object.entries(decryptedItem).map(([key, value]) => {
        return excludedFields.includes(key) ? [key, item[key]] : [key, value];
      })
    );

    return itemWithoutDecryption;
  });

  const items = [
    {
      title: <FormattedMessage id='accreditation_centers' />,
    }
  ];

  return (
    <>
      <FilterTable
        onSearch={(value) => setSearchTerm(value)}
        buttonText={<FormattedMessage id={'add_accreditation_center'} />}
        items={items}
        onClick={() => showModal('add')}
        isUpload={false}
      />

      <UploadFileModal visible={isModalVisible && modalAction === 'upload'} onClose={closeModal} mode={"assessment_advice"} confirmationHeader={''} successMessage={''} queryKey={queryKeys.listAdvice} />
      <AddAccreditationCenter
        visible={isModalVisible && modalAction === 'add'}
        onClose={closeModal}
      />

      <UpdateAccreditationCenter
        visible={isModalVisible && modalAction === 'update'}
        onClose={closeModal}
        editAccreditationCenterData={editAccreditationCenterData}
      />

      <DeleteModal
        visible={isModalVisible && modalAction === 'delete'}
        onDelete={deleteData}
        data={deleteValue}
        confirmationHeader={<FormattedMessage id={'accreditation_centers'} />}
        queryKey={queryKeys.listAccreditationCenter}
        onClose={closeModal}
      />

      {decryptedData && decryptedData.length > 0 ? (
        <Row gutter={{ xs: 8, sm: 16, md: 8, lg: 14, xl: 10 }} justify={decryptedData?.length % 2 !== 0 ? "space-between" : "start"}>
          {decryptedData?.map((item: any) =>
            <Col className='mb-4'>
              <Card
                style={{ width: 327 }}
                className='rounded-sm'
                title={item?.name} extra={<Link to="/accreditation/center/data" className='text-[#ED1D23]' state={{ accreditation_center_data: item }}><FormattedMessage id={'more'} /></Link>}
                actions={[
                  <div><DeleteOutlined key="delete" onClick={() => showModal('delete', item.id)} /> </div>,
                  <div><EditOutlined key="edit" onClick={() => showModal('update', item.id, item)} /> </div>,
                ]}
              >
                <Flex >
                  <div className='mt-[6px]'>
                    <EnvelopeSVG />
                  </div>
                  <div className='text-lg text-black px-1'>
                    {item?.email}
                  </div>
                </Flex>
                <Flex >
                  <div className='mt-[6px]'>
                    <PhoneSVG />
                  </div>
                  <div className='text-lg text-black px-1'>
                    {item?.phone}
                  </div>
                </Flex>
              </Card>
            </Col>
          )}
        </Row>
      ) : (
        <Empty description={<FormattedMessage id='no_data' />} className='mt-24' />
      )}
    </>
  );
}

export default AccreditationCenter;
