import React, { useState } from 'react';
import { Card, Col, Empty, Flex, Row } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../services/react-query/queryKeys';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { decodeResponse, decryptItem } from '../../helpers/apiUtils';
import { FetchData } from '../../network/fetchData';
import EnvelopeSVG from '../../assets/teamMember/EnvelopeSVG';
import PhoneSVG from '../../assets/teamMember/PhoneSVG';
import DeleteModal from '../common/DeleteModal';
import { deleteData } from '../../network/deleteData';
import FilterTable from '../common/filtersTables/FilterTable';
import AddCorporate from './AddCorporate';
import UpdateCorporate from './UpdateCorporate';
import { FormattedMessage } from 'react-intl';
import UploadFileModal from '../common/UploadFile';


function CorporateTable() {
  const [editCorporateData, setEditCorporateData] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState<React.Key | null>(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalAction, setModalAction] = useState<'add' | 'update' | "delete" | "upload" | null>(null);

  const showModal = (action: 'add' | 'update' | 'delete' | 'upload', id?: React.Key, data?: any) => {
    setSelectedRowId(id !== undefined ? id : null);
    setEditCorporateData(data);
    setModalAction(action);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const deleteValue = {
    mode: "corporate",
    type: "delete",
    id: selectedRowId
  };


  const values = {
    mode: "corporate",
    type: "get"
  };
  const [searchTerm, setSearchTerm] = useState('');

  const { data: listCorporate } = useQuery(
    [queryKeys.listCorporate],
    () => FetchData(values)
  );

  const decodedResponse = decodeResponse(listCorporate);
  const decryptedData = decodedResponse?.data.map((item: any) => {
    const decryptedItem = decryptItem(item);
    const excludedFields = ['name', 'country', 'city', 'filed', 'corporate_type', 'contact_person', 'contact_title'];

    const itemWithoutDecryption = Object.fromEntries(
      Object.entries(decryptedItem).map(([key, value]) => {
        return excludedFields.includes(key) ? [key, item[key]] : [key, value];
      })
    );

    return itemWithoutDecryption;
  });

  const items = [
    {
      title: <FormattedMessage id='corporates' />,
    }
  ];

  return (
    <>
      <FilterTable
        buttonText={<FormattedMessage id='add_corporate' />}
        items={items}
        onClick={() => showModal('add')}
        onClickUpload={() => showModal('upload')}
        onSearch={(value) => setSearchTerm(value)}
      />

      <UploadFileModal visible={isModalVisible && modalAction === 'upload'} onClose={closeModal} mode={"assessment_advice"} confirmationHeader={''} successMessage={''} queryKey={queryKeys.listAdvice} />

      <DeleteModal
        visible={isModalVisible && modalAction === 'delete'}
        onDelete={deleteData}
        data={deleteValue}
        confirmationHeader={<FormattedMessage id={'corporate'} />}
        queryKey={queryKeys.listCorporate}
        onClose={closeModal}
      />

      <AddCorporate
        visible={isModalVisible && modalAction === 'add'}
        onClose={closeModal}
      />

      <UpdateCorporate
        visible={isModalVisible && modalAction === 'update'}
        onClose={closeModal}
        corporateData={editCorporateData}
      />

      {decryptedData && decryptedData.length > 0 ? (
        <Row gutter={{ xs: 8, sm: 16, md: 8, lg: 14, xl: 10 }} justify={decryptedData?.length % 2 !== 0 ? "space-between" : "start"}>
          {decryptedData?.map((item: any) =>
            <Col className='mb-4'>
              <Card
                className='rounded-sm'
                style={{ width: 327 }}
                title={item?.name} extra={<Link to="/corporate/data" className='text-[#ED1D23]' state={{ corporate_data: item }}><FormattedMessage id={'more'} /></Link>}
                actions={[
                  <div><DeleteOutlined key="delete" onClick={() => showModal('delete', item.id)} /></div>,
                  <div><EditOutlined key="edit" onClick={() => showModal('update', item.id, item)} /> </div>,
                ]}
              >
                <Flex >
                  <div className='mt-[6px]'>
                    <EnvelopeSVG />
                  </div>
                  <div className='text-lg text-black px-1'>
                    {item?.email}
                  </div>
                </Flex>
                <Flex >
                  <div className='mt-[6px]'>
                    <PhoneSVG />
                  </div>
                  <div className='text-lg text-black px-1'>
                    {item?.phone}
                  </div>
                </Flex>
              </Card>
            </Col>
          )}
        </Row>

      ) : (
        <Empty description={<FormattedMessage id='no_data' />} className='mt-24' />
      )}


    </>
  );
}

export default CorporateTable;
