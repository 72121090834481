const Component = ({ children, style }: any) => {
  return (
    <div
      className={`w-full p-4 bg-white shadow rounded-lg flex flex-col gap-4 mt-2 ${style}`}
    >
      {children}
    </div>
  );
};

export default Component;
