import {
  Tabs,
  TabsProps,
} from "antd";
import React from "react";
import { useLocation } from "react-router-dom";
import CustomHeader from "../../components/common/CustomHeader";
import { FormattedMessage } from "react-intl";
import Chapters from "../../components/eLearning/chapter/Chapters";
import Info from "../../components/eLearning/Info";

export default function ELearningData() {
  const location = useLocation();
  const data = location?.state.e_learning_data;

  const itemsTab: TabsProps['items'] = [
    {
      key: '1',
      label: <FormattedMessage id={'details'} />,
      children: <Info eLearningData={data} />
    },
    {
      key: '2',
      label: <FormattedMessage id={'chapters'} />,
      children: <Chapters eLearningData={data} />
    }
  ];

  return (
    <>
      <CustomHeader title={<FormattedMessage id={'e_learning'} />}/>

      <div className="customTab2 mt-8">
        <Tabs
          defaultActiveKey="1"
          items={itemsTab}
          className="customTab mt-8"
          tabBarStyle={{ margin: 0 }}
        />
      </div>
    </>

  );
}

