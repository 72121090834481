// Define an enum with numeric values
enum questionDifficultyEnum {
  Easy = "easy",
  Intermediate = "intermediate",
  Hard = "hard",
}

// Create a constant array to define the questionDifficultyType
const questionDifficulty: { value: string; label: string }[] = [
  { value: questionDifficultyEnum.Easy, label: 'Easy' },
  { value: questionDifficultyEnum.Intermediate, label: 'Intermediate' },
  { value: questionDifficultyEnum.Hard, label: 'Hard' },
];

export { questionDifficultyEnum, questionDifficulty };
