import { Button, Flex, Form, Input, InputRef, Modal, Space, Table, message } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../../../services/react-query/queryKeys';
import { StoreData } from '../../../../network/storeDate';
import queryClient from '../../../../services/react-query/queryClient';
import { FormattedMessage } from 'react-intl';
import { decodeResponse, decryptItem } from '../../../../helpers/apiUtils';
import { FetchData } from '../../../../network/fetchData';
import { useRef, useState } from 'react';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { SearchOutlined } from '@ant-design/icons';
import type { TableColumnType } from 'antd';
import Highlighter from "react-highlight-words";

interface AddModalProps<T> {
  visible: boolean;
  onClose: () => void;
  exam_scheduling_data: any;
}

interface DataType {
  key: React.Key;
  name: string;
  email: string;
}
type DataIndex = keyof DataType;

const AddExaminee: React.FC<AddModalProps<any>> = ({
  exam_scheduling_data,
  visible,
  onClose,
}) => {
  const [form] = Form.useForm();
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps['confirm'],
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const onCancel = () => {
    form.resetFields();
    setSelectedUserIds([]);
    onClose();
  };

  const { mutate: addMutation, isLoading } = useMutation(StoreData, {
    onSuccess: () => {
      setSelectedUserIds([]);
      queryClient.invalidateQueries([queryKeys.listExams]);
      message.success("Submit success!");
      form.resetFields();
      onClose();
    },
    onError: (error) => {
      message.error("Error Adding");
    },
  });

  const onFinish = async () => {
    const transformedValues = {
      users: selectedUserIds.map((id) => ({ user_id: id })),
    };

    const formData = {
      mode: "examinee",
      type: "store",
      exam_schedule_id: exam_scheduling_data.id,
      ...transformedValues,
    };

    addMutation(formData);
  };

  const values = {
    mode: "auth",
    type: "all_user",
  };

  const { data: listUser } = useQuery(
    [queryKeys.listUser],
    () => FetchData(values)
  );

  const decodedResponse = decodeResponse(listUser);
  const decryptedData = decodedResponse?.data?.map((item: any) => {
    const decryptedItem = decryptItem(item);
    const excludedFields = ['role'];

    const itemWithoutDecryption = Object.fromEntries(
      Object.entries(decryptedItem).map(([key, value]) => {
        return excludedFields.includes(key) ? [key, item[key]] : [key, value];
      })
    );

    return itemWithoutDecryption;
  });

  const usersWithRoleUser = decryptedData?.filter((item: any) => item.role === 'user');
  const columns = [
    {
      title: <FormattedMessage id="name" />,
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
    },
    {
      title: <FormattedMessage id="email" />,
      dataIndex: 'email',
      key: 'email',
      ...getColumnSearchProps('email'),
    },
    {
      title: <FormattedMessage id="user_id" />,
      dataIndex: 'id',
      key: 'id',
    },
  ];
  const data = usersWithRoleUser?.map((user: {
    name: any; id: any; email: any;
  }) => ({
    key: user.id,
    name: user.name,
    email: user.email,
    id: user.id,
  }));

  const rowSelection = {
    onChange: (selectedRowKeys: any) => {
      setSelectedUserIds(selectedRowKeys);
    },
  };

  return (
    <Modal
      centered
      open={visible}
      className="customModal"
      onOk={onFinish}
      onCancel={onCancel}
      footer={[]}
      title={<FormattedMessage id='add_examinees' />}
      width={1050}
    >
      <Form
        onFinish={onFinish}
        name="complex-form"
        layout="vertical"
        form={form}
        encType="multipart/form-data"
        className="form-style mt-8 px-9 pb-5"
      >

        <Table rowSelection={rowSelection} columns={columns} dataSource={data} />

        <Flex gap="middle" align="center" justify="flex-end">
          <Button
            type="text"
            onClick={() => onCancel()}
            loading={isLoading}
            className="bg-[#1d1d39] h-full text-white text-lg rounded-md px-2 w-[112px]"
            htmlType="button"
          >
            <FormattedMessage id={'cancel'} />
          </Button>
          <Button
            type="text"
            loading={isLoading}
            className="bg-[#cf2110] h-full text-white text-lg rounded-md px-2 w-[112px]"
            htmlType="submit"
          >
            <FormattedMessage id={'save'} />
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
}

export default AddExaminee;

