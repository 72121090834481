import { useEffect, useState } from 'react';
import { Button, Col, Flex, Form, Input, Modal, Row, Select, Upload, message } from 'antd';
import { useMutation } from '@tanstack/react-query';
import PhoneInputWithCountrySelect from 'react-phone-number-input';
import { FormattedMessage } from 'react-intl';
import { queryKeys } from '../../../services/react-query/queryKeys';
import queryClient from '../../../services/react-query/queryClient';
import { editFormData } from '../../../network/editData';
import { UploadOutlined } from '@ant-design/icons';

interface UpdateModalProps<T> {
    visible: boolean;
    editAccreditationCenterData: any;
    onClose: () => void;
}

const UpdateAccreditationCenter: React.FC<UpdateModalProps<any>> = ({
    visible,
    onClose,
    editAccreditationCenterData,
}) => {
    const { Option } = Select;
    const [phoneNumber, setPhoneNumber] = useState("");
    const [fileStates, setFileStates] = useState<{
        accreditation?: File;
        commercial_registration?: File;
        license?: File;
        quality_manual?: File;
        tax_registration?: File;
    }>({});



    const handleFileUpload = (type: keyof typeof fileStates) => (info: { file: any; fileList: any }) => {
        const latestFile = info.fileList.slice(-1)[0]?.originFileObj;
        if (latestFile) {
            setFileStates((prevState) => ({
                ...prevState,
                [type]: latestFile,
            }));
        }
    };

    const { mutate: onUpdateAccreditationCenter, isLoading } = useMutation(editFormData, {
        onSuccess: (res) => {
            message.success("Submit success!");
            queryClient.invalidateQueries([queryKeys.listAccreditationCenter]);
            onCancel();
        },
        onError: (error) => {
            message.error("حاول مرة اخرى");
        },
    });


    const onFinish = async (values: any) => {
        let form_data = new FormData();

        // Append values to the FormData object
        form_data.append('mode', "accreditation_center");
        form_data.append('type', "update");
        form_data.append('id', editAccreditationCenterData.id);
        form_data.append('name', values?.name);
        form_data.append('email', values?.email);
        form_data.append('country', values?.country);
        form_data.append('city', values?.city);
        form_data.append('phone', values?.phone);
        form_data.append('contact_person', values?.contact_person);
        form_data.append('contact_phone', values?.contact_phone);
        form_data.append('contact_email', values?.contact_email);
        form_data.append('contact_title', values?.contact_title);
        form_data.append('website', values?.website);
        form_data.append('approve', values?.approve);

        // Append selected files to form_data
        // if (fileStates) {
        //     Object.entries(fileStates).forEach(([key, file]) => {
        //         if (file) form_data.append(key, file);
        //     });
        // }


        onUpdateAccreditationCenter(form_data);
    };

    const handlePhoneNumberChange = (value: string) => {
        setPhoneNumber(value);
    };

    const [form] = Form.useForm();

    const onCancel = () => {
        form.resetFields();
        onClose();
    };

    const initialValues = {
        name: editAccreditationCenterData?.name,
        email: editAccreditationCenterData?.email,
        phone: editAccreditationCenterData?.phone,
        country: editAccreditationCenterData?.country,
        city: editAccreditationCenterData?.city,
        contact_person: editAccreditationCenterData?.contact_person,
        contact_phone: editAccreditationCenterData?.contact_phone,
        website: editAccreditationCenterData?.website,
        contact_email: editAccreditationCenterData?.contact_email,
        contact_title: editAccreditationCenterData?.contact_title,
        quality_manual: editAccreditationCenterData?.quality_manual,
    }

    useEffect(() => {
        if (visible) {
            form.setFieldsValue(initialValues);
            // const initialFileStates = {
            //     accreditation: editAccreditationCenterData?.accreditation,
            //     commercial_registration: editAccreditationCenterData?.commercial_registration,
            //     quality_manual: editAccreditationCenterData?.quality_manual,
            //     license: editAccreditationCenterData?.license,
            //     tax_registration: editAccreditationCenterData?.tax_registration,
            // };

            // setFileStates(initialFileStates);
        }
    }, [visible, initialValues, form, editAccreditationCenterData]);


    const selectBefore = (
        <Select defaultValue="http://">
            <Option value="http://">http://</Option>
            <Option value="https://">https://</Option>
        </Select>
    );

    const selectAfter = (
        <Select defaultValue=".com">
            <Option value=".com">.com</Option>
            <Option value=".jp">.jp</Option>
            <Option value=".cn">.cn</Option>
            <Option value=".org">.org</Option>
        </Select>
    );
    return (
        <Modal
            centered
            open={visible}
            className="customModal"
            onOk={onFinish}
            onCancel={onCancel}
            footer={[]}
            title={<FormattedMessage id={'edit_accreditation_center'} />}
            width={1050}
        >

            <Form
                onFinish={onFinish}
                name="complex-form"
                initialValues={initialValues}
                layout="vertical"
                className="form-style mt-8 px-9 pb-5"
            >
                <Row className='w-[160px] bg-[#FAFAFA] mb-4 py-2 pl-2'>
                    <Col >
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M17.6786 8.92857H16.4286C16.3304 8.92857 16.25 9.00893 16.25 9.10714V16.25H1.60714V1.60714H8.75C8.84822 1.60714 8.92857 1.52679 8.92857 1.42857V0.178571C8.92857 0.0803571 8.84822 0 8.75 0H0.714286C0.319196 0 0 0.319196 0 0.714286V17.1429C0 17.5379 0.319196 17.8571 0.714286 17.8571H17.1429C17.5379 17.8571 17.8571 17.5379 17.8571 17.1429V9.10714C17.8571 9.00893 17.7768 8.92857 17.6786 8.92857Z" fill="#0E103A" />
                            <path d="M5.44405 9.43973L5.40164 12.0937C5.39941 12.2924 5.56012 12.4554 5.75878 12.4554H5.76771L8.40164 12.3906C8.44628 12.3884 8.49092 12.3705 8.52217 12.3393L17.8057 3.07589C17.8749 3.0067 17.8749 2.89286 17.8057 2.82366L15.0311 0.0513393C14.9954 0.0156251 14.9507 0 14.9039 0C14.857 0 14.8124 0.0178572 14.7766 0.0513393L5.49539 9.31473C5.46315 9.34845 5.44481 9.39309 5.44405 9.43973ZM6.86146 9.96652L14.9039 1.94196L15.9128 2.94866L7.86592 10.9777L6.84583 11.0022L6.86146 9.96652Z" fill="#0E103A" />
                        </svg>
                    </Col>
                    <Col className='px-3'>
                        <FormattedMessage id={'basic_information'} />
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 14 }}>
                    <Col xl={6}>
                        <Form.Item
                            name="name"
                            label={<FormattedMessage id={'name'} />}
                            required={false}
                            className="form-style w-full"
                            rules={[
                                { required: true, message: 'Name is required' },
                            ]}
                        >
                            <Input placeholder="Enter Name" />
                        </Form.Item>
                    </Col>
                    <Col xl={6}>
                        <Form.Item
                            name="email"
                            label={<FormattedMessage id={'email'} />}
                            required={false}
                            className="form-style w-full"
                            rules={[
                                { required: true, message: 'Email is required' },
                            ]}
                        >
                            <Input placeholder="Enter Email " />
                        </Form.Item>
                    </Col>
                    <Col xl={6}>
                        <Form.Item
                            name="phone"
                            label={<FormattedMessage id={'phone'} />}
                            className="form-style w-full"
                            required={false}
                        >
                            <PhoneInputWithCountrySelect
                                defaultCountry="SA"
                                name='phone_country_code'
                                international
                                onChange={() => { }}
                            />
                        </Form.Item>

                    </Col>
                    <Col span={6} >
                        <Form.Item
                            name="website"
                            label={<FormattedMessage id={'website'} />}
                            required={false}
                            className="form-style w-full"
                            rules={[
                                { required: true, message: 'website is required' },
                            ]}
                        >
                            <Input className='customInput'
                                addonBefore={selectBefore} addonAfter={selectAfter} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 14 }}>

                    <Col span={12}>
                        <Form.Item
                            name="country"
                            label={<FormattedMessage id={'country'} />}
                            required={false}
                            className="form-style w-full"
                            rules={[
                                { required: true, message: 'country is required' },
                            ]}
                        >
                            <Input placeholder="Enter Country" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="city"
                            label={<FormattedMessage id={'city'} />}
                            required={false}
                            className="form-style w-full"
                            rules={[
                                { required: true, message: 'City is required' },
                            ]}
                        >
                            <Input placeholder="Enter city " />
                        </Form.Item>
                    </Col>

                </Row>

                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 60 }}>
                    <Col >
                        <Form.Item
                            label={<FormattedMessage id={'quality_manual'} />}
                            name="quality_manual"
                            required={false}
                            className="form-style w-full"
                            rules={[
                                { required: false, message: 'quality_manual is required' },
                            ]}
                        >
                            <Upload onChange={handleFileUpload('quality_manual')}>
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload>
                        </Form.Item>
                    </Col>

                    <Col >
                        <Form.Item
                            label={<FormattedMessage id={'accreditation'} />}
                            name="accreditation"
                            required={false}
                            className="form-style w-full"
                            rules={[
                                { required: false, message: 'accreditation is required' },
                            ]}
                        >
                            <Upload onChange={handleFileUpload('accreditation')}>
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload>
                        </Form.Item>
                    </Col>


                    <Col >
                        <Form.Item
                            label={<FormattedMessage id={'tax_registration'} />}

                            name="tax_registration"
                            required={false}
                            className="form-style w-full"
                            rules={[
                                { required: false, message: 'tax_registration is required' },
                            ]}
                        >
                            <Upload onChange={handleFileUpload('tax_registration')}>
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload>
                        </Form.Item>
                    </Col>


                    <Col >
                        <Form.Item
                            label={<FormattedMessage id={'license'} />}
                            name="license"
                            required={false}
                            className="form-style w-full"
                            rules={[
                                { required: false, message: 'license is required' },
                            ]}
                        >
                            <Upload onChange={handleFileUpload('license')}>
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload>

                        </Form.Item>
                    </Col>
                    <Col >
                        <Form.Item
                            label={<FormattedMessage id={'commercial_registration'} />}
                            name="commercial_registration"
                            required={false}
                            className="form-style w-full"
                            rules={[
                                { required: false, message: 'commercial_registration is required' },
                            ]}
                        >
                            <Upload onChange={handleFileUpload('commercial_registration')}>
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                </Row>

                <Row className='w-[160px] bg-[#FAFAFA] mb-4 py-2 pl-2'>
                    <Col >
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M17.6786 8.92857H16.4286C16.3304 8.92857 16.25 9.00893 16.25 9.10714V16.25H1.60714V1.60714H8.75C8.84822 1.60714 8.92857 1.52679 8.92857 1.42857V0.178571C8.92857 0.0803571 8.84822 0 8.75 0H0.714286C0.319196 0 0 0.319196 0 0.714286V17.1429C0 17.5379 0.319196 17.8571 0.714286 17.8571H17.1429C17.5379 17.8571 17.8571 17.5379 17.8571 17.1429V9.10714C17.8571 9.00893 17.7768 8.92857 17.6786 8.92857Z" fill="#0E103A" />
                            <path d="M5.44405 9.43973L5.40164 12.0937C5.39941 12.2924 5.56012 12.4554 5.75878 12.4554H5.76771L8.40164 12.3906C8.44628 12.3884 8.49092 12.3705 8.52217 12.3393L17.8057 3.07589C17.8749 3.0067 17.8749 2.89286 17.8057 2.82366L15.0311 0.0513393C14.9954 0.0156251 14.9507 0 14.9039 0C14.857 0 14.8124 0.0178572 14.7766 0.0513393L5.49539 9.31473C5.46315 9.34845 5.44481 9.39309 5.44405 9.43973ZM6.86146 9.96652L14.9039 1.94196L15.9128 2.94866L7.86592 10.9777L6.84583 11.0022L6.86146 9.96652Z" fill="#0E103A" />
                        </svg>
                    </Col>
                    <Col className='px-3'>
                        <FormattedMessage id={'contact_info'} />:
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 14 }}>
                    <Col xl={6}>
                        <Form.Item
                            name="contact_person"
                            label={<FormattedMessage id={'contact_person'} />}
                            required={false}
                            className="form-style w-full"
                            rules={[
                                { required: true, message: 'Contact Person is required' },
                            ]}
                        >
                            <Input placeholder="Enter Contact Person" />
                        </Form.Item>
                    </Col>
                    <Col xl={6}>
                        <Form.Item
                            name="contact_email"
                            label={<FormattedMessage id={'contact_email'} />}
                            required={false}
                            className="form-style w-full"
                            rules={[
                                { required: true, message: 'Contact email is required' },
                            ]}
                        >
                            <Input placeholder="Enter Contact Email " />
                        </Form.Item>
                    </Col>
                    <Col xl={6}>
                        <Form.Item
                            name="contact_title"
                            label={<FormattedMessage id={'contact_title'} />}
                            required={false}
                            className="form-style w-full"
                            rules={[
                                { required: true, message: 'contact_title is required' },
                            ]}
                        >
                            <Input placeholder="Enter Contact Title " />
                        </Form.Item>
                    </Col>
                    <Col xl={6}>
                        <Form.Item
                            name="contact_phone"
                            label={<FormattedMessage id={'contact_phone'} />}
                            className="form-style w-full"
                            required={false}
                        >
                            <PhoneInputWithCountrySelect
                                defaultCountry="SA"
                                name='phone_country_code'
                                international
                                onChange={() => { }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Flex gap="middle" align="center" justify="flex-end">
                    <Button
                        type="text"
                        onClick={() => onCancel()}
                        loading={isLoading}
                        className="bg-[#1d1d39] h-full text-white text-lg rounded-md px-2 w-[112px]"
                        htmlType="button"
                    >
                        <FormattedMessage id={'cancel'} />
                    </Button>
                    <Button
                        type="text"
                        loading={isLoading}
                        className="bg-[#cf2110] h-full text-white text-lg rounded-md px-2 w-[112px]"
                        htmlType="submit"
                    >
                        <FormattedMessage id={'save'} />
                    </Button>
                </Flex>
            </Form>
        </Modal>

    );
}

export default UpdateAccreditationCenter;

