import {
  Tabs,
  TabsProps,
} from "antd";
import React from "react";
import { useLocation } from "react-router-dom";
import CustomHeader from "../../components/common/CustomHeader";
import { FormattedMessage } from "react-intl";
import Examinee from "../../components/exam/examScheduling/examinee/Examinee";
import BasicInfo from "../../components/exam/examScheduling/BasicInfo";

export default function ExamSchedulingData() {
  const location = useLocation();
  const data = location?.state.exam_configuration;

  const itemsTab: TabsProps['items'] = [
    {
      key: '1',
      label: <FormattedMessage id={'basic_info'} />,
      children: <BasicInfo exam_scheduling_data={data} />
    },
    {
      key: '2',
      label: <FormattedMessage id={'examinees'} />,
      children: <Examinee exam_scheduling_data={data} />
    }
  ];

  return (
    <>
      <CustomHeader title={
        <div>
         <FormattedMessage id={'exam'} />
         </div>
        } />

      <div className="customTab2 mt-8">
        <Tabs
          defaultActiveKey="1"
          items={itemsTab}
          className="customTab mt-8"
          tabBarStyle={{ margin: 0 }}
        />
      </div>
    </>

  );
}

