import React from 'react';
import { Card, Row, Col, Spin, Alert } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { decodeResponse, decryptData } from '../../../../helpers/apiUtils';
import { FetchData } from '../../../../network/fetchData';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../../../services/react-query/queryKeys';

interface BasicInfoProps {
  examinee_data: any;
}

const QuestionTracking: React.FC<BasicInfoProps> = ({ examinee_data }) => {
  const { user, exam_schedule_id } = examinee_data || {};

  const values = {
    mode: 'examinee',
    type: 'list_running_questions',
    user_id: user?.id,
    exam_schedule_id,
  };

  const { data, error, isLoading } = useQuery(
    [queryKeys.listRunningQuestions, values.user_id, values.exam_schedule_id],
    () => FetchData(values)
  );

  if (isLoading) return <Spin tip="Loading..." />;
  if (error) return <Alert message="Error" description="Failed to load data." type="error" showIcon />;

  const decodedResponse = decodeResponse(data);
  const decryptedData = decodedResponse?.data.map((item: any) => ({
    ...item,
    questions_bank: {
      ...item.questions_bank,
      question: decryptData(item.questions_bank.question),
      answers: item.questions_bank.answers.map((answer: any) => ({
        ...answer,
        answer_text: decryptData(answer.answer_text),
      })),
    },
  }));

  const answeredQuestions = decryptedData.filter((q: any) => q.is_answered);
  const remainingQuestions = decryptedData.filter((q: any) => !q.is_answered && q.is_saved);
  const unsavedQuestions = decryptedData.filter((q: any) => !q.is_saved);
  const currentQuestion = decryptedData.find((q: any) => q.is_current);

  return (
    <div className="mt-5">
      <h1 className="text-lg font-bold">Question: {currentQuestion || 'N/A'}/100</h1>

      <div className="p-5 mb-4 rounded-lg shadow-sm bg-[#FAFAFA]">
        <div className="grid grid-cols-12 gap-1 mt-3">
          {answeredQuestions.map((q: any, index: number) => (
            <div key={index + 1} className="p-2 text-center bg-green-500 rounded text-white">{index}</div>
          ))}
          {unsavedQuestions.map((q: any, index: number) => (
            <div key={index + 1} className="p-2 text-center bg-white rounded text-black">{index}</div>
          ))}
          {remainingQuestions.map((q: any, index: number) => (
            <div key={index + 1} className="p-2 text-center bg-red-500 rounded text-white">{index}</div>
          ))}
        </div>
      </div>

      {decryptedData.map((question: any, index: number) => (
        <div key={index}>
          <div className="bg-[#ECECEC] p-5 mb-4 rounded-lg shadow-sm">
            <div className="mb-4">
              <span className="font-bold text-lg">{index + 1}. </span>
              <span>{question.questions_bank.question}</span>
            </div>
          </div>
          <div className="bg-[#ECECEC] p-5 mb-4 rounded-lg shadow-sm">
            <Row gutter={[16, 16]}>
              {question.questions_bank.answers.map((answer: any, idx: number) => (
                <Col span={24} key={idx}>
                  <Card className={`w-full ${answer.is_correct ? 'bg-green-100' : ''}`}>
                    <div className="flex items-center">
                      {answer.is_correct ? (
                        <CheckCircleOutlined className="text-green-500 mr-2" />
                      ) : (
                        <CloseCircleOutlined className="text-red-500 mr-2" />
                      )}
                      {decryptData(answer.answer)}
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </div>

      ))}

    </div>
  );
};

export default QuestionTracking;
