import { useEffect } from 'react';
import { Button, Col, DatePicker, Flex, Form, Modal, Row, Select, message } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../../services/react-query/queryKeys';
import queryClient from '../../../services/react-query/queryClient';
import { useLocation, useNavigate } from "react-router-dom";
import { FetchData } from '../../../network/fetchData';
import { decodeResponse, decryptItem } from '../../../helpers/apiUtils';
import { FormattedMessage } from 'react-intl';
import { editData } from '../../../network/editData';
import dayjs from 'dayjs';

interface AddModalProps<T> {
  visible: boolean;
  onClose: () => void;
  examData: any;
}

const EditExamScheduling: React.FC<AddModalProps<any>> = ({ visible, onClose, examData }) => {
  console.log("examData", examData);
  const [form] = Form.useForm();
  const { mutate: onUpdateExam, isLoading } = useMutation(editData, {
    onSuccess: (res) => {
      message.success("Submit success!");
      queryClient.invalidateQueries([queryKeys.listExams]);
    },
    onError: (error) => {
      message.error("حاول مرة اخرى");
    },
  });


  const onFinish = async (values: any) => {
    let form_data = new FormData();
    const formattedScheduledAt = values.scheduled_at ? values.scheduled_at.format('YYYY-MM-DD HH:mm:ss') : null;

    form_data.append('mode', "exam_schedule");
    form_data.append('type', "update");
    form_data.append('scheduled_at', formattedScheduledAt);
    form_data.append('exam_configuration_id', values?.exam_configuration_id);

    onUpdateExam(form_data);
  };

  const values = {
    mode: "exam_configuration",
    type: "get",
  };

  const { data: listExams } = useQuery(
    [queryKeys.listExams],
    () => FetchData({
      ...values,
    }),
    {
      keepPreviousData: true,
    }
  );

  const decodedResponse = decodeResponse(listExams);
  const decryptedData = decodedResponse?.data.map((item: any) => {
    const decryptedItem = decryptItem(item);
    return decryptedItem;
  });


  const initialValues = {
    exam_configuration_id: examData?.exam_configuration_id,
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  const onCancel = () => {
    form.resetFields();
    onClose();
  };
  return (
    <Modal
      centered
      open={visible}
      className="customModal"
      onOk={onFinish}
      onCancel={onCancel}
      footer={[]}
      title={<FormattedMessage id='add_exam_scheduling' />}
      width={1050}
    >

      <Form
        onFinish={onFinish}
        name="complex-form"
        layout="vertical"
        form={form}
        encType="multipart/form-data"
        className="form-style mt-8 px-9 pb-5"
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 14 }}>
          <Col xl={12}>
            <Form.Item
              label={<FormattedMessage id={'scheduled_at'} />}
              required={false}
              name="scheduled_at"
              rules={[{ required: true, message: 'Missing scheduled_at' }]}
            >
              <DatePicker
                format="YYYY-MM-DD HH:mm:ss"
                showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}
              />
            </Form.Item>
          </Col>
          <Col xl={12}>
            <Form.Item
              label={<FormattedMessage id={'exam_configuration'} />}
              required={false}
              name="exam_configuration_id"
              rules={[{ required: true, message: 'Missing Exam Configuration' }]}
            >
              <Select
                placeholder="Choces Chapter"
                options={decryptedData?.map((item: { id: any; exam_title: any; }) => ({
                  value: item?.id,
                  label: item?.exam_title,
                }))}
              />
            </Form.Item>
          </Col>

        </Row>


        <Flex gap="middle" align="center" justify="flex-end">
          <Button
            type="text"
            onClick={() => onCancel()}
            loading={isLoading}
            className="bg-[#1d1d39] h-full text-white text-lg rounded-md px-2 w-[112px]"
            htmlType="button"
          >
            <FormattedMessage id={'cancel'} />
          </Button>
          <Button
            type="text"
            loading={isLoading}
            className="bg-[#cf2110] h-full text-white text-lg rounded-md px-2 w-[112px]"
            htmlType="submit"
          >
            <FormattedMessage id={'save'} />
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
}

export default EditExamScheduling;