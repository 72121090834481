import React, { useState } from 'react';
import { Row, Col, Card, Empty, Pagination } from 'antd';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl'; // Assuming you are using react-intl for internationalization
import { decryptData } from '../../helpers/apiUtils';

interface Props {
  decryptedData: any;
  actions: any;
  gutterNumber: any;
  spanNumber: any;
}

const PaginationComponent: React.FC<Props> = ({ decryptedData, actions, gutterNumber, spanNumber }) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage: number = 12;

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const indexOfLastItem: number = currentPage * itemsPerPage;
  const indexOfFirstItem: number = indexOfLastItem - itemsPerPage;
  const currentItems: any[] = decryptedData?.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      {decryptedData && decryptedData.length > 0 ? (
        <>
          <Row gutter={gutterNumber}>
            {currentItems.map((item: any) => (
              <Col key={item.id} className='mb-3' xl={spanNumber} sm={24} md={12}>
                <Card style={{ width: "100%" }} className='rounded-sm' >
                  <Row justify={"space-between"} gutter={{ xs: 8, sm: 16, md: 24, lg: 24, xl: 12 }}>
                    <Col>{item?.name} {item?.advice} {decryptData(item?.chapter?.name)}</Col>
                    <Col>
                      <Row justify={"space-between"} gutter={{ xs: 8, sm: 16, md: 24, lg: 14 }}>
                        {actions(item)}
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))}
          </Row>
          <Pagination
            current={currentPage}
            total={decryptedData.length}
            pageSize={itemsPerPage}
            onChange={handlePageChange}
            className='mt-1'
          />
        </>
      ) : (
        <Empty description={<FormattedMessage id='no_data' />} className='mt-24' />
      )}
    </>
  );
};

export default PaginationComponent;
