import Login from "../pages/auth/login/Login";

const routesAuthentication = [
  {
    path: "login",
    element: <Login />,
  }
];

export default routesAuthentication;
