import { FC } from "react";

interface EnvelopeSVGProps {
    width?: number;
    height?: number;
}

const EnvelopeSVG: FC<EnvelopeSVGProps> = ({ width = "18px", height = "18px" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8687 5.56878C1.68194 7.30405 1.69027 9.30537 1.95015 11.0336C2.09395 11.9898 2.87439 12.7229 3.83777 12.8067L4.84459 12.8942C6.94396 13.0767 9.0552 13.0767 11.1546 12.8942L12.1614 12.8067C13.1248 12.7229 13.9052 11.9898 14.049 11.0336C14.3089 9.30536 14.3172 7.30416 14.1305 5.56889C14.1063 5.36771 14.0792 5.16681 14.049 4.96624C13.9052 4.00998 13.1248 3.27689 12.1614 3.19314L11.1546 3.10562C9.0552 2.92313 6.94396 2.92313 4.84459 3.10562L3.83777 3.19314C2.87439 3.27689 2.09395 4.00998 1.95015 4.96624C1.91999 5.16677 1.89284 5.36764 1.8687 5.56878ZM4.93119 4.10186C6.97293 3.92438 9.02622 3.92438 11.068 4.10186L12.0748 4.18938C12.5777 4.2331 12.9851 4.61578 13.0601 5.11495C13.0679 5.16679 13.0755 5.21865 13.0829 5.27053L9.37554 7.33016C8.51981 7.80557 7.4793 7.80557 6.62357 7.33016L2.91627 5.27056C2.92365 5.21866 2.93123 5.16679 2.93903 5.11495C3.01409 4.61578 3.42149 4.2331 3.92437 4.18938L4.93119 4.10186ZM13.2052 6.34651C13.3346 7.85834 13.2862 9.38158 13.0601 10.8849C12.9851 11.3841 12.5777 11.7667 12.0748 11.8104L11.068 11.898C9.02623 12.0755 6.97293 12.0755 4.9312 11.898L3.92437 11.8104C3.42149 11.7667 3.01409 11.3841 2.93903 10.8849C2.71297 9.38159 2.6646 7.85836 2.79391 6.34653L6.13792 8.20432C7.29568 8.84752 8.70343 8.84752 9.86119 8.20432L13.2052 6.34651Z" fill="#9999A7"/>
</svg>
    );
};

export default EnvelopeSVG;
