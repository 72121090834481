import { Line } from "react-chartjs-2";
import "chart.js/auto";
import { useDispatch, useSelector } from "react-redux";

const MostBookedServices = () => {

  const currentLang = useSelector((state) => state?.user?.currentLang);

  const options = {
    type: "line",
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "rgba(50, 50, 50, 0.8)",
        bodyFont: { size: 14 },
      },
    },
    layout: {
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },

    scales: {
      x: {
        position: "bottom", // Align x-axis labels to the top
        ticks: {
          color: "black",
          font: { size: 12, family: "Arial" },
        },
        grid: {
          color: "rgba(0, 0, 0, 0)",
        },
      },
      y: {
        position: currentLang === "ar" ? "right" : "left",
        ticks: {
          color: "black",
          font: { size: 12, family: "Arial" },
        },
        grid: {
          color: "rgba(0, 0, 0, 0)",
        },
      },
    },
    indexAxis: "x",
    maintainAspectRatio: false, // Disable maintaining aspect ratio
    aspectRatio: 1, // Set the aspect ratio to control the height
    plugins: {
      tooltip: {
        mode: "index",
        intersect: false,
      },
    },
    parsing: {
      xAxisKey: "month",
      yAxisKey: "value",
    },
  };

  const generateLabels = () => {
    const labels = {
      en: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      ar: [
        "يناير",
        "فبراير",
        "مارس",
        "أبريل",
        "مايو",
        "يونيو",
        "يوليو",
        "أغسطس",
        "سبتمبر",
        "أكتوبر",
        "نوفمبر",
        "ديسمبر",
      ],
    };

    return currentLang === "ar" ? labels.ar : labels.en;
  };
  const value = [100, 150, 200, 250, 300, 320, 390, 290, 390, 320, 280, 200];

  const reversedLabels = generateLabels().slice().reverse();
  const reversedValue = value.slice().reverse();

  const handleGradientBackground = ({ chart: { ctx } }) => {
    const gradient = ctx.createLinearGradient(0, 0, 0, 300);
    gradient.addColorStop(0, "rgba(149, 164, 252, 0.19)"); // Black
    gradient.addColorStop(1, "rgba(149, 164, 252, 0.01)"); // White
    return gradient;
  };
  const data = {
    labels: reversedLabels,
    datasets: [
      {
        label: "",
        data: reversedValue,
        borderColor: "rgba(14, 24, 171, 0.48)",
        backgroundColor: handleGradientBackground,
        fill: true,
        borderWidth: 1,
      },
    ],
  };

  return (
    <div dir="rtl" style={{ height: "18rem" }} className="">
      <Line id={100} options={options} data={data} />
    </div>
  );
};

export default MostBookedServices;
