import {
  Card,
  Flex,
  MenuProps,
  Tabs,
  TabsProps,
} from "antd";
import { useLocation } from "react-router-dom";
import { queryKeys } from "../../services/react-query/queryKeys";
import { useQuery } from "@tanstack/react-query";
import { ShowDate } from "../../network/showDate";
import BasicInfo from "../../components/accreditation/center/BasicInfo";
import { decodeResponse, decryptItem } from "../../helpers/apiUtils";
import AccreditationTrainerTable from "../../components/accreditation/trainer/AccreditationTrainerTable";
import CustomHeader from "../../components/common/CustomHeader";
import { FormattedMessage } from "react-intl";


export default function AccreditationData() {
  const location = useLocation();

  const data = location?.state.accreditation_center_data;

  const values = {
    mode: "accreditation_center",
    type: "show",
    id: data.id
  };

  const { data: SpacficAccreditationCenter } = useQuery(
    [queryKeys.SpacficAccreditationCenter],
    () => ShowDate(values)
  );

  // const decodedResponse = decodeResponse(SpacficCorporates);
  const decodedResponse = decodeResponse(SpacficAccreditationCenter);
  let decryptedData;

  if (decodedResponse?.data) {
    decryptedData = decryptItem(decodedResponse.data);
    const excludedFields = ['country', 'city', 'quality_manual','tax_registration','commercial_registration', 'website', 'accreditation', 'license', 'contact_person', 'contact_title'];

    decryptedData = Object.fromEntries(
      Object.entries(decryptedData).map(([key, value]) => {
        return excludedFields.includes(key) ? [key, decodedResponse.data[key]] : [key, value];
      })
    );
  }


  const itemsTab: TabsProps['items'] = [
    {
      key: '1',
      label: <FormattedMessage id={'basic_info'} />,
      children: <BasicInfo AccreditationCenterData={decryptedData} />
    },
    {
      key: '2',
      label: <FormattedMessage id={'accreditation_trainer'} />,
      children: <AccreditationTrainerTable AccreditationCenter={decryptedData} />,
    },
  ];

  return (
    <>
      <CustomHeader title={<FormattedMessage id={'accreditation'} />} />

      <div className="customTab2 mt-8">
        <Tabs
          defaultActiveKey="1"
          items={itemsTab}
          className="customTab mt-8"
          tabBarStyle={{ margin: 0 }}
        />
      </div>
    </>

  );
}

