import { Content, Header } from "antd/es/layout/layout";
import { FC } from "react";
import ServicesWrapper from "../../components/overview/ServicesWrapper";
import PurchasedWrapper from "../../components/overview/PurchasedWrapper";
import { Card, Col, Row, Statistic } from "antd";
import TotalReservationWrapper from "../../components/overview/TotalReservationWrapper";
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { FormattedMessage } from "react-intl";

interface OverviewProps { }

const Overview: FC<OverviewProps> = ({ }) => {
  return (
    <div className="w-full pb-5">
      <Row gutter={16} className="mb-5">
        <Col span={6}>
          <Card>
            <Statistic
              title={<FormattedMessage id={'total_sales'} />}
              value={11.28}
              precision={2}
              valueStyle={{ color: '#3f8600' }}
              prefix={<ArrowUpOutlined />}
              suffix="%"
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title={<FormattedMessage id={'visits'} />}
              value={9.3}
              precision={2}
              valueStyle={{ color: '#cf1322' }}
              prefix={<ArrowDownOutlined />}
              suffix="%"
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title={<FormattedMessage id={'operational_effect'} />}
              value={9.3}
              precision={2}
              valueStyle={{ color: '#cf1322' }}
              prefix={<ArrowDownOutlined />}
              suffix="%"
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title={<FormattedMessage id={'payments'} />}
              value={6560}
              precision={0}
              valueStyle={{ color: '#3f8600' }}
              suffix="$"
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={16} className="mb-5">
        <Col span={24}>
          <PurchasedWrapper />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <ServicesWrapper />
        </Col>
        <Col span={12}>
          <TotalReservationWrapper />
        </Col>
      </Row>
    </div>
  );
};

export default Overview;
