import { FC } from "react";

interface PhoneProps {
    width?: number;
    height?: number;
}

const Phone: FC<PhoneProps> = ({ width = "18px", height = "18px" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
        <path d="M16.1318 19.74C16.1253 19.74 16.1187 19.74 16.1122 19.74C12.9556 19.6366 9.25762 16.5769 6.70973 14.0273C4.15856 11.4778 1.0988 7.7782 1.00036 4.63641C0.964264 3.53391 3.66966 1.57336 3.69755 1.55367C4.39973 1.06477 5.17903 1.23867 5.49895 1.68164C5.71551 1.98188 7.7663 5.08922 7.98942 5.44195C8.22075 5.80781 8.1863 6.3525 7.89755 6.89883C7.73841 7.20234 7.21012 8.13094 6.96239 8.56406C7.22981 8.94469 7.93692 9.8782 9.39708 11.3384C10.8589 12.7985 11.7907 13.5073 12.173 13.7747C12.6061 13.527 13.5347 12.9987 13.8382 12.8395C14.3764 12.5541 14.9178 12.518 15.2869 12.7444C15.6643 12.9757 18.7634 15.0363 19.0489 15.2348C19.2884 15.4038 19.4426 15.6926 19.4738 16.0289C19.5033 16.3685 19.3983 16.7278 19.1801 17.0412C19.1621 17.0674 17.2245 19.74 16.1318 19.74Z" fill="#0E103A"/>
      </svg>
    );
};

export default Phone;
