import React, { FC, useEffect, useState } from 'react';
import { Breadcrumb, Button, Card, Col, Dropdown, Input, MenuProps, Row, Space, Table, message } from 'antd';
import { DownOutlined, UserOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { AudioOutlined } from '@ant-design/icons';
import { SearchProps } from 'antd/es/input';
import ReloadSVG from '../../../assets/common/ReloadSVG';
import WrapperSVG from '../../../assets/common/WrapperSVG';
import SettingSVG from '../../../assets/common/SettingSVG';
import { FormattedMessage, useIntl } from 'react-intl';

interface FilterTableProps {
  buttonText?: any;
  onClick?: () => void;
  onClickUpload?: () => void;
  tabName?: any;
  items?: any;
  isUpload?: boolean;
  isAdded?: boolean;
  onSearch: (value: string) => void; 
}
const { Search } = Input;

const FilterTable: FC<FilterTableProps> = ({
  buttonText,
  isUpload,
  tabName,
  isAdded,
  items,
  onClick,
  onClickUpload,
  onSearch,
}) => {
  const intl = useIntl();

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 8, lg: 14 }} justify={"space-between"} className='my-3'>
      <Col className='text-[16px] text-normal mb-3' style={{ color: "rgba(0, 0, 0, 0.85)" }}>
        <Breadcrumb
          className='text-[16px] text-normal'
          separator=">"
          items={items}
        />
      </Col>
      <Col className='mb-3'>
        <Row gutter={{ xs: 8, sm: 16, md: 8, lg: 14 }} justify={"space-between"}>
          <Col className='mb-3'>
            <Search
              placeholder={intl.formatMessage({ id: 'search' })}
              onSearch={onSearch}
              className='xl:w-[300px]'
            />
          </Col>
          {isAdded ?? (
            <Col className='mb-3'>
              <Button type="text" onClick={onClick} className='text-white bg-[#0E103A]' icon={<PlusOutlined />}>
                <Space>
                  {buttonText}
                </Space>
              </Button>
            </Col>
          )
          }
          {isUpload ?? (
            <Col className='mb-3'>
              <Button type="default" onClick={onClickUpload} className='text-black' icon={<UploadOutlined />}>
                <Space>
                  <FormattedMessage id='upload' />
                </Space>
              </Button>
            </Col>
          )
          }
        </Row>
      </Col>
    </Row>
  );
};

export default FilterTable;