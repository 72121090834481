import { FC } from "react";

interface DeleteSVGProps {
    width?: number;
    height?: number;
}

const DeleteSVG: FC<DeleteSVGProps> = ({ width = "18px", height = "18px" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14" fill="none">
        <path d="M4.66732 0.5C4.39118 0.5 4.16732 0.723858 4.16732 1V1.5H1.33398C1.05784 1.5 0.833984 1.72386 0.833984 2C0.833984 2.27614 1.05784 2.5 1.33398 2.5H10.6673C10.9435 2.5 11.1673 2.27614 11.1673 2C11.1673 1.72386 10.9435 1.5 10.6673 1.5H7.83398V1C7.83398 0.723858 7.61013 0.5 7.33398 0.5H4.66732Z" fill="#C43535"/>
        <path d="M6.70776 9L7.68754 9.97977C7.8828 10.175 7.8828 10.4916 7.68754 10.6869C7.49228 10.8821 7.17569 10.8821 6.98043 10.6869L6.00066 9.70711L5.02088 10.6869C4.82561 10.8821 4.50903 10.8821 4.31377 10.6869C4.11851 10.4916 4.11851 10.175 4.31377 9.97978L5.29355 9L4.31376 8.02021C4.1185 7.82495 4.1185 7.50837 4.31376 7.31311C4.50903 7.11785 4.82561 7.11785 5.02087 7.31311L6.00066 8.29289L6.98044 7.31311C7.1757 7.11785 7.49228 7.11785 7.68754 7.31311C7.8828 7.50837 7.8828 7.82496 7.68754 8.02022L6.70776 9Z" fill="#C43535"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.99493 4.27812C2.02307 4.0249 2.2371 3.83333 2.49188 3.83333H9.50942C9.76419 3.83333 9.97823 4.0249 10.0064 4.27812L10.1398 5.47901C10.3817 7.6559 10.3817 9.85288 10.1398 12.0298L10.1267 12.148C10.0306 13.0121 9.36095 13.7011 8.49993 13.8216C6.84183 14.0538 5.15947 14.0538 3.50137 13.8216C2.64035 13.7011 1.97065 13.0121 1.87464 12.148L1.8615 12.0298C1.61963 9.85287 1.61963 7.6559 1.8615 5.47901L1.99493 4.27812ZM2.9394 4.83333L2.85538 5.58945C2.62166 7.69293 2.62166 9.81584 2.85538 11.9193L2.86852 12.0375C2.91406 12.4474 3.23167 12.7741 3.64002 12.8313C5.20613 13.0506 6.79516 13.0506 8.36128 12.8313C8.76963 12.7741 9.08724 12.4474 9.13277 12.0375L9.14591 11.9193C9.37963 9.81584 9.37963 7.69293 9.14591 5.58945L9.0619 4.83333H2.9394Z" fill="#C43535"/>
      </svg>
    );
};

export default DeleteSVG;
