// Define an enum with numeric values
enum genderEnum {
  Male = "male",
  Female = "Female",
}

// Create a constant array to define the genderType
const gender: { value: string; label: string }[] = [
  { value: genderEnum.Male, label: 'Male' },
  { value: genderEnum.Female, label: 'Female' },
];

export { genderEnum, gender };
