import { Empty, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useSelector } from "react-redux";
import { PlusOutlined, DownOutlined, DeleteOutlined } from '@ant-design/icons';
import { FormattedMessage } from "react-intl";

type Props = {
  columns: ColumnsType<any>;
  data: any;
  scroll?: any; // Adjust the type here
  expandableData?: any;
};

const CustomTable = ({ columns, data, scroll, expandableData }: Props) => {
  const currentLang = useSelector((state: any) => state?.user?.currentLang);

  return (
    <div className={currentLang === "ar" ? "customTableAr" : "customTableEn"}>
      {data && data.length > 0 ? (
        <Table columns={columns} dataSource={data || []} rowClassName={() => "customTableRow"} scroll={scroll} expandable={expandableData} locale={{ emptyText: <Empty description={<FormattedMessage id='no_data' />} /> }} />
      ) : (
        <Empty description={<FormattedMessage id='no_data' />} className='mt-24' />
      )}
    </div>
  );
};

export default CustomTable;
