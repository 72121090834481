import React from 'react';
import { Card } from 'antd';
import Meta from 'antd/es/card/Meta';
import { decryptData, decryptItem } from '../../helpers/apiUtils';

interface InfoProps {
  eLearningData: any;
}

const Info: React.FC<InfoProps> = ({ eLearningData }) => {
  const formatInstruction = (instruction: string) => {
    // Split the instruction by new lines and map each line to a <p> tag
    const formattedLines = instruction.split('\n').map((line, index) => (
      <p key={index}>{line}</p>
    ));

    return <>{formattedLines}</>;
  };
  console.log("eLearningData", eLearningData);
  return (
    <div className='customCard'>
      <Card
        bordered={false}
        cover={
          <img
            alt="example"
            src={eLearningData.certificate.media}
            style={{ objectFit: 'contain', width: '100%', height: '150px' }}
          />
        }
      >
        <Meta
          title={
            decryptData(eLearningData?.certificate?.name)
          }
          description={<div>{formatInstruction(decodeURIComponent(eLearningData?.instruction))}</div>}
        />
      </Card>
    </div>
  );
}

export default Info;
