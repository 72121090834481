import { useState } from 'react';
import { Button, Card, Col, Flex, Form, Input, Modal, Row, message } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { queryKeys } from '../../services/react-query/queryKeys';
import { StoreData } from '../../network/storeDate';
import PhoneInputWithCountrySelect from 'react-phone-number-input';
import queryClient from '../../services/react-query/queryClient';
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from 'react-intl';

interface AddModalProps<T> {
  visible: boolean;
  onClose: () => void;
}

const AddCorporate: React.FC<AddModalProps<any>> = ({
  visible,
  onClose,
}) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const navigate = useNavigate();
  const { mutate: onAddCorporate, isLoading } = useMutation(StoreData, {
    onSuccess: (res) => {
      message.success("Submit success!");
      queryClient.invalidateQueries([queryKeys.listCorporate]);
      onCancel();
    },
    onError: (error) => {
      message.error("حاول مرة اخرى");
    },
  });
  const onFinish = async (values: any) => {
    const data = {
      mode: "corporate",
      type: "store",
      ...values
    };

    onAddCorporate(data);
  }


  const handlePhoneNumberChange = (value: string) => {
    setPhoneNumber(value);
  };

  const [form] = Form.useForm();

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      centered
      open={visible}
      className="customModal"
      onOk={onFinish}
      onCancel={onCancel}
      footer={[]}
      title={<FormattedMessage id={'add_corporate'} />}
      width={1050}
    >

      <Form
        onFinish={onFinish}
        name="complex-form"
        layout="vertical"
        className="form-style mt-8 px-9 pb-5"
      >
        <Row className='w-[160px] bg-[#FAFAFA] mb-4 py-2 pl-2'>
          <Col >
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path d="M17.6786 8.92857H16.4286C16.3304 8.92857 16.25 9.00893 16.25 9.10714V16.25H1.60714V1.60714H8.75C8.84822 1.60714 8.92857 1.52679 8.92857 1.42857V0.178571C8.92857 0.0803571 8.84822 0 8.75 0H0.714286C0.319196 0 0 0.319196 0 0.714286V17.1429C0 17.5379 0.319196 17.8571 0.714286 17.8571H17.1429C17.5379 17.8571 17.8571 17.5379 17.8571 17.1429V9.10714C17.8571 9.00893 17.7768 8.92857 17.6786 8.92857Z" fill="#0E103A" />
              <path d="M5.44405 9.43973L5.40164 12.0937C5.39941 12.2924 5.56012 12.4554 5.75878 12.4554H5.76771L8.40164 12.3906C8.44628 12.3884 8.49092 12.3705 8.52217 12.3393L17.8057 3.07589C17.8749 3.0067 17.8749 2.89286 17.8057 2.82366L15.0311 0.0513393C14.9954 0.0156251 14.9507 0 14.9039 0C14.857 0 14.8124 0.0178572 14.7766 0.0513393L5.49539 9.31473C5.46315 9.34845 5.44481 9.39309 5.44405 9.43973ZM6.86146 9.96652L14.9039 1.94196L15.9128 2.94866L7.86592 10.9777L6.84583 11.0022L6.86146 9.96652Z" fill="#0E103A" />
            </svg>
          </Col>
          <Col className='px-3'>
            <FormattedMessage id={'basic_information'} />
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 14 }}>
          <Col xl={8}>
            <Form.Item
              name="name"
              label={<FormattedMessage id={'name'} />}
              required={false}
              className="form-style w-full"
              rules={[
                { required: true, message: 'Name is required' },
              ]}
            >
              <Input placeholder="Enter Name" />
            </Form.Item>
          </Col>
          <Col xl={8}>
            <Form.Item
              name="email"
              label={<FormattedMessage id={'email'} />}
              required={false}
              className="form-style w-full"
              rules={[
                { required: true, message: 'Email is required' },
              ]}
            >
              <Input placeholder="Enter Email " />
            </Form.Item>
          </Col>
          <Col xl={8}>
            <Form.Item
              name="phone"
              label={<FormattedMessage id={'phone'} />}
              className="form-style w-full"
              required={false}
            >
              <PhoneInputWithCountrySelect
                defaultCountry="SA"
                name='phone_country_code'
                international
                onChange={() => { }}
              />
            </Form.Item>

          </Col>
        </Row>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 14 }}>
          <Col xl={8}>
            <Form.Item
              name="corporate_type"
              label={<FormattedMessage id={'corporate_type'} />}
              required={false}
              className="form-style w-full"
              rules={[
                { required: true, message: 'corporate_type is required' },
              ]}
            >
              <Input placeholder="Enter Corporate Type " />
            </Form.Item>
          </Col>
          <Col xl={8}>
            <Form.Item
              name="filed"
              label={<FormattedMessage id={'filed'} />}
              required={false}
              className="form-style w-full"
              rules={[
                { required: true, message: 'filed is required' },
              ]}
            >
              <Input placeholder="Enter filed " />
            </Form.Item>
          </Col>
          <Col xl={8}>
            <Form.Item
              name="website"
              label={<FormattedMessage id={'website'} />}
              required={false}
              className="form-style w-full"
              rules={[
                { required: true, message: 'website is required' },
              ]}
            >
              <Input placeholder="Enter website" />
            </Form.Item>
          </Col>
        </Row>

        <Row className='w-[160px] bg-[#FAFAFA] mb-4 py-2 pl-2'>
          <Col >
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path d="M17.6786 8.92857H16.4286C16.3304 8.92857 16.25 9.00893 16.25 9.10714V16.25H1.60714V1.60714H8.75C8.84822 1.60714 8.92857 1.52679 8.92857 1.42857V0.178571C8.92857 0.0803571 8.84822 0 8.75 0H0.714286C0.319196 0 0 0.319196 0 0.714286V17.1429C0 17.5379 0.319196 17.8571 0.714286 17.8571H17.1429C17.5379 17.8571 17.8571 17.5379 17.8571 17.1429V9.10714C17.8571 9.00893 17.7768 8.92857 17.6786 8.92857Z" fill="#0E103A" />
              <path d="M5.44405 9.43973L5.40164 12.0937C5.39941 12.2924 5.56012 12.4554 5.75878 12.4554H5.76771L8.40164 12.3906C8.44628 12.3884 8.49092 12.3705 8.52217 12.3393L17.8057 3.07589C17.8749 3.0067 17.8749 2.89286 17.8057 2.82366L15.0311 0.0513393C14.9954 0.0156251 14.9507 0 14.9039 0C14.857 0 14.8124 0.0178572 14.7766 0.0513393L5.49539 9.31473C5.46315 9.34845 5.44481 9.39309 5.44405 9.43973ZM6.86146 9.96652L14.9039 1.94196L15.9128 2.94866L7.86592 10.9777L6.84583 11.0022L6.86146 9.96652Z" fill="#0E103A" />
            </svg>
          </Col>
          <Col className='px-3'>
            <FormattedMessage id={'location'} />:
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 14 }}>
          <Col xl={12}>
            <Form.Item
              name="country"
              label={<FormattedMessage id={'country'} />}
              required={false}
              className="form-style w-full"
              rules={[
                { required: true, message: 'country is required' },
              ]}
            >
              <Input placeholder="Enter Country" />
            </Form.Item>
          </Col>
          <Col xl={12}>
            <Form.Item
              name="city"
              label={<FormattedMessage id={'city'} />}
              required={false}
              className="form-style w-full"
              rules={[
                { required: true, message: 'City is required' },
              ]}
            >
              <Input placeholder="Enter city " />
            </Form.Item>
          </Col>

        </Row>
        <Row className='w-[160px] bg-[#FAFAFA] mb-4 py-2 pl-2'>
          <Col >
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path d="M17.6786 8.92857H16.4286C16.3304 8.92857 16.25 9.00893 16.25 9.10714V16.25H1.60714V1.60714H8.75C8.84822 1.60714 8.92857 1.52679 8.92857 1.42857V0.178571C8.92857 0.0803571 8.84822 0 8.75 0H0.714286C0.319196 0 0 0.319196 0 0.714286V17.1429C0 17.5379 0.319196 17.8571 0.714286 17.8571H17.1429C17.5379 17.8571 17.8571 17.5379 17.8571 17.1429V9.10714C17.8571 9.00893 17.7768 8.92857 17.6786 8.92857Z" fill="#0E103A" />
              <path d="M5.44405 9.43973L5.40164 12.0937C5.39941 12.2924 5.56012 12.4554 5.75878 12.4554H5.76771L8.40164 12.3906C8.44628 12.3884 8.49092 12.3705 8.52217 12.3393L17.8057 3.07589C17.8749 3.0067 17.8749 2.89286 17.8057 2.82366L15.0311 0.0513393C14.9954 0.0156251 14.9507 0 14.9039 0C14.857 0 14.8124 0.0178572 14.7766 0.0513393L5.49539 9.31473C5.46315 9.34845 5.44481 9.39309 5.44405 9.43973ZM6.86146 9.96652L14.9039 1.94196L15.9128 2.94866L7.86592 10.9777L6.84583 11.0022L6.86146 9.96652Z" fill="#0E103A" />
            </svg>
          </Col>
          <Col className='px-3'>
            <FormattedMessage id={'contact_info'} />:
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 14 }}>
          <Col xl={6}>
            <Form.Item
              name="contact_person"
              label={<FormattedMessage id={'contact_person'} />}
              required={false}
              className="form-style w-full"
              rules={[
                { required: true, message: 'Contact Person is required' },
              ]}
            >
              <Input placeholder="Enter Contact Person" />
            </Form.Item>
          </Col>
          <Col xl={6}>
            <Form.Item
              name="contact_email"
              label={<FormattedMessage id={'contact_email'} />}
              required={false}
              className="form-style w-full"
              rules={[
                { required: true, message: 'Contact email is required' },
              ]}
            >
              <Input placeholder="Enter Contact Email " />
            </Form.Item>
          </Col>
          <Col xl={6}>
            <Form.Item
              name="contact_title"
              label={<FormattedMessage id={'contact_title'} />}
              required={false}
              className="form-style w-full"
              rules={[
                { required: true, message: 'contact_title is required' },
              ]}
            >
              <Input placeholder="Enter Contact Title " />
            </Form.Item>
          </Col>
          <Col xl={6}>
            <Form.Item
              name="contact_phone"
              label={<FormattedMessage id={'contact_phone'} />}
              className="form-style w-full"
              required={false}
            >
              <PhoneInputWithCountrySelect
                defaultCountry="SA"
                name='phone_country_code'
                international
                onChange={() => { }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Flex gap="middle" align="center" justify="flex-end">
          <Button
            type="text"
            onClick={() => onCancel()}
            loading={isLoading}
            className="bg-[#1d1d39] h-full text-white text-lg rounded-md px-2 w-[112px]"
            htmlType="button"
          >
            <FormattedMessage id={'cancel'} />
          </Button>
          <Button
            type="text"
            loading={isLoading}
            className="bg-[#cf2110] h-full text-white text-lg rounded-md px-2 w-[112px]"
            htmlType="submit"
          >
            <FormattedMessage id={'save'} />
          </Button>
        </Flex>
      </Form>
    </Modal>

  );
}

export default AddCorporate;

