import { Button, Col, Flex, Form, Input, Row, Select, Switch, Typography, message } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../../services/react-query/queryKeys';
import queryClient from '../../../services/react-query/queryClient';
import { useLocation, useNavigate } from "react-router-dom";
import TextArea from 'antd/es/input/TextArea';
import { FetchData } from '../../../network/fetchData';
import { decodeResponse, decryptItem } from '../../../helpers/apiUtils';
import CustomHeader from '../../common/CustomHeader';
import { FormattedMessage } from 'react-intl';
import { questionDifficulty } from '../../../enum/questionDifficulty';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { MinusCircleOutlined, PlusOutlined, VerticalLeftOutlined } from '@ant-design/icons';
import { editFormData } from '../../../network/editData';

function EditQuestionsBank() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const location = useLocation();
  const question_data = location?.state.question_data;
  const [selectedCertificate, setSelectedCertificate] = useState(question_data?.level?.certificate_id);
  const [selectedLevel, setSelectedLevel] = useState(question_data?.level?.id);

  const { mutate: onEditQuestion, isLoading } = useMutation(editFormData, {
    onSuccess: (res) => {
      console.log("Add Exam response:", res);
      message.success("Submit success!");
      queryClient.invalidateQueries([queryKeys.listExams]);
      navigate("/questions/bank");
    },
    onError: (error) => {
      console.log("Add Category error:", error);
      message.error("حاول مرة اخرى");
    },
  });


  const onFinish = async (values: any) => {
    let processedQuestion;
  
    if (question_data.type === 'mcq') {
      let answers = values.answers.map((answer: any) => ({
        ...answer,
        is_correct: values.right_answers.some((right: any) => right.answer === answer.answer) ? 1 : 0,
      }));
  
      values.right_answers.forEach((right: any) => {
        if (!answers.some((answer: any) => answer.answer === right.answer)) {
          answers.push({
            answer: right.answer,
            is_correct: 1,
          });
        }
      });
  
      processedQuestion = {
        ...values,
        answers,
      };
    } else if (question_data.type === 'true_false') {
      const switch_value = values.switch ? 1 : 0; // Ensure switch_value is an integer (1 or 0)
      const answers = [
        { answer: 'true', is_correct: switch_value },
        { answer: 'false', is_correct: switch_value === 1 ? 0 : 1 },
      ];
  
      processedQuestion = {
        ...values,
        answers,
      };
    }
  
    const data = {
      mode: "question_bank",
      type: "update",
      id: question_data.id,
      ...processedQuestion,
    };

    onEditQuestion(data);
  };
  
  
  const currentLang = useSelector((state: any) => state?.user?.currentLang);

  const valueCertificate = {
    mode: "certificate",
    type: "get",
    language_id: currentLang === "ar" ? 1 : currentLang === "en" ? 2 : null
  };

  const { data: listCertificates } = useQuery(
    [queryKeys.listCertificates, valueCertificate.language_id],
    () => FetchData(valueCertificate)
  );

  const decodedResponse = decodeResponse(listCertificates);
  const decryptedData = decodedResponse?.data?.map(decryptItem);

  const valueChapter = {
    mode: "chapter",
    type: "get",
    level_id: selectedLevel,
  };

  const valueLevel = {
    mode: "level",
    type: "get",
    certificate_id: selectedCertificate,
  };

  const { data: listChapters } = useQuery(
    [queryKeys.listChapters, selectedLevel],
    () => FetchData(valueChapter),
    {
      enabled: !!selectedLevel,
    }
  );

  const { data: listLevels } = useQuery(
    [queryKeys.listLevels, selectedCertificate],
    () => FetchData(valueLevel),
    {
      enabled: !!selectedCertificate,
    }
  );

  const decodedResponseExamChapters = decodeResponse(listChapters);
  const decryptedExamChapters = decodedResponseExamChapters?.data?.map(decryptItem);

  const decodedResponseLevels = decodeResponse(listLevels);
  const decryptedLevels = decodedResponseLevels?.data?.map(decryptItem);

  const onClose = () => {
    navigate("/questions/bank");
  };

  return (
    <>
      <CustomHeader title={<FormattedMessage id={'edit_questions_bank'} />} />

      <Form
        onFinish={onFinish}
        name="complex-form"
        layout="vertical"
        className="form-style mt-8 p-1"
        form={form}
        initialValues={{
          question: question_data?.question,
          certificate_id: question_data?.level?.certificate_id,
          chapter_id: question_data?.chapter_id,
          difficulty: question_data?.difficulty,
          level_id: question_data?.level?.id,
          answers: question_data?.answers?.filter((answer: { is_correct: any; }) => !answer.is_correct)
            .map((answer: { answer: any; }) => ({ answer: answer.answer })),
          right_answers: question_data?.answers
            ?.filter((answer: { is_correct: any; }) => answer.is_correct)
            .map((answer: { answer: any; }) => ({
              answer: answer.answer,
            })),
        }}
      >
        <Row gutter={15}>
          <Col xl={12}>
            <Form.Item
              name={'certificate_id'}
              label={<FormattedMessage id={'certificate'} />}
              required={false}
              className="form-style w-full"
              rules={[
                { required: true, message: 'certificate is required' },
              ]}
            >
              <Select
                placeholder="Choces Certificate"
                options={decryptedData?.map((item: { id: any; name: any; }) => ({
                  value: item?.id,
                  label: item?.name,
                }))}
                onChange={(value) => setSelectedCertificate(value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 14 }}>
          <Col xl={8}>
            <Form.Item
              label={<FormattedMessage id={'difficulty'} />}
              required={false}
              name={'difficulty'}
              rules={[{ required: true, message: 'Missing Difficulty' }]}
            >
              <Select
                placeholder="Choces Question Level"
                options={questionDifficulty?.map((item) => ({
                  value: item.value,
                  label: item.label,
                }))}
              />
            </Form.Item>
          </Col>
          <Col xl={8}>
            <Form.Item
              label={<FormattedMessage id={'level'} />}
              required={false}
              name={'level_id'}
              rules={[{ required: true, message: 'Missing Level' }]}
            >
              <Select
                placeholder="Choces Question Level"
                options={decryptedLevels?.map((item: { id: any; name: any; }) => ({
                  value: item?.id,
                  label: item?.name,
                }))}
                onChange={(value) => setSelectedLevel(value)}
              />
            </Form.Item>
          </Col>
          <Col xl={8}>
            <Form.Item
              label={<FormattedMessage id={'chapter'} />}
              required={false}
              name={'chapter_id'}
              rules={[{ required: true, message: 'Missing Chapter' }]}
            >
              <Select
                placeholder="Choces Chapter"
                options={decryptedExamChapters?.map((item: { id: any; name: any; }) => ({
                  value: item?.id,
                  label: item?.name,
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 14 }}>
          <Col xl={24}>
            <Form.Item
              label={<FormattedMessage id={'Question'} />}
              required={false}
              name={'question'}
              rules={[{ required: true, message: 'Missing Question' }]}
            >
              <TextArea rows={3} placeholder="Enter Question" />
            </Form.Item>
          </Col>
        </Row>


        {question_data.type === 'mcq' && question_data.type && (
          <>
            <Typography.Title level={5} type="secondary" ><VerticalLeftOutlined /> <FormattedMessage id={'answers'} /></Typography.Title>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 14 }} className="w-full">
              <Form.List name={'answers'}>
                {(answerFields, { add: addAnswer, remove: removeAnswer }) => (
                  <>
                    {answerFields.map(({ key: answerKey, name: answerName, ...answerRestField }) => (
                      <Col span={12} key={answerKey}>
                        <Row gutter={20}>
                          <Col span={22}>
                            <Form.Item
                              required={false}
                              {...answerRestField}
                              name={[answerName, 'answer']}
                              rules={[{ required: true, message: 'Missing Answer' }]}
                            >
                              <Input placeholder="Enter Answer" />
                            </Form.Item>
                          </Col>
                          <Col span={2} className='mt-2'>
                            <MinusCircleOutlined onClick={() => removeAnswer(answerName)} />
                          </Col>
                        </Row>
                      </Col>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => addAnswer()}
                        block
                        icon={<PlusOutlined />}
                        disabled={answerFields.length >= 3}
                      >
                        Add Answer
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Row>
            <Typography.Title level={5} type="secondary" ><VerticalLeftOutlined /> <FormattedMessage id={'right_ans'} /></Typography.Title>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 14 }} className="w-full">
              <Form.List name={'right_answers'}>
                {(answerFields, { add: addAnswer, remove: removeAnswer }) => (
                  <>
                    {answerFields.map(({ key: answerKey, name: answerName, ...answerRestField }) => (
                      <Col span={12} key={answerKey}>
                        <Row gutter={20}>
                          <Col span={22}>
                            <Form.Item
                              required={false}
                              {...answerRestField}
                              name={[answerName, 'answer']}
                              rules={[{ required: true, message: 'Missing Right Answer' }]}
                            >
                              <Input placeholder="Enter Right Answer" />
                            </Form.Item>
                          </Col>
                          <Col span={2} className='mt-2'>
                            <MinusCircleOutlined onClick={() => removeAnswer(answerName)} />
                          </Col>
                        </Row>
                      </Col>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => addAnswer()}
                        block
                        icon={<PlusOutlined />}
                        disabled={answerFields.length >= 3}
                      >
                        Add Answer
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Row>
          </>
        )}

        {question_data.type !== "mcq" && (
          <Row gutter={24}>
            <Col>
              <Typography.Title level={5} className='' type="secondary" ><VerticalLeftOutlined /> <FormattedMessage id={'right_ans'} /></Typography.Title>
            </Col>
            <Col span={12} className='mt-6'>
              <Form.Item
                name={['switch']}
                valuePropName="checked"
                initialValue={question_data.type === 'true_false' ? (question_data.answers[0]?.is_correct === 1) : false} 
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>
        )}
    

        <Flex gap="middle" align="center" justify="flex-end">
          <Button
            type="text"
            loading={isLoading}
            className="bg-[#1d1d39] h-full text-white text-lg rounded-md px-2 w-[112px]"
            htmlType="button"
            onClick={onClose}
          >
            <FormattedMessage id={'cancel'} />
          </Button>
          <Button
            type="text"
            loading={isLoading}
            className="bg-[#cf2110] h-full text-white text-lg rounded-md px-2 w-[112px]"
            htmlType="submit"
          >
            <FormattedMessage id={'save'} />
          </Button>
        </Flex>
      </Form >
    </>

  );
}

export default EditQuestionsBank;