import React from "react";
import { Card, Flex, Tabs, TabsProps } from "antd";
import { css } from "@emotion/css";
import CorporateTable from "../../components/corporate/CorporateTable";
import CustomHeader from "../../components/common/CustomHeader";
import { FormattedMessage } from "react-intl";

export default function Corporate() {
  return (
    <>
      <CustomHeader title={<FormattedMessage id={'corporate'} />} />

      <CorporateTable />
    </>

  );
}


