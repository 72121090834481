import React, { useState } from 'react';
import { Empty, Pagination, Space, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useQuery } from '@tanstack/react-query';
import { FetchData } from '../../../network/fetchData';
import { decodeResponse, decryptItem } from '../../../helpers/apiUtils';
import FilterTable from '../../common/filtersTables/FilterTable';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { queryKeys } from '../../../services/react-query/queryKeys';
import DeleteModal from '../../common/DeleteModal';
import { deleteData } from '../../../network/deleteData';
import { Link, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import UploadFileModal from '../../common/UploadFile';



const ListExamConfigurations: React.FC = () => {
  const [selectedRowId, setSelectedRowId] = useState<React.Key | null>(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalAction, setModalAction] = useState<'add' | 'update' | "delete" | 'examData' | 'upload' | null>(null);
  const [selectedExamData, setSelectedExamData] = useState<{ data: any }[]>([]);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');


  const showModal = (action: 'add' | 'update' | 'delete' | 'examData' | "upload", id?: React.Key) => {
    setSelectedRowId(id !== undefined ? id : null);
    setModalAction(action);
    setModalVisible(true);
    // Redirect to the 'add/exm' route when 'add' is clicked
    if (action === 'add') {
      navigate('/add/exam/configuration');
    } else {
      setModalVisible(true);
    }
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const currentLang = useSelector((state: any) => state?.user?.currentLang);

  const values = {
    mode: "exam_configuration",
    type: "get",
    language_id: currentLang === "ar" ? 1 : currentLang === "en" ? 2 : null
  };

  const deleteValue = {
    mode: "exam_configuration",
    type: "delete",
    id: selectedRowId
  };

  const { data: listExams } = useQuery(
    [queryKeys.listExams, values.language_id, searchTerm],
    () => FetchData({
      ...values,
      name: searchTerm,
    }),
    {
      keepPreviousData: true,
    }
  );

  const decodedResponse = decodeResponse(listExams);

  const decryptedData = decodedResponse?.data?.map((item: any) => {
    const decryptedItem = decryptItem(item);
    const excludedFields = ['exam_duration', 'questions', 'randomize_categories', 'certificate'];

    const itemWithoutDecryption = Object.fromEntries(
      Object.entries(decryptedItem).map(([key, value]) => {
        return excludedFields.includes(key) ? [key, item[key]] : [key, value];
      })
    );

    const decryptedExamData = item.questions?.map((data: any) => {
      const decryptedQuestion = decryptItem(data?.question);

      const decryptedAnswers = data?.question?.answers?.map((answer: any) => {
        const decryptedAnswers = decryptItem(answer);
        return {
          ...decryptedAnswers,
        };
      });

      const questionWithLevel = {
        ...decryptedQuestion,
        level: data?.question?.level,
        type: data?.question?.type,
        difficulty: data?.question?.difficulty,
        answers: decryptedAnswers,
      };

      return {
        ...data,
        question: questionWithLevel,
      };
    });


    const decryptedELearning = item.e_learning ? ((e_learning: any) => {
      const decryptedData = decryptItem({ data: e_learning.name });
      return {
        ...e_learning,
        name: decryptedData.data, // Update the 'name' field with decrypted data
      };
    })(item.e_learning) : null; // Apply the function to item.e_learning if it exists

    itemWithoutDecryption.questions = decryptedExamData;
    itemWithoutDecryption.e_learning = decryptedELearning;

    return itemWithoutDecryption;
  });

  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage: number = 12;

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const indexOfLastItem: number = currentPage * itemsPerPage;
  const indexOfFirstItem: number = indexOfLastItem - itemsPerPage;


  const columns: ColumnsType<any> = [
    {
      title: <FormattedMessage id={'name'} />,
      dataIndex: 'exam_title',
      width: 200,
    },
    {
      title: <FormattedMessage id={'exam_duration'} />,
      dataIndex: 'exam_duration',
      width: 200,
    },
    {
      title: <FormattedMessage id={'intsruction'} />,
      dataIndex: 'exam_notes',
    },
    {
      key: 'operation',
      width: 100,
      render: (record: any) => (
        <Space>
          <Link to="/exam/configuration/data" className='text-gray-400' state={{ exam_configuration_data: record }}><EyeOutlined /></Link>
          <Link to="/edit/exam/configuration" className='text-gray-400' state={{ exam_configuration_data: record }}><EditOutlined /></Link>
          <DeleteOutlined style={{ color: "red" }} onClick={() => showModal('delete', record.id)} />
        </Space>
      ),
    },
  ];

  const items = [
    {
      title: <FormattedMessage id='exams' />,
    }
  ];
  const currentItems: any[] = decryptedData?.slice(indexOfFirstItem, indexOfLastItem);


  return (
    <>
      <FilterTable
        buttonText={<FormattedMessage id='add_exam' />}
        tabName={<FormattedMessage id='exams' />}
        onClick={() => showModal('add')}
        items={items}
        onClickUpload={() => showModal('upload')}
        onSearch={(value) => setSearchTerm(value)}
      />

      <UploadFileModal visible={isModalVisible && modalAction === 'upload'} onClose={closeModal} mode={"assessment_advice"} confirmationHeader={''} successMessage={''} queryKey={queryKeys.listAdvice} />

      <DeleteModal
        visible={isModalVisible && modalAction === 'delete'}
        onDelete={deleteData}
        data={deleteValue}
        confirmationHeader={<FormattedMessage id={'exam'} />}
        queryKey={queryKeys.listExams}
        onClose={closeModal}
      />

      {decryptedData && decryptedData.length > 0 ? (
        <>
          {currentItems.map((item) => (
            <div key={item.id} className='mb-5 custom-table'>
              <Table
                bordered
                dataSource={[item]}
                columns={columns}
                pagination={false}
              />
            </div>
          ))}
          <Pagination
            current={currentPage}
            total={decryptedData.length}
            pageSize={itemsPerPage}
            onChange={handlePageChange}
            className='mt-1'
          />
        </>
      ) : (
        <Empty description={<FormattedMessage id='no_data' />} className='mt-24' />
      )}
    </>
  );
};

export default ListExamConfigurations;