import React, { useState } from 'react';
import { Empty, Pagination, Space, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useQuery } from '@tanstack/react-query';
import { FetchData } from '../../../network/fetchData';
import { decodeResponse, decryptItem } from '../../../helpers/apiUtils';
import FilterTable from '../../common/filtersTables/FilterTable';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { queryKeys } from '../../../services/react-query/queryKeys';
import DeleteModal from '../../common/DeleteModal';
import { deleteData } from '../../../network/deleteData';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import UploadFileModal from '../../common/UploadFile';

const ListExams: React.FC = () => {
  const [selectedRowId, setSelectedRowId] = useState<React.Key | null>(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalAction, setModalAction] = useState<'add' | 'update' | "delete" | 'examData' | 'upload' | null>(null);
  const [searchTerm, setSearchTerm] = useState('');

  const showModal = (action: 'add' | 'update' | 'delete' | 'examData' | "upload", id?: React.Key) => {
    setSelectedRowId(id !== undefined ? id : null);
    setModalAction(action);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const currentLang = useSelector((state: any) => state?.user?.currentLang);

  const values = {
    mode: "exam_schedule",
    type: "get",
    language_id: currentLang === "ar" ? 1 : currentLang === "en" ? 2 : null
  };

  const deleteValue = {
    mode: "exam_schedule",
    type: "delete",
    id: selectedRowId
  };

  const { data: listExams } = useQuery(
    [queryKeys.listExams, values.language_id, searchTerm],
    () => FetchData({
      ...values,
      name: searchTerm,
    }),
    {
      keepPreviousData: true,
    }
  );

  const decodedResponse = decodeResponse(listExams);

  const decryptedData = decodedResponse?.data?.map((item: any) => {
    const decryptedItem = decryptItem(item);
    const excludedFields = ['status', 'scheduled_at', 'exam_configuration'];

    const itemWithoutDecryption = Object.fromEntries(
      Object.entries(decryptedItem).map(([key, value]) => {
        return excludedFields.includes(key) ? [key, item[key]] : [key, value];
      })
    );

    return itemWithoutDecryption;
  });

  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage: number = 12;

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const indexOfLastItem: number = currentPage * itemsPerPage;
  const indexOfFirstItem: number = indexOfLastItem - itemsPerPage;

  const columns: ColumnsType<any> = [
    {
      title: <FormattedMessage id={'scheduled_at'} />,
      dataIndex: 'scheduled_at',
      width: 200,
    },
    {
      title: <FormattedMessage id={'status'} />,
      dataIndex: 'status',
      width: 200,
    },
    {
      title: <FormattedMessage id={'exam_configuration'} />,
      dataIndex: 'exam_configuration_id',
    },
    {
      key: 'operation',
      width: 100,
      render: (record: any) => (
        <div className='text-center'>
          <Space >
            <Link to="/exam/data" className='text-gray-400 mx-1' state={{ exam_configuration: record }}><EyeOutlined /></Link>
            <DeleteOutlined style={{ color: "red" }} onClick={() => showModal('delete', record.id)} />
          </Space>
        </div>
      ),
    },
  ];

  const items = [
    {
      title: <FormattedMessage id='exams' />,
    }
  ];
  const examRunning = decryptedData?.filter((item: any) => item.status === 'running');
  const currentItems: any[] = examRunning?.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      <FilterTable
        items={items}
        isUpload={false}
        isAdded={false}
        onSearch={(value) => setSearchTerm(value)}
      />

      <UploadFileModal visible={isModalVisible && modalAction === 'upload'} onClose={closeModal} mode={"assessment_advice"} confirmationHeader={''} successMessage={''} queryKey={queryKeys.listAdvice} />


      <DeleteModal
        visible={isModalVisible && modalAction === 'delete'}
        onDelete={deleteData}
        data={deleteValue}
        confirmationHeader={<FormattedMessage id={'exam'} />}
        queryKey={queryKeys.listExams}
        onClose={closeModal}
      />

      {examRunning && examRunning.length > 0 ? (
        <>
          {currentItems.map((item) => (
            <div key={item.id} className='mb-5 custom-table'>
              <Table
                bordered
                dataSource={[item]}
                columns={columns}
                pagination={false}
              />
            </div>
          ))}
          <Pagination
            current={currentPage}
            total={examRunning.length}
            pageSize={itemsPerPage}
            onChange={handlePageChange}
            className='mt-1'
          />
        </>
      ) : (
        <Empty description={<FormattedMessage id='no_data' />} className='mt-24' />
      )}
    </>
  );
};

export default ListExams;