import {
  Tabs,
  TabsProps,
} from "antd";
import React from "react";
import { useLocation } from "react-router-dom";
import { queryKeys } from "../../services/react-query/queryKeys";
import { useQuery } from "@tanstack/react-query";
import { ShowDate } from "../../network/showDate";
import BasicInfo from "../../components/corporate/BasicInfo";
import { decodeResponse, decryptItem } from "../../helpers/apiUtils";
import BatchTable from "../../components/corporate/batch/BatchTable";
import CustomHeader from "../../components/common/CustomHeader";
import { FormattedMessage } from "react-intl";


export default function CorporateData() {
  const location = useLocation();
  const data = location?.state.corporate_data;

  const values = {
    mode: "corporate",
    type: "show",
    id: data.id
  };

  const { data: SpacficCorporates } = useQuery(
    [queryKeys.SpacficCorporates],
    () => ShowDate(values)
  );

  // const decodedResponse = decodeResponse(SpacficCorporates);
  const decodedResponse = decodeResponse(SpacficCorporates);
  let decryptedData;

  if (decodedResponse?.data) {
    decryptedData = decryptItem(decodedResponse.data);

    const excludedFields = ['name', 'country', 'city', 'filed', 'corporate_type', 'contact_person', 'contact_title'];

    decryptedData = Object.fromEntries(
      Object.entries(decryptedData).map(([key, value]) => {
        return excludedFields.includes(key) ? [key, decodedResponse.data[key]] : [key, value];
      })
    );
  }

  const itemsTab: TabsProps['items'] = [
    {
      key: '1',
      label: <FormattedMessage id={'basic_info'} />,
      children: <BasicInfo corporateData={decryptedData} />
    },
    {
      key: '2',
      label: <FormattedMessage id={'batch'} />,
      children: <BatchTable corporateData={decryptedData} />,
    }
  ];

  return (
    <>
      <CustomHeader title={<FormattedMessage id={'corporate'} />} />

      <div className="customTab2 mt-8">
        <Tabs
          defaultActiveKey="1"
          items={itemsTab}
          className="customTab mt-8"
          tabBarStyle={{ margin: 0 }}
        />
      </div>
    </>

  );
}

