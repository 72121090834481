import React, { useState } from 'react';
import { Card, Col, Empty, Flex, Pagination, Row } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../../services/react-query/queryKeys';
import { decodeResponse, decryptItem } from '../../../helpers/apiUtils';
import { FetchData } from '../../../network/fetchData';
import DeleteModal from '../../common/DeleteModal';
import { deleteData } from '../../../network/deleteData';
import FilterTable from '../../common/filtersTables/FilterTable';
import { EditOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import AddChapter from './AddLevel';
import UpdateChapter from './UpdateLevel';
import { FormattedMessage } from 'react-intl';
import UploadFileModal from '../../common/UploadFile';
import { Link } from 'react-router-dom';

interface LevelProps {
  certificateData: any;
}

const ListLevels: React.FC<LevelProps> = ({ certificateData }) => {
  const [editChapterData, setEditChapterData] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState<React.Key | null>(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalAction, setModalAction] = useState<'add' | 'update' | "delete" | "upload" | null>(null);

  const showModal = (action: 'add' | 'update' | 'delete' | 'upload', id?: React.Key, data?: any) => {
    setSelectedRowId(id !== undefined ? id : null);
    setEditChapterData(data);
    setModalAction(action);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const deleteValue = {
    mode: "level",
    type: "delete",
    id: selectedRowId
  };


  const values = {
    mode: "level",
    type: "get",
    certificate_id: certificateData.id,
  };

  const [searchTerm, setSearchTerm] = useState('');

  const { data: listLevel } = useQuery(
    [queryKeys.listLevels, searchTerm],
    () => FetchData({
      ...values,
      name: searchTerm,
    }),
    {
      keepPreviousData: true,
    }
  );

  const decodedResponse = decodeResponse(listLevel);
  const decryptedData = decodedResponse?.data.map((item: any) => {
    const decryptedItem = decryptItem(item);
    const excludedFields = ['media', 'index_path'];

    const itemWithoutDecryption = Object.fromEntries(
      Object.entries(decryptedItem).map(([key, value]) => {
        return excludedFields.includes(key) ? [key, item[key]] : [key, value];
      })
    );

    return itemWithoutDecryption;
  });

  const items = [
    {
      title: <FormattedMessage id='certificate' />,
      href: '/certificate',
    },
    {
      title: <FormattedMessage id='Level' />,
      href: '',
    }
  ];
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage: number = 12;

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  return (
    <>
      <FilterTable items={items} onSearch={(value) => setSearchTerm(value)} buttonText={<FormattedMessage id='add_level' />} tabName={<FormattedMessage id='Level' />} onClick={() => showModal('add')} isUpload={false} />

      <UploadFileModal visible={isModalVisible && modalAction === 'upload'} onClose={closeModal} mode={"assessment_advice"} confirmationHeader={''} successMessage={''} queryKey={queryKeys.listAdvice} />

      <AddChapter
        visible={isModalVisible && modalAction === 'add'}
        onClose={closeModal}
        certificateData={certificateData}
      />

      <UpdateChapter
        visible={isModalVisible && modalAction === 'update'}
        onClose={closeModal}
        levelData={editChapterData}
        certificateData={certificateData}
      />

      <DeleteModal
        visible={isModalVisible && modalAction === 'delete'}
        onDelete={deleteData}
        data={deleteValue}
        confirmationHeader={<FormattedMessage id='level' />}
        queryKey={queryKeys.listLevels}
        onClose={closeModal}
      />

      {decryptedData && decryptedData?.length > 0 ? (
        <>
          <Row gutter={{ xs: 8, sm: 16, md: 8, lg: 14, xl: 10 }} justify={decryptedData?.length % 2 !== 0 ? "space-between" : "start"}>
            {decryptedData.map((item: any) => (
              <Col className='mb-4'>
                <Card title={item?.name} style={{ width: 300 }}
                  actions={[
                    <Link to="/certificate/chapter" className='text-[#ED1D23]' state={{ level_data: item }}><EyeOutlined /></Link>,
                    <EditOutlined key="edit" onClick={() => showModal('update', item.id, item)} />,
                    <DeleteOutlined key="delete" style={{ color: "red" }} onClick={() => showModal('delete', item.id)} />,
                  ]}
                >
                  <Flex align='center'>
                    <img
                      alt="example"
                      src={item.media}
                      className='w-full'
                    />
                  </Flex>
                </Card>
              </Col>
            ))}
          </Row>
          <Pagination
            current={currentPage}
            total={decryptedData.length}
            pageSize={itemsPerPage}
            onChange={handlePageChange}
            className='mt-1'
          />
        </>
      ) : (
        <Empty description={<FormattedMessage id='no_data' />} className='mt-24' />
      )}
    </>
  );
}

export default ListLevels;
