import { FC } from "react";

interface AddressProps {
    width?: number;
    height?: number;
}

const Address: FC<AddressProps> = ({ width = "18px", height = "18px" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none">
            <g clip-path="url(#clip0_369_7079)">
                <path d="M8.83719 1.34C5.82664 1.34 3.37719 3.78945 3.37719 6.8C3.37719 10.7998 8.29414 17.282 8.5025 17.5559L8.83719 17.9907L9.17188 17.5559C9.38024 17.282 14.2972 10.7998 14.2972 6.8C14.2972 3.78945 11.8477 1.34 8.83719 1.34ZM8.83719 5.12C9.76578 5.12 10.5172 5.87141 10.5172 6.8C10.5172 7.72859 9.76578 8.48 8.83719 8.48C7.9086 8.48 7.15719 7.72859 7.15719 6.8C7.15719 5.87141 7.9086 5.12 8.83719 5.12ZM1.40188 13.94L-1 20.66H18.6744L16.2725 13.94H13.0585C12.8945 14.2288 12.7304 14.5093 12.5663 14.78H15.6819L17.48 19.82H0.194375L1.9925 14.78H5.10805C4.94398 14.5093 4.77992 14.2288 4.61586 13.94H1.40188Z" fill="#0E103A" />
            </g>
            <defs>
                <clipPath id="clip0_369_7079">
                    <rect width="21" height="21" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Address;
