import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "user",
  initialState: {
    data: null,
    addressList: [],
    currentLang: "ar",
  },
  reducers: {
    setUserData: (user, action) => {
      user.data = action.payload;
    },
    setUserAddressList: (user, action) => {
      user.addressList = action.payload;
    },
    setUserPackge: (user, action) => {
      user.packge = action.payload;
    },
    setCurrentLang: (user, action) => {
      console.log(action, "actionactionaction")
      user.currentLang = action.payload;
    },
  },
});

export const { setUserData, setUserAddressList, setUserPackge, setCurrentLang } = slice.actions;

export default slice.reducer;
