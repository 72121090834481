import { useEffect, useState } from 'react';
import { Button, Col, Flex, Form, Input, Modal, Row, Upload, message } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { queryKeys } from '../../../services/react-query/queryKeys';
import { addELearning } from '../../../network/storeDate';
import queryClient from '../../../services/react-query/queryClient';
import { FormattedMessage } from 'react-intl';
import UploudImgSVG from '../../../assets/teamMember/UploudImgSVG';
import { RcFile } from 'antd/es/upload';

interface AddModalProps<T> {
  visible: boolean;
  onClose: () => void;
  levelData: any;
  certificateData: any;
}

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const UpdateLevel: React.FC<AddModalProps<any>> = ({
  visible,
  onClose,
  levelData,
  certificateData,
}) => {
  const [fileList, setFileList] = useState<any>([]);
  const [fileValue, setFileValue] = useState<any>([]);
  const [imageUrl, setImageUrl] = useState<string>();
  const [form] = Form.useForm();

  const { mutate: onEditLevel, isLoading } = useMutation(addELearning, {
    onSuccess: (res) => {
      message.success("Submit success!");
      queryClient.invalidateQueries([queryKeys.listLevels]);
      onCancel();
    },
    onError: (error) => {
      message.error("حاول مرة اخرى");
    },
  });


  const handleFileChange = (info: {
    file: any; fileList: any;
  }) => {
    let fileList = [...info.fileList];

    // 1. Limit the number of uploaded files
    fileList = fileList.slice(-1);

    // 2. Read from response and show file link
    fileList = fileList.map(file => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url;
      }
      return file;
    });

    getBase64(info.file.originFileObj as RcFile, (url) => {
      setImageUrl(url);
    });

    console.log(fileList[0]);
    // Update the component state
    setFileList(fileList);
    setFileValue(fileList.map(file => file.originFileObj));

    // You can also log or perform other actions with the file here
    console.log(fileList);
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  const onFinish = async (values: any) => {
    let form_data = new FormData();
    form_data.append('mode', "level");
    form_data.append('type', "update");
    form_data.append('name', values?.name);
    form_data.append('certificate_id', levelData?.id);
    form_data.append('image', fileValue[0]);

    onEditLevel(form_data);
  };

  const initialValues = {
    name: certificateData?.name,
    language_id: certificateData?.language_id,
    instruction: decodeURIComponent(certificateData?.instruction),
  }

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(initialValues);
      if (fileValue[0] === undefined) {
        setImageUrl(certificateData?.media);
      }
    }

  }, [visible, initialValues, form]);

  return (
    <Modal
      centered
      open={visible}
      className="customModal"
      onOk={onFinish}
      onCancel={onCancel}
      footer={[]}
      title={<FormattedMessage id='edit_level' />}
      width={800}
    >
      <Form
        onFinish={onFinish}
        name="complex-form"
        layout="vertical"
        form={form}
        encType="multipart/form-data"
        className="form-style mt-8 px-9 pb-5"
      >

        <Form.Item
          name='image'
          required={false}
          className="form-style w-full flex justify-center"
        >
          <Upload
            listType="picture-card"
            className="avatar-uploader flex justify-center"
            onChange={handleFileChange}
            showUploadList={false}
          >
            {imageUrl ? <img src={imageUrl} style={{ width: '100%' }} /> : <UploudImgSVG />}
          </Upload>

        </Form.Item>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 14 }}>
          <Col xl={24}>
            <Form.Item
              name="name"
              label={<FormattedMessage id='name' />}
              required={false}
              className="form-style w-full"
              rules={[
                { required: true, message: 'Name is required' },
              ]}
            >
              <Input placeholder="Enter Name" />
            </Form.Item>
          </Col>
        </Row>

        <Flex gap="middle" align="center" justify="flex-end">
          <Button
            type="text"
            onClick={() => onCancel()}
            loading={isLoading}
            className="bg-[#1d1d39] h-full text-white text-lg rounded-md px-2 w-[112px]"
            htmlType="button"
          >
            <FormattedMessage id={'cancel'} />
          </Button>
          <Button
            type="text"
            loading={isLoading}
            className="bg-[#cf2110] h-full text-white text-lg rounded-md px-2 w-[112px]"
            htmlType="submit"
          >
            <FormattedMessage id={'save'} />
          </Button>
        </Flex>
      </Form>
    </Modal>

  );
}

export default UpdateLevel;

