// Define an enum with numeric values
enum langEnum {
  Arabic = 1,
  English = 2,
}

// Create a constant array to define the langType
const lang: { value: number; label: string }[] = [
  { value: langEnum.Arabic, label: 'Arabic' },
  { value: langEnum.English, label: 'English' },
];

export { langEnum, lang };
