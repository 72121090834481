import { createSlice } from '@reduxjs/toolkit';

const batchSlice = createSlice({
  name: 'batch',
  initialState: {
    info: [],
  },
  reducers: {
    setbatchInfo: (info, action) => {
      info.info = action.payload;
    },
    resetbatchState: (state) => {
      state.info = [];
      state.teamMember = [];
    }
  },
});

export const { setbatchInfo, resetbatchState } = batchSlice.actions;
export default batchSlice.reducer;
